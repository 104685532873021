import React, { useRef } from 'react'
import { ModalGeneric, Button } from '../../Components'
import { FC, invalidateQuery, showGrowl } from '../../Services'
import ModalContent from '../Signatures/Components/ModalContent'

export default function DeleteBtn ({ rowData }) {
  const deleteRef = useRef(null)

  const deleteSelectedClient = (rowData) => async () => {
    try {
      const response = await FC.service('clients').patch(rowData?._id, { deleted: true })
      if (!response) return showGrowl('error', 'Errore', 'Errore nell\'eliminazione del cliente. Riprova')
      invalidateQuery('clients')
      return showGrowl('success', 'Cliente eliminato', 'Cliente eliminato con successo!')
    } catch {
      return showGrowl('error', 'Errore', 'Errore nell\'eliminazione del cliente. Riprova')
    }
  }
  return (
    <>

      <ModalGeneric
        ref={deleteRef}
        header='Eliminazione Cliente'
        content={
          <ModalContent
            onHide={() => deleteRef.current.hide()}
            onConfirm={deleteSelectedClient(rowData)}
            contentText='Sicuro di volere eliminare questo cliente?'
          />
        }
      />
      <Button
        rich
        round
        tooltip='Elimina cliente'
        icon='close'
        onClick={() => deleteRef.current.show()}
        style={{ cursor: 'pointer', marginRight: 5 }}
      />
    </>

  )
}
