import React, { useRef } from 'react'

export const Draggable = ({ startX, startY, children, refParentDiv, dimensionContainer, handlerCoordinatesDrag, callbackUpdatePosition, marginDefault, isCustomQuestion }) => {
  const pos = useRef({
    x1: 0,
    x2: 0,
    y1: 0,
    y2: 0
  })
  const dragRef = useRef()
  const marginTop = isCustomQuestion ? marginDefault : document.getElementsByClassName('marginDocumentPDF')[0]?.offsetTop
  const marginLeft = isCustomQuestion ? marginDefault : document.getElementsByClassName('marginDocumentPDF')[0]?.offsetLeft

  const handleMouseUp = (event) => {
    const differenceXLeft = pos.current.offsetClickX
    const differenceYTop = pos.current.offsetClickY

    document.onmouseup = null
    document.onmousemove = null
    // Chiedere ad Andre perchè marginLeft e marginTop ? Causa un offset sulle crocette personalizzate.
    callbackUpdatePosition(pos.current.x2 - differenceXLeft + marginLeft * 2, pos.current.y2 - differenceYTop + marginTop * 2)
    // callbackUpdatePosition(pos.current.x2 - differenceXLeft + marginDefault * 2, pos.current.y2 - differenceYTop + marginDefault * 2)
    // gestione al release del controllo della posizione della firma o elemento, al di fuori, se sì allora riporto dentro nei limiti
  }
  const handleMouseMove = (e) => {
    const event = e || window.event
    event.preventDefault()
    pos.current = {
      ...pos.current,
      x1: pos.current.x2 - event.clientX, // pos1
      y1: pos.current.y2 - event.clientY, // pos2
      x2: event.clientX, // pos3
      y2: event.clientY // pos4
    }

    // height borders
    pos.current.elementClicked.style.top = (pos.current.elementClicked.offsetTop - pos.current.y1) + 'px'

    // width borders
    pos.current.elementClicked.style.left = (pos.current.elementClicked.offsetLeft - pos.current.x1) + 'px'
  }

  const handleMouseDown = (e) => {
    const styleComputed = window.getComputedStyle(dragRef.current)
    const styleComputedParent = window.getComputedStyle(refParentDiv.current)
    const event = e || window.event
    event.preventDefault()
    pos.current = {
      ...pos.current,
      x2: event.clientX, // pos3
      y2: event.clientY, // pos4
      elementClicked: event.target.parentNode,
      offsetClickX: event.clientX - parseInt(styleComputed.left),
      offsetClickY: event.clientY - parseInt(styleComputed.top),
      parentLimitTop: parseInt(refParentDiv.current.offsetTop),
      parentLimitLeft: parseInt(refParentDiv.current.offsetLeft),
      parentLimitRight: parseInt(refParentDiv.current.offsetLeft) + parseInt(styleComputedParent.width),
      parentLimitBottom: parseInt(refParentDiv.current.offsetTop) + parseInt(styleComputedParent.height)
    }
    document.onmouseup = handleMouseUp
    document.onmousemove = handleMouseMove
  }
  return (
    <div
      draggable
      onMouseDown={handleMouseDown}
      ref={dragRef}
      style={{
        zIndex: 20,
        position: 'absolute',
        left: startX,
        top: startY,
        cursor: 'move',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: dimensionContainer.width,
        height: dimensionContainer.height
      }}
    >
      {children}
    </div>
  )
}
