import React from 'react'
import { Flex } from './Flex'
import { Text } from './Text'
import { Input } from './Input'
import { ColorPicker } from 'primereact/colorpicker'

export const Color = ({ value: [value, setValue], label }) => (
  <Flex style={{ width: 150 }}>
    <Text value={label} />
    <ColorPicker format='hex' value={value} onChange={(e) => setValue(e.value)} />
    <Input
      value={value} style={{ width: 100 }} id='txtColor' onChange={(e) => setValue(e.txtColor)}
      onBlur={(e) => e.target.value?.length === 6 && e.target.value.match(/^[0-9A-Fa-f]+$/) && setValue('#' + e.target.value)}
    />
  </Flex>
)
