import React, { useState } from 'react'
import ReactDOM from 'react-dom'

import { Icon, Text, Flex } from '..'
import { useMount, useUnmount } from 'react-use'
import { motion } from 'framer-motion'

const modalMenu = document.getElementById('modal-menu')
const modalDiv = document.createElement('div')

const MENU = {
  accent: '#32324e',
  base: 'rgba(255, 255, 255, 0.95)',
  miniWidth: 90,
  menuWidth: 300,
  toggle: null
}

export const HamburgerMenu = () => (
  <motion.div
    whileHover='hovered' whileTap='click' onTap={() => MENU.toggle && MENU.toggle(2)}
    onHoverStart={() => MENU.toggle && MENU.toggle(1)}
    onHoverEnd={() => MENU.toggle && MENU.toggle(0)}
    variants={{ click: { rotate: '270deg' }, hovered: { rotate: '90deg' } }}
  >
    <Icon name='menu' size={30} />
  </motion.div>
)

const MenuItem = ({ item: { label, icon, path, disabled, separator }, action }) => disabled
  ? null
  : (
    <motion.div
      onTap={action} style={Styles.menuElement}
      whileHover='hovered' transition={{ default: { duration: 0.5 } }}
      variants={{ hovered: { backgroundColor: !separator ? MENU.accent : '#FFF', color: !separator ? MENU.base : '#FFF' } }}
    >
      <Flex style={{ minWidth: 70, marginLeft: 10, marginRight: 20 }}>
        {icon && <Icon color='inherit' name={icon} size={35} />}
      </Flex>
      <Text color='inherit' size={20} bold value={label} />
    </motion.div>)

export const SlidingMenu = ({ menuItems, menuAction }) => {
  const [visibility, setVisibility] = useState(0)

  useMount(() => { modalMenu.appendChild(modalDiv); MENU.toggle = setVisibility })
  useUnmount(() => { modalMenu.removeChild(modalDiv); MENU.toggle = null })

  const renderMenu = () => {
    return (
      <motion.div
        onHoverStart={() => setVisibility(2)} onHoverEnd={() => setVisibility(0)}
        animate={{
          width: [MENU.miniWidth, MENU.miniWidth, MENU.menuWidth][visibility],
          x: [-MENU.miniWidth, 0, 0][visibility],
          boxShadow: ['4px 0 78px 0px rgba(100, 100, 100, 0)', '4px 0 78px 0px rgba(100, 100, 100, 0.7)', '4px 0 58px 0px rgba(100, 100, 100, 0.7)'][visibility]
        }}
        transition={{ ease: 'easeOut', duration: 0.5 }}
        style={Styles.menuContainer}
      >
        {menuItems.map(({ path, ...item }, ind) => <MenuItem key={ind} item={item} action={() => { menuAction(path); setVisibility(0) }} />)}
      </motion.div>
    )
  }

  return ReactDOM.createPortal(renderMenu(), modalMenu) // .createPortal(<div><text>pippo</text></div>, modalMenu)
}

const Styles = {
  menuContainer: {
    position: 'absolute',
    top: 50,
    bottom: 0,
    left: 0,
    backgroundColor: MENU.base,
    overflow: 'hidden',
    width: MENU.miniWidth,
    x: -MENU.miniWidth,
    zIndex: 20
  },
  menuElement: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: MENU.accent,
    height: 60
  }
}
