import React, { useEffect, useState } from 'react'
import { Button, Flex, Input, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services/QueryCache'
import { FC } from '../../../../Services'
import TypeSetterArea from './TypeSetterArea'
import { inputPropsDropdown, nullEmailTypes, createEmailTemplate } from './functions'

/**
 * Create an email template
 *
 * @param {function} props.onClose - Function to close the modal
 *
 * @returns {JSX.Element}
 */
const CreateEmail = ({ onClose }) => {
  const { data: dbTemplates = [], isSuccess: templatesIsSuccess } = useCQuery('emailTemplates')
  const { data: dbEnvs = [], isSuccess: environmentsIsSuccess } = useCQuery('environments')
  const [environments, setEnvironments] = useState([])
  const [filteredEnvironments, setFilteredEnvironments] = useState([])
  const [sourceEnvironment, setSourceEnvironment] = useState('')
  // Target email is the one that will be created
  const [targetEnvironment, setTargetEnvironment] = useState('')
  const [targetEmailTypesByEnvironment, setTargetEmailTypesByEnvironment] = useState(nullEmailTypes)
  const [previewsEnvironments, setPreviewsEnvironments] = useState(nullEmailTypes)

  useEffect(() => {
    const nonDuplicateEnvironments = []
    if (templatesIsSuccess) {
      dbTemplates.forEach(template => {
        if (!nonDuplicateEnvironments[template.environment]) {
          nonDuplicateEnvironments.push(template.environment)
        }
      })
      setFilteredEnvironments(nonDuplicateEnvironments)
    }
    if (environmentsIsSuccess) setEnvironments(dbEnvs.map(env => (env._id)))
  }, [templatesIsSuccess, dbTemplates, environmentsIsSuccess, dbEnvs, previewsEnvironments])

  /**
   * Set all the types to the same environment value, set the
   * source environment and set the target email types by environment
   *
   * @param {string} environment - The environment to set
   *
   * @example ('esitur')
   */
  const setAllTypeEnvironments = (environment) => {
    (async () => {
      const [data] = await FC.service('emailTemplates').find({ query: { environment } });

      ['signatureRequest', 'reminder', 'error', 'expired'].forEach(type => {
        if (data[type]) {
          setTargetEmailTypesByEnvironment(prev => ({ ...prev, [type]: environment }))
          setPreviewsEnvironments(prev => ({ ...prev, [type]: environment }))
        }
      })
    })()
    setSourceEnvironment(environment)
  }

  return (
    <Flex fw wrap style={{ padding: '0 20px' }}>
      <Text value='Reuse All Templates' size='22px' center style={{ display: 'block', marginTop: '20px' }} />
      <Text
        value='Here you can take all of the templates from one environment, and give them to another one, which will create a new set of templates.'
        center size='16px' style={{ marginTop: '10px', width: '400px' }}
      />
      <Flex row style={{ width: '35%', marginTop: '20px', textTransform: 'capitalize' }}>
        <Text value='Source:' center style={{ display: 'inline-block' }} />
        <Input
          {...inputPropsDropdown('environment', 'Environment', sourceEnvironment, setAllTypeEnvironments)}
          dropdown options={filteredEnvironments}
        />
        <Text value='Receiver:' center style={{ display: 'inline-block' }} />
        <Input
          {...inputPropsDropdown('environment', 'Environment', targetEnvironment, setTargetEnvironment)}
          dropdown options={environments}
        />
      </Flex>
      <Button
        label='Create' icon='plus' style={{ marginTop: '20px' }}
        onClick={() => createEmailTemplate(targetEnvironment, targetEmailTypesByEnvironment, onClose)}
      />
      <Text value='Or, you can select them individually below.' center style={{ display: 'block', marginTop: '50px' }} />

      {['signatureRequest', 'reminder', 'error', 'expired'].map((type, key) => (
        <TypeSetterArea
          key={key}
          targetEmailTypesByEnvironment={[targetEmailTypesByEnvironment, setTargetEmailTypesByEnvironment]}
          previewsEnvironments={[previewsEnvironments, setPreviewsEnvironments]}
          filteredEnvironments={filteredEnvironments}
          type={type}
        />
      ))}
      <Flex row>
        <Button
          label='Crea' icon='plus' style={{ margin: '30px 10px' }}
          onClick={() => createEmailTemplate(targetEnvironment, targetEmailTypesByEnvironment, onClose)}
        />
        <Button label='Cancel' icon='close' style={{ margin: '30px 10px' }} onClick={() => onClose()} />
      </Flex>
    </Flex>
  )
}

export default CreateEmail
