import React, { useState, useEffect } from 'react'

import { Spinner, Text } from '../../Components'
import { getUrlParams } from '../../Services/Utils'
import RobinturMail from './Robintur'
import OutsMail from './Outs'
import { FC } from '../../Services'

export default function BrowserEmail () {
  const documentType = getUrlParams('type')
  const code = getUrlParams('code')
  const env = getUrlParams('env')

  const [designMail, setDesignMail] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    FC.service('public').get('envDesign', { query: { code: code || '', environment: env || '' } })
      .then((design) => {
        setLoading(false)
        setDesignMail(design)
      })
      .catch((e) => {
        setLoading(false)
        setError(true)
        setDesignMail(false)
      })
  }, [])

  if (loading) return <Spinner />
  if (error) return <Text loading='Error' />

  /*
  Qui comincia la visualizzazione delle mail per cliente.
  Se il cliente ci fornisce un template HTML, renderizziamo quello, altrimenti OUTS generale con colori e loghi custom
  */
  if (env === 'robintur') return <RobinturMail documentType={documentType} code={code} />
  return <OutsMail documentType={documentType} code={code} designMail={designMail} />
}
