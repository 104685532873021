import React, { useState } from 'react'
import DocumentPage from './DocumentPage'
import WelcomePage from './WelcomePage'
import { useParams } from 'react-router-dom'

export default function RemoteSignature () {
  const [otpVerified, setOptVerified] = useState(false)
  const [signature, setSignature] = useState({})
  const [designPage, setDesignPage] = useState({})
  const { signId: code } = useParams()

  const updatePage = (sig, design) => {
    setSignature(sig)
    setOptVerified(true)
    setDesignPage(design)
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {otpVerified
        ? <DocumentPage code={code} signature={signature} designPage={designPage} />
        : <WelcomePage code={code} updatePage={updatePage} />}
    </div>
  )
}
