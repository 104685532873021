import { Button, Flex, Input, Text } from '../../../../../../Components'
import { translateLabelType } from '../Functions'
import { Checkbox } from 'primereact/checkbox'
import _ from 'lodash'
import { Select } from '../../../../../../Components/Common/Select'
import { useLocation } from 'react-router-dom'

const dropDownOptions = ['Crocetta caso negativo', 'Crocetta caso affermativo']

export const Answer = ({ item, index, customQuestion: [listCustomQuestions, setListCustomQuestions], updateCustomCheck, page }) => {
  const { answerQuestion, id, type, question, mandatory, idFlagPrivacy = '' } = item
  const { pathname } = useLocation()
  const isInstant = pathname === '/instantsign'
  return (
    <Flex key={id}>
      <Flex style={{ position: 'relative', padding: '0.5em 1.1em', paddingBottom: 10, margin: 10, borderRadius: 12, width: '720px', ...questionStyle }}>
        <Text value={`${translateLabelType(type)} ${index + 1}`} size={16} bold />
        <Text value={`Testo domanda: ${question}`} />
        <Flex row>
          <Text value='Obbligatoria' style={{ marginRight: 10 }} />
          <Checkbox
            checked={mandatory}
            onChange={e => {
              const array = [...listCustomQuestions]
              const newArray = array.map((obj) =>
                (obj.id !== id) ? obj : { ...obj, mandatory: !obj?.mandatory })
              setListCustomQuestions(newArray)
            }}
          />
        </Flex>
        {!isInstant && (
          <Flex row>
            <Input
              keyfilter='int'
              placeholder='IdFlagPrivacy di Atlante'
              style={{ border: '2px solid black' }}
              value={idFlagPrivacy}
              id='idFlagPrivacy'
              onChange={({ idFlagPrivacy }) => {
                const array = [...listCustomQuestions]
                const newArray = array.map((obj) =>
                  (obj.id !== id) ? obj : { ...obj, idFlagPrivacy })
                setListCustomQuestions(newArray)
              }}
            />
          </Flex>)}
        <Button
          style={{ cursor: 'pointer', marginTop: 10, textAlign: 'center' }}
          rich label='Aggiungi nuova crocetta'
          onClick={() => {
            const timestamp = Date.now()
            updateCustomCheck({ answerQuestion: [...answerQuestion, { coordinates: { x: 250, y: 400 }, width: 18, height: 18, id: type + timestamp, page, isYesAnswer: answerQuestion?.length === 0 }] }, id)
          }}
        />
        {answerQuestion.map((item, ind) =>
          <Flex key={ind} style={{ borderRadius: 4, padding: '0.5em 1.5em 1.5em 1em', border: '2px solid #32324e', margin: '10px 0px', position: 'relative' }}>
            <Text value={`Pagina ${item.page}`} style={{ alignSelf: 'right', padding: '0.4em 1em', borderRadius: 7, margin: '0.5em' }} size={14} bold />
            <Select
              value={dropDownOptions[item.isYesAnswer ? 1 : 0]}
              options={dropDownOptions}
              style={{ fontSize: 12 }}
              onChange={() => {
                const array = [...listCustomQuestions]
                const newArray = array.map((obj) =>
                  (obj.id !== id)
                    ? obj
                    : ({ ...obj, answerQuestion: obj?.answerQuestion.map(answer => answer?.id !== item.id ? answer : { ...answer, isYesAnswer: !answer.isYesAnswer }) }))
                setListCustomQuestions(newArray)
              }}
            />
            <Button
              style={{ position: 'absolute', top: -11, right: -11, width: 22 }}
              icon='close' iconSize={10}
              onClick={(e) => {
                const array = [...listCustomQuestions]
                const newArray = array.map((obj) =>
                  obj.id !== id
                    ? obj
                    : { ...obj, answerQuestion: obj?.answerQuestion.filter(answer => answer?.id !== item.id) })
                setListCustomQuestions(newArray)
              }}
            />
          </Flex>
        )}
        <Button
          style={{ cursor: 'pointer', position: 'absolute', top: -11, right: -11, width: 22 }}
          icon='close' iconSize={10}
          onClick={(e) => {
            const array = [...listCustomQuestions]
            _.remove(array, (el) => el.id === id)
            setListCustomQuestions(array)
          }}
        />
      </Flex>
    </Flex>
  )
}

const questionStyle = {
  backdropFilter: 'blur(4px) saturate(162%)',
  WebkitBackdropFilter: 'blur(4px) saturate(162%)',
  backgroundColor: 'rgba(255, 255, 255, 0.39)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.125)'
}
