import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useRef, useState } from 'react'
import { Button, Flex, ModalGeneric, Spinner } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { useNavigate } from 'react-router-dom'
import { translateLabel } from './Functions'
import ModalContent from '../../Signatures/Components/ModalContent'
import { handleDeleteDoc } from '../New/Functions'

const TabMultiSign = ({ onlyRequested, onlySigned }) => {
  const { isSuccess, data: multiSignatures = [] } = useCQuery(['multiSignatures', onlyRequested, onlySigned])
  const navigate = useNavigate()
  const [itemToDelete, setItemToDelete] = useState()
  const deleteRef = useRef()

  const btnAction = ({ _id, status }) => (
    <Flex row>
      <Button tooltip='Modifica' round icon='edit' onClick={() => navigate('/multisign/' + _id)} />
      <Button tooltip='Elimina' style={{ marginLeft: 10 }} round icon='bin' disabled={status === 'requested'} onClick={() => { setItemToDelete(_id); deleteRef.current.show() }} />
    </Flex>
  )

  const deleteElement = async () => {
    await handleDeleteDoc(itemToDelete)
    await FC.service('multiSignatures').patch(itemToDelete, { deleted: true })
    invalidateQuery('multiSignatures')
    deleteRef.current.hide()
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh>
      <Flex fw row je ae>
        <Button label='Nuova Firma Multipla' icon='plus' style={{ marginRight: 10 }} onClick={() => navigate('/multisign/new')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} sortField='createdAt' sortOrder={-1}
        value={multiSignatures} emptyMessage='Niente da visualizzare' responsiveLayout='scroll'
        paginator rows={10} className='TableSignatures' filterDisplay='row'
      >
        <Column field='name' {...columnProps('Nome')} />
        <Column field='createdAt' {...columnProps('Data richiesta')} body={({ createdAt }) => new Date(createdAt).toLocaleDateString('it-IT')} />
        <Column field='status' {...columnProps('Stato')} body={({ status }) => translateLabel(status)} />
        <Column field='clients.length' {...columnProps('Numero Clienti')} />
        <Column body={btnAction} style={{ width: 180 }} />
      </DataTable>
      <ModalGeneric
        ref={deleteRef}
        header='Cancellazione documento'
        content={
          <ModalContent onHide={deleteRef} onConfirm={deleteElement} contentText='Sei sicuro di volere eliminare questa firma multipla?' />
        }
      />
    </Flex>
  )
}

export default TabMultiSign

const columnProps = (filterPlaceholder) => ({
  filter: true,
  header: filterPlaceholder,
  sortable: true,
  filterMatchMode: 'contains',
  filterPlaceholder: '🔍   ' + filterPlaceholder,
  showFilterMenu: false,
  showClearButton: false
})
