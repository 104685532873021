import React, { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { useWindowSize } from 'react-use'
import { Column } from 'primereact/column'
import { Button, Flex, ModalGeneric, Spinner, Text } from '../../../Components'
import { cap } from '../../../State'
import { invalidateQuery, showGrowl, useCQuery } from '../../../Services'
import { DownloadBtn, ResendBtn, DeleteBtn } from '.'
import { downloadDocuments, resendDocuments } from '../Functions'
import ModalContent from './ModalContent'
import { DetailsBtn } from './DetailsBtn'

const TabSignatures = ({ signed, requested, expired }) => {
  // const { width: screenWidth } = useWindowSize()
  const { data: agency } = useCQuery('agency')
  const { data: environment, isSuccess: isSuccessEnv } = useCQuery('environment')
  const { isSuccess, data = [] } = useCQuery(['signaturesDoc', cap.auth.state.noTablet])
  const [selectedSignatures, setSelectedSignatures] = useState([])
  const [headerText, setHeaderText] = useState('')
  const [contentText, setContentText] = useState('')
  const dtRef = useRef(null)
  const [extraDocUrls, setExtraDocUrls] = useState([])

  const { width: screenWidth } = useWindowSize()

  const modalRef = useRef(null)
  const modalRefExtraDocs = useRef(null)

  if (!isSuccess || !isSuccessEnv) return <Spinner />

  const filterByStatus = ({ status }) => {
    if (signed) return status === 'signed'
    if (requested) return (status === 'requested' || status === 'waiting')
    if (expired) return status === 'expired'
  }

  const signatures = data.filter(filterByStatus).map(i => ({ ...i, documentName: i?.label !== 'no-label-name' ? i?.label : i?.documents?.documentName || '' }))
  const bodyActions = (rowData) => {
    const headerText = signed || expired ? 'Re-invio richiesta di firma' : rowData?.status === 'waiting' ? 'Invia richiesta di firma' : 'Sollecita richiesta di firma'
    const contentText = signed || expired ? 'Sei sicuro di volere re-inviare questa richiesta di firma ?' : rowData?.status === 'waiting' ? 'Sei sicuro di  voler inviare la richiesta di firma?' : 'Sei sicuro di voler sollecitare la firma al cliente ?'
    const tooltip = requested ? rowData?.status === 'waiting' ? 'Invia' : 'Sollecita' : 'Re-Invia'
    return (
      <Flex width={((requested || expired) ? 320 : 220) + (rowData?.extraDocUrls?.length ? 50 : 0)} row>
        {rowData?.extraDocUrls?.length
          ? <Button
              tooltip='Documenti Aggiuntivi' icon='archive-2' rich round onClick={() => {
                setExtraDocUrls(rowData?.extraDocUrls)
                modalRefExtraDocs.current.show()
              }}
            />
          : null}
        {rowData?.status === 'waiting' && <Button rich round tooltip='Firma in attesa' icon='clock' disabled style={{ cursor: 'not-allowed', marginLeft: screenWidth > 1200 ? 10 : 0 }} />}
        {rowData?.extraMailBody && (
          <DetailsBtn
            headerText='Informazioni Aggiuntive' contentText={rowData?.extraMailBody || ''} tooltip='Note'
          />
        )}
        <DownloadBtn rowData={rowData} />
        <ResendBtn tooltip={tooltip} rowData={rowData} headerText={headerText} contentText={contentText} />
        {(requested || expired) && <DeleteBtn rowData={rowData} />}

      </Flex>
    )
  }

  const onClickMultipleDownloads = async () => {
    await downloadDocuments(selectedSignatures.map(({ _id }) => _id))
  }

  const onClickMultipleResend = async () => {
    const findTabletRequest = selectedSignatures.find(({ requestDest }) => requestDest === 'APP')

    let signaturesToResend = [...selectedSignatures]
    if (findTabletRequest) {
      signaturesToResend = selectedSignatures.filter(({ requestDest }) => requestDest !== 'APP')
      showGrowl('info', 'Attenzione', 'Alcune delle richieste selezionate per il re-invio sono richieste indirizzate ad un tablet. Per queste richieste non è possibile effettuare il re-invio.')
    }
    resendDocuments(signaturesToResend.map(({ _id }) => _id))
    hideModal()

    showGrowl('success', 'Operazione andata a buon fine', '')
    invalidateQuery('signaturesDoc')
  }

  const openModal = (mode) => () => {
    if (!selectedSignatures.length) return showGrowl('error', 'Attenzione', 'Seleziona almeno un elemento')
    if (mode === 'urge') {
      setHeaderText('Sollecita richiesta di firma')
      setContentText('Sei sicuro di voler sollecitare la firma al cliente ?')
    }
    if (mode === 'send') {
      setHeaderText('Re-invio richiesta di firma')
      setContentText('Sei sicuro di volere re-inviare questa richiesta di firma ?')
    }

    modalRef?.current?.show ? modalRef.current.show() : modalRef.show()
  }

  const hideModal = () => modalRef?.current?.hide ? modalRef.current.hide() : modalRef.hide()

  return (
    <Flex fw fh>
      <ModalGeneric
        ref={modalRef}
        header={headerText}
        content={
          <ModalContent
            onHide={hideModal}
            onConfirm={onClickMultipleResend}
            contentText={contentText}
          />
        }
      />
      <ModalGeneric
        ref={modalRefExtraDocs}
        style={{ width: 400 }}
        content={
          <Flex fw>
            <Text value='Documenti aggiuntivi' bold size={20} style={{ marginBottom: 10 }} />
            <ul>
              {extraDocUrls?.map((url, index) => (
                <li key={index}>
                  <Flex key={index} row as js>
                    <a href={url} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>
                      <Text value={'Documento ' + (index + 1)} />
                    </a>
                  </Flex>
                </li>
              ))}
            </ul>
          </Flex>
        }
      />
      <Flex fw row je ae>
        {agency?.hasExport && <Button label='Esporta CSV' icon='xls-2' style={{ marginRight: 10 }} onClick={() => dtRef?.current?.exportCSV()} />}
        <Button label='Scarica selezionati' icon='download' style={{ marginRight: 10 }} onClick={onClickMultipleDownloads} />
        <Button label={requested ? 'Sollecita selezionati' : 'Re-invia selezionati'} icon='send' onClick={openModal(requested ? 'urge' : 'send')} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} ref={dtRef}
        value={signatures} emptyMessage='Niente da visualizzare' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        selection={selectedSignatures} onSelectionChange={(e) => setSelectedSignatures(e.value)}
        filterDisplay='row'
        sortField='createdAt' sortOrder={-1}
      >
        <Column selectionMode='multiple' style={{ width: '5px', height: '5px', marginTop: '10px', textAlign: 'center' }} />
        <Column field='documentName' {...columnProps('Descrizione')} />
        {signed && environment.showSignDate && <Column field='metadata.signedAt' {...columnProps('Data Firma')} />}
        <Column field='creationDate' {...columnProps('Data richiesta')} />
        {signatures[0]?.status !== 'signed' && (
          <Column field='firstRequestDate' {...columnProps('Data prima richiesta')} />
        )}

        {requested && <Column field='expiringAt' {...columnProps('Data Scadenza')} />}
        {!cap.auth.state.noTablet && signed && !environment?.hasFtoSign && (
          <Column field='deviceCode' {...columnProps('Codice Tablet')} body={({ deviceCode = '' }) => deviceCode === 'REMOTE' ? 'Da remoto' : deviceCode} />
        )}
        <Column field='userName' {...columnProps('Cliente')} />
        <Column field='userPhone' {...columnProps('Telefono cliente')} />
        <Column field='userEmail' {...columnProps('Email cliente')} />
        {!!environment?.showUserAtlInTab && <Column field='requestData.userAtl' {...columnProps('Operatore')} />}
        <Column style={btnColumnStyle(requested)} body={bodyActions} />
      </DataTable>
    </Flex>
  )
}

const btnColumnStyle = (requested) => ({
  border: 'none',
  width: requested ? 280 : 140,
  height: 15,
  textAlign: 'center'
})

const columnProps = (filterPlaceholder) => ({
  header: filterPlaceholder,
  headerStyle: { visibility: 'hidden' },
  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export default TabSignatures
