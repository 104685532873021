import React, { useState, useEffect } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'

import { Button, Text } from '../../../Components'
import { cap } from '../../../State'
import Panel from './Panel'
import ItalicSign from './ItalicSign'
import { useWindowSize } from 'react-use'

export default function SignatureModal ({
  handleSign,
  italicSign,
  designPage,
  open,
  setOpen
}) {
  // const [base64URL, setBase64URL] = useState(null)
  const [orientation] = cap.orientation.useState()
  const [isLandscape, setIsLandscape] = useState(false)
  const [isVerticalSign, setIsVerticalSign] = useState(false)
  const [askForItalic, setAskForItalic] = useState(true)

  const { width: screenWidth } = useWindowSize()

  useEffect(() => {
    setOpen(false)
    if (orientation === 'landscape') setIsLandscape(true)
    else setIsLandscape(false)
  }, [orientation])

  const onPressConfirm = async (b64) => {
    setIsVerticalSign(false)
    setOpen((o) => !o)
    await handleSign(b64)
  }

  const onPressCancel = () => {
    setIsVerticalSign(false)
    setOpen((o) => !o)
  }

  const renderVerticalModal = () => (
    <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Text bold value='Ruota lo schermo per firmare!' />
      <div style={{ marginTop: 40, justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
        <Text value='Il tuo telefono non supporta la rotazione dello schermo ?' />
        <Button
          onClick={() => setIsVerticalSign(true)}
          label='Firma qui'
          style={{ backgroundColor: designPage?.mainColor, color: '#fff', marginTop: 10 }}
          inverted
        />
      </div>
    </div>
  )

  return (

    <Popup
      open={open}
      contentStyle={{
        backgroundColor: '#fff',
        borderRadius: 25,
        padding: 10,
        minWidth: screenWidth < cap.smallScreen.state ? undefined : 640,
        width: screenWidth < cap.smallScreen.state ? screenWidth - 20 : undefined
      }}
      modal
      closeOnDocumentClick
      onClose={() => {
        setIsVerticalSign(false)
        setAskForItalic(true)
        setOpen(false)
      }}
    >
      {askForItalic && italicSign
        ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', minHeight: 125, maxHeight: 550 }}>
            <Button
              style={{ backgroundColor: designPage?.mainColor, color: '#fff', marginTop: 10 }}
              label='Firma con tratto'
              onClick={() => setAskForItalic(false)}
              inverted
            />
            <ItalicSign sign={italicSign} onPressConfirm={onPressConfirm} designPage={designPage} />
          </div>)
        : isLandscape
          ? <Panel onPressCancel={onPressCancel} onPressConfirm={onPressConfirm} designPage={designPage} />
          : screenWidth < cap.smallScreen.state
            ? isVerticalSign
                ? <Panel designPage={designPage} onPressConfirm={onPressConfirm} onPressCancel={onPressCancel} canvasProps={panelStyles.verticalSign.canvasProps} buttonBoxStyle={panelStyles.verticalSign.btnBoxStyle} buttonConfirmStyle={{ ...panelStyles.verticalSign.btnConfirm, backgroundColor: designPage?.mainColor }} buttonCancelStyle={panelStyles.verticalSign.btnCancel} />
                : renderVerticalModal()
            : <Panel designPage={designPage} onPressCancel={onPressCancel} onPressConfirm={onPressConfirm} buttonBoxStyle={panelStyles.screenBig.btnBoxStyle} canvasProps={panelStyles.screenBig.canvasProps} />}
    </Popup>

  )
}

const panelStyles = {
  verticalSign: {
    canvasProps: {
      width: '100%',
      minHeight: 125,
      border: '1px solid black',
      borderRadius: 25,
      marginBottom: 20,
      maxHeight: 550
    },
    btnBoxStyle: {
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'space-between ',
      padding: 10
    },
    btnConfirm: { color: '#fff' },
    btnCancel: { backgroundColor: '#32324e', color: '#fff', marginTop: 10 }
  },
  screenBig: {
    canvasProps: {
      width: '100%',
      minHeight: 125,
      border: '1px solid black',
      borderRadius: 25,
      maxHeight: 550,
      marginBottom: 20
    },
    btnBoxStyle: {
      display: 'flex', justifyContent: 'space-between', padding: 10
    }
  }
}
