import React from 'react'
import { Flex } from './Flex'
import { Text } from './Text'
import { motion } from 'framer-motion'

export const Select = ({ value, options, style, onChange, selectedColor = (window?._env_?.MAIN_COLOR || '') }) => {
  const renderOptionsValueLabel = () => options.map(({ value: optValue, label: optLabel }, index) => (
    <motion.div
      key={index} whileHover={{ scale: 1.05 }} onClick={() => onChange({ value: optValue })}
      style={{ padding: '6px 14px', backgroundColor: optValue === value ? selectedColor : 'lightgray', borderRadius: 2, cursor: 'pointer' }}
    >
      <Text value={optLabel} size={18} color={optValue === value ? 'white' : 'black'} />
    </motion.div>
  ))

  const renderOptionsValue = () => options.map((optValue, index) => (
    <motion.div
      key={index} whileHover={{ scale: 1.05 }} onClick={() => onChange({ value: optValue })}
      style={{ padding: '6px 14px', backgroundColor: optValue === value ? selectedColor : 'lightgray', borderRadius: 2, cursor: 'pointer' }}
    >
      <Text value={optValue} size={18} color={optValue === value ? 'white' : 'black'} />
    </motion.div>
  ))

  return (
    <Flex row style={{ ...style }}>
      {typeof (options[0]) === 'object' ? renderOptionsValueLabel() : renderOptionsValue()}
    </Flex>
  )
}
