import React, { useRef } from 'react'
import { Button, ModalGeneric } from '../../../Components'
import { useWindowSize } from 'react-use'
import SignatureDetailsModal from './SIgnatureDetailsModal'

export const DetailsBtn = ({ headerText, contentText, tooltip }) => {
  const { width: screenWidth } = useWindowSize()
  const detailsRef = useRef(null)

  const openDetailsModal = () => detailsRef?.current?.show ? detailsRef.current.show() : detailsRef.show()
  const hideDetailsModal = () => detailsRef?.current?.hide ? detailsRef.current.hide() : detailsRef.hide()

  return (
    <>
      <ModalGeneric
        ref={detailsRef} style={{ maxWidth: '60%' }}
        header={headerText} // 'Re-invio richiesta di firma'
        content={
          <SignatureDetailsModal
            onHide={hideDetailsModal}
            contentText={contentText}
          />
        }
      />
      <Button
        rich
        round
        tooltip={tooltip}
        icon='info'
        onClick={openDetailsModal}
        style={{ cursor: 'pointer', marginLeft: screenWidth > 1200 ? 10 : 0 }}
      />
    </>

  )
}
