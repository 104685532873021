import React, { useState } from 'react'
import { Flex, Icon, Text } from '../../../../Components'

const ContactCard = ({ client, onCardClick, selected }) => {
  const { name, lastname, email, phoneNumber } = client.contactPerson
  const [isHovered, setIsHovered] = useState()

  const handleClick = () => onCardClick({ name, lastname, email, phoneNumber })

  const handleMouseEnter = () => setIsHovered(true)

  const handleMouseLeave = () => setIsHovered(false)

  const cardStyle = {
    width: '320px',
    padding: '.8em',
    marginBottom: '1em',
    cursor: 'pointer',
    borderRadius: '12px',
    transform: isHovered ? 'scale(1.01)' : 'scale(1)',
    border: '1px solid #32324e',
    backgroundColor: '#fff'
  }

  return (
    <div style={cardStyle} onClick={handleClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Flex style={{ gap: '.5em', padding: '.4em' }}>
        <Flex row style={{ gap: '0.5em' }}>
          <Icon name='account' size={18} style={{ fontWeight: 600 }} />
          <Text bold size={18} value={name.charAt(0).toUpperCase() + name.slice(1) || 'Non Disponibile'} />
          <Text size={18} bold value={lastname.charAt(0).toUpperCase() + lastname.slice(1) || 'Non Disponibile'} />
        </Flex>
        <span style={{ width: '60%', height: '1px', backgroundColor: '#32324e', margin: '0 auto' }} />
        <Flex style={{ borderLeft: '2px solid #32324e', paddingLeft: '.5em', gap: '.5em' }} />
        <Flex as js style={{ gap: '.2em' }}>
          <Flex js row style={{ gap: '.2em' }}>
            <Icon size={18} style={{ fontWeight: 600 }} name='telephone' /><Text size={16} value={phoneNumber || 'Non Disponibile'} />
          </Flex>
          <Flex js row style={{ gap: '.2em' }}>
            <Icon size={18} style={{ fontWeight: 600 }} name='mail' /><Text size={16} value={email || 'Non Disponibile'} />
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}

export default ContactCard
