import React, { useRef, useState, useEffect } from 'react'
import { Button, Card, Flex, Input, Spinner, Text } from '../../../Components'
import { PageContainer } from '../../../Components/Layout/PageContainer'
import { useNavigate, useParams } from 'react-router-dom'
import { FC, showGrowl } from '../../../Services'
import MultiSignMainData from './MultiSignMainData'
import { invalidateQuery, useCQuery } from '../../../Services/QueryCache'
import { ClientsDocTutorial, MultiSignStats } from './Components'
import { importFromCRM, uploadClients } from './Functions'

const NewMultiSign = () => {
  const { multisignId } = useParams()
  const { data: multiSign, isSuccess } = useCQuery(['multiSign', multisignId])
  const { data: environment = {} } = useCQuery('environment')
  const [clients, setClients] = useState([])
  const [multiSignName, setMultiSignName] = useState('')
  const [importType, setImportType] = useState('')
  const navigate = useNavigate()
  const refUploadClients = useRef()
  const isNew = multisignId === 'new'
  const [loadingCRM, setLoadingCRM] = useState(false)

  useEffect(() => {
    setClients(isSuccess && multiSign ? multiSign?.clients : [])
    setMultiSignName(isSuccess && multiSign ? multiSign?.name : '')
  }, [multiSign, isSuccess])

  const checkErrorSave = () => !multiSignName || !clients.length

  const handleSaveMultiSign = async () => {
    if (checkErrorSave()) return showGrowl('error', 'Errore', 'Campi obbligatori non compilati')
    if (isNew) {
      const res = await FC.service('multiSignatures').create({ name: multiSignName, clients, status: 'created', importType })
      if (res._id) {
        window.growl.show({ severity: 'success', summary: 'Firma Multipla Creata', detail: 'Firma Multipla Creata con successo!' })
        navigate(`/multisign/${res._id}`)
      }
      invalidateQuery('multiSignatures')
      return true
    }
    const res = await FC.service('multiSignatures').patch(multisignId, { name: multiSignName })
    res._id && window.growl.show({ severity: 'success', summary: 'Firma Multipla Salvata', detail: 'Firma Multipla Salvata con successo!' })
    invalidateQuery('multiSignatures')
  }

  const importClients = async (type, e) => {
    type === 'CRM' && setLoadingCRM(true)
    type === 'CRM'
      ? setClients(await importFromCRM())
      : setClients(await uploadClients(e))
    type === 'CRM' && setLoadingCRM(false)
    setImportType(type)
  }

  if (!isSuccess) return <Spinner />
  return (
    <PageContainer>
      <Card>
        <Flex js fw>
          <Text bold title value={`${isNew ? 'Nuova' : 'Modifica'} Firma Multipla`} size={20} upCase />
          <Flex fw jb style={{ padding: 10, marginTop: '2em' }} row>
            <Input style={{ width: 290 }} value={multiSignName} id='multiSignName' onChange={({ multiSignName }) => setMultiSignName(multiSignName)} label='Nome Firma Multipla' />
            {!isNew && multiSign?.status !== 'created' && <MultiSignStats multiSign={multiSign} />}
            {isNew && (
              <Flex>
                <Button icon='cloud-up' label='Carica CSV' style={{ marginTop: 10 }} onClick={() => refUploadClients.current.click()} />
                {environment?.hasCrm &&
                  (!loadingCRM ? <Button icon='cloud-up' label='Importa da CRM' style={{ marginTop: 10 }} onClick={() => importClients('CRM')} /> : <Spinner />)}
              </Flex>
            )}
          </Flex>
          <input type='file' accept='.csv' ref={refUploadClients} style={{ display: 'none' }} onChange={(e) => importClients('CSV', e)} />
          {clients?.length
            ? <MultiSignMainData onSave={handleSaveMultiSign} clients={[clients, setClients]} />
            : (<ClientsDocTutorial />)}
        </Flex>
      </Card>
    </PageContainer>
  )
}

export default NewMultiSign
