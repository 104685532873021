import _ from 'lodash'
import React from 'react'
import { Button, Flex, Text } from '../../../../../Components'
import { translateLabelType } from './Functions'

export const DateSign = ({ item, signDate: [elementAddedSignDate, setElementAddedSignDate], id: key }) => (
  <Flex key={item.id} style={{ borderRadius: 4, border: '2px solid #32324e', padding: '0.5em 1.5em', position: 'relative', margin: '0.5em' }}>
    <Text value={`${translateLabelType(item.type)} ${key + 1}`} size={16} bold />
    {(item?.type === 'fixedLabel' || item?.type === 'special') && <Text value={`"${item.title}"`} />}
    <Text value={`Pagina: ${item.page}`} />
    <Button
      icon='close' iconSize={10}
      style={{ width: 22, position: 'absolute', right: -11, top: -11 }}
      onClick={(e) => {
        const array = [...elementAddedSignDate]
        _.remove(array, (el) => el.id === item.id)
        setElementAddedSignDate(array)
      }}
    />
  </Flex>
)
