import React, { useEffect, useRef } from 'react'
import { Button, Card, Flex, Text } from '../../../Components'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { exportExcel } from '../../../Services/Utils'

const SmsHistory = ({ isVisible, mobytMessages }) => {
  const dataTableRef = useRef(null)
  const baseFileName = 'conteggio-sms-firma-digitale' /* ${mobytMessages[0].environment || '_'} */

  const formatDateNTime = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }
    return `${date.toLocaleDateString('it-IT', options)}`
  }

  const dateBodyTemplate = (rowData) => {
    return formatDateNTime(new Date(rowData.createdAt)) || ''
  }

  const prefixCountryBodyTemplate = (rowData) => {
    return (rowData.countryCode === '39') ? 'ITA' : 'ESTERO'
  }

  useEffect(() => {
    if (dataTableRef.current) {
      dataTableRef.current.scrollToBottom()
    }
  }, [isVisible, dataTableRef])

  const handleExportExcel = () => {
    const columns = [
      { label: 'AGENZIA', field: 'environment', width: 120 },
      { label: 'PREFISSO', field: 'countryCode', width: 70 },
      { label: 'TELEFONO', field: 'phone', width: 140 },
      { label: 'DATA INVIO', field: 'createdAt', width: 150 }
    ]
    exportExcel(mobytMessages, columns, baseFileName)
  }

  return (
    <Card style={{ width: '70%', marginTop: '1em', marginBottom: '1em' }}>
      <Flex row jb style={{ gap: '.5em' }}>
        <Text bold size={22} value='STORICO' />
        <Button round icon='xls-2' tooltip='Scarica storico' severity='success' rounded onClick={handleExportExcel} data-pr-tooltip='XLS' />
      </Flex>
      <DataTable
        ref={dataTableRef} style={{ width: '100%' }} striped
        value={mobytMessages} paginator rows={5}
        sortField='createdAt' sortOrder={-1} emptyMessage='Nessun sms trovato'
        className='TableSignatures'
      >
        <Column field='environment' header='Agenzia' />
        <Column field='createdAt' header='Data Invio' body={dateBodyTemplate} />
        <Column field='countryCode' header='Prefisso' />
        <Column field='' header='Paese Prefisso' body={prefixCountryBodyTemplate} />
        <Column field='phone' header='Numero' />
      </DataTable>
    </Card>
  )
}

export default SmsHistory
