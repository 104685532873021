import React, { useState, useRef, useEffect } from 'react'
import { useCQuery, FC } from '../../../Services'
import { Button, Card, Flex, Text } from '../../../Components'
import OperationFixed from './Components/OperationFixed'
import { Document, Page } from 'react-pdf'
import OperationRelative from './Components/OperationRelative'
import NewDocumentForm from './Components/NewDocumentForm'
import { useLocation, useParams } from 'react-router-dom'
import { PageContainer } from '../../../Components/Layout/PageContainer'
import { useMount } from 'react-use'

const MARGIN = 10

export default function () {
  const { data: environment = {}, isSuccess } = useCQuery(['environmentById', useParams().environment])
  const [numPagesState, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [coordinatesMouse, setCoordinatesMouse] = useState({ x: 0, y: 0 })
  const [coefficientMouse, setCoefficientMouse] = useState({ x: 0, y: 0 })
  const [selectedDocumentType, setSelectedDocumentType] = useState('')
  const { documentId: url = '' } = useParams()
  const documentAwsId = url.replaceAll('_', '/')
  const awsLink = `${documentAwsId}.pdf`
  const [documentLink, setDocumentLink] = useState((documentAwsId && documentAwsId !== 'edit') ? awsLink : '')
  const [documentId, setDocumentId] = useState('')
  const [documentName, setDocumentName] = useState('')
  const refContainer = useRef()
  const { state } = useLocation()

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  useEffect(() => {
    if (isSuccess && awsLink) {
      const foundMappedDocument = environment?.documentsDefinition?.find(({ url, mappingMode }) => url === documentLink) || false
      if (foundMappedDocument) setSelectedDocumentType(foundMappedDocument?.mappingMode)
    }
  }, [environment, isSuccess])

  useMount(() => {
    if (state?.multiSignData) {
      const { url, id = '', type, name } = state?.multiSignData
      handleSelectDoc({ url, id, type, name })
    }
    if (state?.hasFtoSign) {
      const { url, id = '', type, name } = state?.hasFtoSign
      handleSelectDoc({ url, id, type, name })
    }
  })

  const onMouseMove = (e = {}) => {
    setCoordinatesMouse({
      x: e.nativeEvent.x - MARGIN,
      y: e.nativeEvent.y - MARGIN,
      x1: e.nativeEvent.x - MARGIN,
      y1: 799 - e.nativeEvent.y + MARGIN
    })
    setCoefficientMouse({ x: (e.nativeEvent.x - MARGIN) / 565, y: (e.nativeEvent.y - MARGIN) / 799 })
  }

  const handleSelectDoc = ({ url, id, type, name }) => {
    setDocumentLink(url)
    setDocumentId(id)
    setSelectedDocumentType(type)
    setDocumentName(name)
  }

  return (
    <PageContainer style={{ paddingTop: 0 }}>
      {!documentLink
        ? <NewDocumentForm handleSelectDoc={handleSelectDoc} />
        : (
          <>
            <Flex js as row fw>
              <Flex style={{ paddingTop: 20 }}>
                <Card style={{ padding: 10, width: 565, marginLeft: 10, height: 65, borderRadius: 30 }}>
                  <Flex row jb style={{ height: 45 }}>
                    <Button onClick={() => (pageNumber - 1) && setPageNumber(pageNumber - 1)} label='Indietro' icon='left' />
                    <Text value={`Pagina ${pageNumber} di ${numPagesState}`} />
                    <Button onClick={() => pageNumber !== numPagesState && setPageNumber(pageNumber + 1)} label='Avanti' icon='right' invertedIcon />
                  </Flex>
                </Card>

                <Document className='marginDocumentPDF' onLoadSuccess={onDocumentLoadSuccess} file={documentLink}>
                  <Page height={800} width={565} pageNumber={pageNumber} />
                </Document>
              </Flex>
              {
                selectedDocumentType === 'fixed'
                  ? (
                    <OperationFixed
                      documentId={[documentId, setDocumentId]}
                      documentName={[documentName, setDocumentName]}
                      pageNumber={pageNumber}
                      refContainer={refContainer}
                      documentLink={documentLink}
                      onMouseMove={onMouseMove}
                      awsLink={awsLink}
                    />)
                  : selectedDocumentType === 'relative'
                    ? (
                      <OperationRelative
                        documentId={[documentId, setDocumentId]}
                        documentName={[documentName, setDocumentName]}
                        pageNumber={pageNumber}
                        marginBlock={MARGIN}
                        refContainer={refContainer}
                        documentLink={documentLink}
                        onMouseMove={onMouseMove}
                        awsLink={awsLink}
                      />)
                    : null
              }
            </Flex>
            {FC.isDev &&
              <>
                <Flex>{coordinatesMouse.x}********{coordinatesMouse.y}</Flex>
                <Flex>coordinate pdfPrinter :{coordinatesMouse.x1}********{coordinatesMouse.y1}</Flex>
                <Flex>{coefficientMouse.x}********{coefficientMouse.y}</Flex>
              </>}
          </>)}
    </PageContainer>
  )
}
