import React from 'react'
import { Card, Flex, Text, AgencyLogo } from '../../../Components'

const SignCompleted = ({ logo, missingFieldInfo, signature }) => {
  return (
    <Flex fw style={{ paddingTop: 50 }}>
      <AgencyLogo logo={logo} />
      <Card style={{ marginTop: signature.agency === 'viaggigiovani' ? '12vh' : '' }}>
        <Flex width='100%'>
          {window.scrollTo({ top: 0, behavior: 'smooth' })}
          <Text
            size={25} style={{ marginLeft: 10 }} value={
            missingFieldInfo
              ? 'Abbiamo preso in carico la sua richiesta di modifica dei dati anagrafici, a breve riceverà la nuova richiesta di Firma Digitale con i dati aggiornati'
              : signature.agency === 'viaggigiovani'
                ? 'Grazie #NomadeModerno per aver firmato correttamente il contratto. Una copia ti è stata inviata via Email.'
                : 'Firma eseguita correttamente. Il documento firmato ti verrà inviato tramite mail.'
          }
          />
        </Flex>
      </Card>
    </Flex>
  )
}

export default SignCompleted
