import { FC } from '../../Services'

export const verifyRequestCode = async (code) => {
  const response = await FC.service('public').get('remoteSignVerification', { query: { code } })
  if (!response) return false
  return response
}

export const getEnvDesign = async (code) => {
  const design = await FC.service('public').get('envDesign', { query: { code } })
  if (!design) return false
  return design
}
