import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useRef, useState } from 'react'
import { Button, Flex, ModalGeneric, Spinner, Text } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { UserEditor } from './UserEditor'
import { FilterMatchMode } from 'primereact/api'

const UsersList = () => {
  const { data: users = [], isSuccess } = useCQuery('users')
  const refUserEditor = useRef(null)
  const refDelete = useRef(null)
  const [edit, setEdit] = useState('new')
  const [userToDelete, setUserToDelete] = useState(null)
  const [filters] = useState({
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    codSociety: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    opUnit: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  })

  const editUser = (user) => {
    setEdit(user)
    refUserEditor.current.show()
  }

  const createNewUser = () => {
    setEdit('new')
    refUserEditor?.current.show()
  }

  const deleteUser = async () => {
    await FC.service('users').remove(userToDelete)
    invalidateQuery('users')
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js style={{ padding: 20, paddingTop: 0 }}>
      <ModalGeneric ref={refDelete} content="Vuoi cancellare l'utente selezionato?" action={deleteUser} />
      <ModalGeneric ref={refUserEditor} header={`${edit === 'new' ? 'Crea' : 'Modifica'} utente`} content={<UserEditor edit={edit} onClose={() => refUserEditor.current.hide()} />} style={{ width: '70%' }} />
      <Flex fw>
        <Flex jb row fw>
          <Text size={22} bold value='Elenco Utenti' style={{ marginBottom: 15 }} />
          <Button label='Crea nuovo utente' onClick={createNewUser} />
        </Flex>
        <DataTable style={{ width: '100%' }} value={users} filter={filters} filterDisplay='row' paginator rows={20} emptyMessage='Nessun utente trovato' className='TableSignatures'>
          <Column field='username' header='Username' sortable filter filterPlaceholder='Cerca per Username' showFilterMenu={false} />
          <Column field='agency' header='Unità Operativa' sortable filter filterPlaceholder='Cerca per agency' showFilterMenu={false} />
          <Column field='environment' header='Environment' sortable filter filterPlaceholder='Cerca per Environment' showFilterMenu={false} />
          <Column body={(user) => (
            <Flex row>
              <Button label='Modifica' style={{ marginRight: 10 }} icon='edit' onClick={() => editUser(user)} />
              <Button
                label='Elimina' icon='bin' onClick={() => {
                  setUserToDelete(user._id)
                  refDelete.current.show()
                }}
              />
            </Flex>
          )}
          />
        </DataTable>
      </Flex>
    </Flex>
  )
}

export default UsersList
