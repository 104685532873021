import React from 'react'
import { PageContainer } from '../../../Components/Layout/PageContainer'
import { getUrlParams } from '../../../Services'
import TabMultiSign from './TabMultiSign'
import { useNavigate } from 'react-router-dom'
import { Flex, TabHeader, Tabs } from '../../../Components'

const getTabs = () => [
  { id: 0, icon: 'doc-2', title: 'FIRME MULTIPLE IN CORSO', content: <TabMultiSign onlyRequested /> },
  { id: 1, icon: 'doc-2', title: 'FIRME MULTIPLE CONCLUSE', content: <TabMultiSign onlySigned /> }
]

const MultiSign = () => {
  const tab = getUrlParams('tab')
  const navigate = useNavigate()
  const currentTab = parseInt(tab) || 0
  return (
    <PageContainer>
      <Flex fw>
        <Tabs
          style={{ width: '100%', height: '100%', padding: 20 }}
          onTabSelection={(e) => navigate('/multisign/list?tab=' + e)}
          currentTab={currentTab}
        >
          {getTabs()?.map((tab, index) => (
            <TabHeader
              title={tab?.title}
              key={index}
              index={index}
              icon={tab?.icon}
            >
              {tab?.content}
            </TabHeader>
          ))}
        </Tabs>
      </Flex>
    </PageContainer>
  )
}

export default MultiSign
