import React from 'react'
import { ProgressSpinner } from 'primereact/progressspinner'

export const Spinner = ({ size, color }) => {
  const spinnerSize = size || 40
  return (
    <ProgressSpinner
      style={{
        width: `${spinnerSize}px`,
        height: `${spinnerSize}px`,
        marginTop: '10px'
      }}
      strokeWidth='8'
    />
  )
}
