import React from 'react'
import { Flex } from '../Common/Flex'

const Background = ({ theme, children }) => {
  const backgroundStyle = {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    overflow: 'scroll',
    overflowX: 'hidden',
    backgroundImage: `url(${theme})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat',
    paddingBottom: 100
  }

  return (
    <Flex js style={backgroundStyle}>
      {children}
    </Flex>
  )
}
export { Background }
