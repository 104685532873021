import React, { useState } from 'react'
import { Button } from '../../../../Components'

const LightBox = ({ label, icon, view, setView, textValue }) => {
  const [isHovered, setIsHovered] = useState(null)
  const lightboxStyle = {
    height: '90%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.3em',
    backgroundColor: isHovered ? 'lightgreen' : 'rgba(231, 231, 231, 0.8)',
    borderRadius: 20,
    padding: 10,
    backdropFilter: 'blur(4px) saturate(180%)',
    WebkitBackdropFilter: 'blur(4px) saturate(180%)',
    boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)'
  }

  return (
    <Button
      label={label}
      icon={icon}
      style={{ ...lightboxStyle }}
      iconSize={24}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setView(view)}
    />
  )
}

export default LightBox
