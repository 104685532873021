import React, { useState, useEffect } from 'react'
import { Button, Flex, Input } from '../../../Components'
import { FC, invalidateQuery } from '../../../Services'
import { useCQuery } from '../../../Services/QueryCache'

export const UserEditor = ({ edit, onClose }) => {
  const { data: dbAgencies = [], isSuccess: isSuccessAgencies } = useCQuery('agencies')
  const { data: dbEnvs = [], isSuccess: isSuccessEnvs } = useCQuery('environments')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [agency, setAgency] = useState('')
  const [environment, setEnvironment] = useState('')
  const [environmentsOptions, setEnvironmentsOptions] = useState([])
  const [agenciesOptions, setAgenciesOptions] = useState([])
  const isNew = edit === 'new'

  useEffect(() => {
    if (!isNew) {
      setUsername(edit?.username)
      setEnvironment(edit?.environment)
      setAgency(edit?.agency)
    }
  }, [edit])

  useEffect(() => {
    if (isSuccessEnvs) setEnvironmentsOptions(dbEnvs.map(({ _id }) => _id))
  }, [isSuccessEnvs, dbEnvs])

  useEffect(() => {
    if (isSuccessAgencies) setAgenciesOptions(dbAgencies.filter(({ environment: env }) => env === environment).map(({ _id }) => _id))
  }, [environment, isSuccessAgencies, dbAgencies])

  const createNewAgency = async () => {
    const res = await FC.service('users').create({ username, password, agency, environment })
    res._id && window.growl.show({ severity: 'success', summary: 'Utente Creato', detail: 'Utente Creato con successo!' })
    invalidateQuery('users')
  }

  const editAgency = async () => {
    const res = await FC.service('users').patch(edit._id, { username, agency, environment })
    res._id && window.growl.show({ severity: 'success', summary: 'Utente Creato', detail: 'Utente modificato con successo!' })
    invalidateQuery('users')
  }

  const inputPropsText = (id, placeholder, value, setValue) => ({
    placeholder,
    id,
    value: value || '',
    onChange: (e) => setValue(e[id]),
    style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
  })

  return (
    <Flex fw>
      <Flex fw wrap row>
        <Input {...inputPropsText('username', 'Username', username, setUsername)} />
        {isNew && <Input {...inputPropsText('password', 'Password', password, setPassword)} />}
        <Input {...inputPropsText('environment', 'Environment', environment, setEnvironment)} dropdown options={environmentsOptions} />
        <Input {...inputPropsText('agency', 'Agenzia', agency, setAgency)} dropdown options={agenciesOptions} />
      </Flex>
      <Button label={isNew ? 'Crea' : 'Modifica'} icon='plus' style={{ marginTop: 20 }} onClick={isNew ? createNewAgency : editAgency} />
    </Flex>
  )
}
