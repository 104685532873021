import React from 'react'
import { Button, Flex, Input } from '../../../Components'
import { useParams, useNavigate } from 'react-router-dom'
import { saveEnvironment, useEnvironment } from './Functions'
import { PageContainer } from '../../../Components/Layout/PageContainer'
import { Text } from '../../../Components/Common/Text'
import { Card } from '../../../Components/Common/Card'
import { ImagePicker } from '../../../Components/Common/ImagePicker'
import { FC } from '../../../Services'
import { Color } from '../../../Components/Common/ColorPicker'

const defaultStyle = { width: '31%', marginRight: '1%', marginLeft: '1%', marginBottom: 15 }

const sections = [{
  title: 'Dati Generali',
  items: [
    { location: '_id', label: 'ID' },
    { location: 'ssoKey', label: 'SSO Key' },
    { location: 'codSociety', label: 'Codice Società' },
    { location: 'hasFtoSign', label: 'hasFtoSign', dropdown: [{ label: 'Si', value: true }, { label: 'No', value: false }] }
  ]
},
{
  title: 'Configurazione Email',
  items: [
    { location: 'configEmail.from', label: 'Mittente' },
    { location: 'configEmail.host', label: 'Host' },
    { location: 'configEmail.fromLabel', label: 'Scritta Mittente' },
    { location: 'configEmail.subject', label: 'Oggetto Email' },
    { location: 'configEmail.auth.user', label: 'Username' },
    { location: 'configEmail.auth.pass', label: 'Password' },
    { location: 'configEmail.port', label: 'Porta' },
    { location: 'configEmail.secure', label: 'Secure', dropdown: [{ label: 'Si', value: true }, { label: 'No', value: false }] }
  ]
},
{
  title: 'Configurazione Mobyt',
  items: [
    { location: 'mobyt.user', label: 'User' },
    { location: 'mobyt.key', label: 'Password' },
    { location: 'mobyt.senderName', label: 'Mittente' },
    { location: 'mobyt.useSiapConfig', label: 'Usa Configurazione Siap', dropdown: [{ label: 'Si', value: true }, { label: 'No', value: false }] }
  ]
},
{
  title: 'Design',
  items: [
    { location: 'design.name', label: 'Nome' },
    { location: 'design.links.support', label: 'Supporto' },
    { location: 'design.links.contacts', label: 'Contatti' },
    { location: 'design.mainColor', label: 'Main Color', color: true },
    { location: 'design.logo', label: 'Logo', image: true }
  ]
},
{
  title: 'Tema App',
  items: [
    { location: 'appTheme.colors.white', label: 'White', color: true },
    { location: 'appTheme.colors.black', label: 'Black', color: true },
    { location: 'appTheme.colors.grey', label: 'Grey', color: true },
    { location: 'appTheme.colors.themeColor', label: 'Theme Color', color: true },
    { location: 'appTheme.colors.fontColor', label: 'Font Color', color: true },
    { location: 'appTheme.colors.textButtonColor', label: 'Text Button Color', color: true },
    { location: 'appTheme.logoImage', label: 'Immagine Logo / Sfondo App', image: true }
  ]
}
]

const defaultColors = { translucent: 'rgba(255, 255, 255, 0.55)', opaque: 'rgba(255, 255, 255, 0.8)', transparent: 'rgba(0, 0, 0, 0)', darkTransparent: 'rgba(0,0,0,0.5)' }

export const readFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

const getValueLocation = (location, obj) => {
  const keys = location.split('.')
  return keys.reduce((acc, key) => acc?.[key], obj)
}

export const EnvEditor = () => {
  const { environmentId } = useParams()
  const [environment, setEnvironment, isSuccess] = useEnvironment(environmentId)
  const navigate = useNavigate()

  const isNew = environmentId === 'new'

  const save = async () => {
    let elaboratedEnvironment = { ...environment }
    if (environment?.mobyt?.useSiapConfig) {
      const [{ mobyt: siapMobyt }] = await FC.service('environments').find({ query: { _id: 'siap' } })
      elaboratedEnvironment.mobyt = { ...elaboratedEnvironment.mobyt, ...siapMobyt }
    }
    elaboratedEnvironment = { ...elaboratedEnvironment, appTheme: { colors: { ...defaultColors, ...elaboratedEnvironment.appTheme.colors }, ...elaboratedEnvironment.appTheme } }
    const res = await saveEnvironment(elaboratedEnvironment, isNew)
    res?._id && navigate('/environments/' + res._id)
    return res
  }

  if (!isSuccess || !environment) return null

  return (
    <PageContainer style={{ marginTop: -60 }}>
      <Card>
        <Flex fw>
          <Text value={`${isNew ? 'Crea' : 'Modifica'} Environment`} title bold size={30} style={{ marginBottom: 20 }} />
          <Flex fw wrap row>
            {sections.map(({ title, items }) => (
              <Flex fw key={title} style={{ width: '100%', marginBottom: 20 }}>
                <Text center value={title} title bold size={20} style={{ width: '100%', marginBottom: 20 }} />
                <Flex fw wrap row>
                  {items.map(({ location, label, dropdown, image, color }) => {
                    if (color) {
                      return (
                        <Color
                          value={[getValueLocation(location, environment), (value) => setEnvironment(location, value)]}
                          label={label} key={location}
                        />
                      )
                    }
                    if (image) {
                      return (
                        <ImagePicker
                          key={location} style={{ width: '100%' }} label={label}
                          image={[getValueLocation(location, environment), (value) => setEnvironment(location, value)]}
                        />
                      )
                    }
                    if (dropdown) {
                      return (
                        <Input
                          key={location} id={location}
                          value={getValueLocation(location, environment)} onChange={({ [location]: value }) => setEnvironment(location, value)}
                          label={label} style={defaultStyle} dropdown options={dropdown}
                        />
                      )
                    }
                    return (
                      <Input
                        key={location} id={location} value={getValueLocation(location, environment)}
                        onChange={({ [location]: value }) => setEnvironment(location, value)}
                        label={label} style={defaultStyle}
                      />
                    )
                  })}
                </Flex>
              </Flex>
            ))}
            <Flex row>
              <Button label={isNew ? 'Crea' : 'Salva'} icon='plus' style={{ marginTop: 20 }} onClick={save} />
              <Button label='Indietro' icon='close' style={{ marginTop: 20, marginLeft: 10 }} onClick={() => window.history.back()} />
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </PageContainer>
  )
}
