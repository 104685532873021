import { Button, Flex, Input, Text } from '../../../../../../Components'
import { translateLabelType } from '../Functions'
import { Checkbox } from 'primereact/checkbox'
import _ from 'lodash'
import { useState } from 'react'

export const AnswerRadio = ({ item, index, customRadio: [listCustomRadios, setListCustomRadios], updateCustomRadio }) => {
  const { answerRadio, id, type, question, mandatory } = item
  const [optionValue, setOptionValue] = useState('')

  return (
    <Flex key={id}>
      <Flex style={{ border: '3px solid #32324e', position: 'relative', padding: '0.5em 1.1em', paddingBottom: 10, margin: 10, borderRadius: 12, width: '720px', ...questionStyle }}>
        <Flex fw row style={{ gap: '1em', position: 'relative', margin: '0.2em 0.4em' }}>
          <Text value={`${translateLabelType(type)} ${index + 1}`} size={16} bold />
          <Text style={{ alignSelf: 'right', padding: '0.2em 0.4em', position: 'absolute', right: 0 }} value={`Pagina ${item.page}`} size={14} bold />
        </Flex>
        <Text value={question} size={16} />
        <Flex row>
          <Text value='Obbligatoria' style={{ marginRight: 10 }} />
          <Checkbox
            checked={mandatory}
            onChange={e => {
              const array = [...listCustomRadios]
              const newArray = array.map((obj) =>
                (obj.id !== id) ? obj : { ...obj, mandatory: !obj?.mandatory })
              setListCustomRadios(newArray)
            }}
          />
        </Flex>
        <Button
          style={{ cursor: 'pointer', marginTop: 10 }}
          rich label='Aggiungi Opzione'
          onClick={() => {
            const timestamp = Date.now()
            updateCustomRadio({ answerRadio: [...answerRadio, { id: type + timestamp, answer: '' }] }, id)
          }}
        />
        <span style={{ height: '1px', backgroundColor: '#32324e', width: '80%', margin: '1em auto', display: answerRadio ? 'inline-block' : 'none' }} />

        <Flex row wrap style={{ gap: '1em' }}>
          {answerRadio && answerRadio.map((item, ind) =>
            <Flex key={ind} style={{ borderRadius: 4, padding: '0.5em 1.5em', border: '2px solid #32324e', margin: '10px 0px', position: 'relative' }}>
              <Text value={`OPZIONE ${ind + 1}`} bold size={14} style={{ color: '#32324e' }} />
              <Flex row style={{ gap: '0.5em' }}>
                {item?.answer
                  ? (<Text value={item.answer} />)
                  : (
                    <>
                      <Input
                        value={item?.answer || optionValue}
                        name='optionValue'
                        placeholder={`Opzione ${ind + 1}`}
                        id='optionValue'
                        onChange={(e) => {
                          const updatedOptionValue = e.optionValue
                          setOptionValue(updatedOptionValue)
                        }}
                      />
                      {optionValue !== '' &&
                        <Button
                          style={{ width: '15%' }} icon='check'
                          onClick={(e) => {
                            const array = [...listCustomRadios]
                            item.answer = optionValue || ''
                            setOptionValue('')
                            setListCustomRadios(array)
                          }}
                        />}
                    </>
                    )}
              </Flex>
              <Button
                style={{ position: 'absolute', top: -11, right: -11, width: 22 }}
                icon='close' iconSize={10}
                onClick={(e) => {
                  const array = [...listCustomRadios]
                  const newArray = array.map((obj) => {
                    if (obj.id !== id) {
                      return obj
                    } else {
                      return {
                        ...obj,
                        answerRadio: obj?.answerRadio.filter(answer => answer?.id !== item.id)
                      }
                    }
                  })
                  setListCustomRadios(newArray)
                }}
              />
            </Flex>
          )}
        </Flex>
        <Button
          style={{ cursor: 'pointer', position: 'absolute', top: -11, right: -11, width: 22 }}
          icon='close' iconSize={10}
          onClick={(e) => {
            const array = [...listCustomRadios]
            _.remove(array, (el) => el.id === id)
            setListCustomRadios(array)
          }}
        />
      </Flex>
    </Flex>
  )
}

const questionStyle = {
  backdropFilter: 'blur(4px) saturate(162%)',
  WebkitBackdropFilter: 'blur(4px) saturate(162%)',
  backgroundColor: 'rgba(255, 255, 255, 0.39)',
  borderRadius: '12px',
  border: '1px solid rgba(255, 255, 255, 0.125)'
}
