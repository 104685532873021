import React, { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'

import { Button, Flex, ModalGeneric, Spinner } from '../../Components'
import { useCQuery } from '../../Services'
import ClientData from './ClientData'
import DeleteBtn from './DeleteBtn'
import EditBtn from './EditBtn'

function TabClients () {
  const { isSuccess, data = [] } = useCQuery('clients')
  const { data: environment = {} } = useCQuery('environment')
  const [modalContent, setModalContent] = useState(null)
  const [filters, setFilters] = useState({
    'contactPerson.name': { value: null, matchMode: FilterMatchMode.STARTSWITH },
    'contactPerson.lastname': { value: null, matchMode: FilterMatchMode.STARTSWITH },
    'contactPerson.email': { value: null, matchMode: FilterMatchMode.STARTSWITH },
    'contactPerson.phoneNumber': { value: null, matchMode: FilterMatchMode.STARTSWITH }
  })
  const modalAddClient = useRef(null)

  if (!isSuccess) return <Spinner />

  const editSelectedClient = (rowData) => {
    // Aprire componente inserimento/edit cliente pre-compilato.
    const content = (<ClientData onCreate={hideModal} clientData={rowData} />)
    setModalContent(content)
    modalAddClient?.current?.show ? modalAddClient.current.show() : modalAddClient.show()
  }

  const bodyActions = (rowData) => {
    return (
      <Flex width={220} row>
        <DeleteBtn rowData={rowData} />
        <EditBtn rowData={rowData} editSelectedClient={editSelectedClient} />
      </Flex>
    )
  }

  const hideModal = () => modalAddClient?.current?.hide ? modalAddClient.current.hide() : modalAddClient.hide()

  const openModalAddClient = () => {
    const content = (<ClientData onCreate={hideModal} />)
    setModalContent(content)
    modalAddClient?.current?.show ? modalAddClient.current.show() : modalAddClient.show()
  }

  const bodyAddress = (rowData) => {
    return `${rowData?.address} - ${rowData?.city} - ${rowData?.province} - ${rowData?.phoneNumber}`
  }

  const bodyContactPerson = (rowData) => {
    return `${rowData?.contactPerson?.name} - ${rowData?.contactPerson?.lastname} - ${rowData?.contactPerson?.email} - ${rowData?.contactPerson?.phoneNumber}`
  }

  return (
    <Flex fw fh>
      <ModalGeneric
        ref={modalAddClient}
        content={modalContent}
        header='Inserimento/Variazione cliente'
      />
      <Flex fw row je ae>
        <Button label='Aggiungi un nuovo cliente' icon='account' style={{ margin: 10 }} onClick={openModalAddClient} />
      </Flex>
      <DataTable
        style={{ width: '100%' }}
        value={data} emptyMessage='Nessun cliente da visualizzare' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        filterDisplay='row' filters={filters}
        sortField='createdAt' sortOrder={-1}
      >
        <Column field='contactPerson.name' {...columnProps('Nome')} />
        <Column field='contactPerson.lastname' {...columnProps('Cognome')} />
        <Column field='identifier' {...columnProps('Identificativo')} />
        {!environment.hasFtoSign && (
          <Column {...columnProps('Indirizzo')} body={bodyAddress} />

        )}
        <Column field='contactPerson.email' {...columnProps('Email')} />
        <Column field='contactPerson.phoneNumber' {...columnProps('Telefono')} />
        {!environment.hasFtoSign && (
          <Column {...columnProps('Persona di riferimento')} body={bodyContactPerson} />

        )}
        <Column style={btnColumnStyle()} body={bodyActions} />
      </DataTable>
    </Flex>
  )
}

const btnColumnStyle = () => ({
  border: 'none',
  width: 220,
  height: 15,
  textAlign: 'center'
})

const columnProps = (filterPlaceholder) => ({

  filter: true,
  filterMatchMode: 'startswith',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export default TabClients
