import React from 'react'
import { InputSwitch } from 'primereact/inputswitch'

import { Flex, Text } from '../../../Components'

/* const LOREM =
  'Lorem Ipsum is simply dummy text of the printing and typesetting industry.
  Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s,
  when an unknown printer took a galley of type and scrambled it to make a type specimen book.
  It has survived not only five centuries,
  but also the leap into electronic typesetting, remaining essentially unchanged.
  It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages,
  and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
 */
export default function Option ({ item, optionId, switchAction, switchValue, isLast, signature }) {
  const { titlePrivacy, title, mandatory, descPrivacy } = item

  const titleLabel = titlePrivacy || title
  const agency = signature.agency

  const onChange = (e) => {
    const { value } = e
    switchAction(optionId, value)
  }

  return (
    <Flex width='100%'>
      <Flex width='100%' row jb>
        <Flex as style={{ marginRight: 5 }}>
          <Flex row>
            <Text value={titleLabel} />
            {mandatory && <Text size={20} color='red' value='*' />}
          </Flex>
          {descPrivacy && descPrivacy !== titleLabel && <Text value={descPrivacy} />}
        </Flex>
        <Flex style={{ marginLeft: 5 }}>
          {agency === 'viaggigiovani'
            ? (
              <InputSwitch checked={switchValue} onChange={onChange} className={mandatory && !switchValue ? 'mandatory-switch-viaggigiovani' : ''} />)
            : (
              <InputSwitch checked={switchValue} onChange={onChange} className={mandatory && !switchValue ? 'mandatory-switch' : ''} />
              )}
        </Flex>
      </Flex>
      <hr />
    </Flex>
  )
}
