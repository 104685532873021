import React, { useEffect, useState } from 'react'
import { Button, Text, Flex, Card } from '../../../Components'
import { CommonColors, FC, getUrlParams } from '../../../Services'
import { cap } from '../../../State'
import SignatureModal from './SignatureModal'
import './document.css'
import { useWindowSize } from 'react-use'
import SignCompleted from './SignCompleted'
import DocumentViewer from './DocumentViewer'
import Questions from './Questions'
import { MissingFieldsForm } from './MissingFieldsForm'
import { RadioQuestions } from './RadioQuestions'

export default function DocumentPage ({ code, signature, designPage }) {
  const [open, setOpen] = useState(false)
  const [signCompleted, setSignCompleted] = useState(false)
  const [numberOfDocuments, setNumberOfDocuments] = useState(1)
  const [currentDocumentIndex, setCurrentDocumentIndex] = useState(0)
  const [updatedDocuments, setUpdatedDocuments] = useState([])
  const [documentURL, setDocumentURL] = useState('')

  const [customAnswers, setCustomAnswers] = useState([])
  const [customQuestions, setCustomQuestions] = useState([])
  const [fullList, setFullList] = useState([])

  const [radioAnswers, setRadioAnswers] = useState([])
  const [radioQuestions, setRadioQuestions] = useState([])
  const [radioFullList, setRadioFullList] = useState([])

  const [italicSignB64, setItalicSignB64] = useState('')
  const { width: screenWidth } = useWindowSize()
  const [sentMissingFields, setSentMissingFields] = useState(false)

  useEffect(() => {
    const { documents = [], remoteSign: { italicSign = '' } } = signature
    const { documentUrl, questions = [], customRadio } = documents[currentDocumentIndex]
    setNumberOfDocuments(documents.length)
    setDocumentURL(documentUrl)
    setCustomQuestions(questions)
    setRadioQuestions(customRadio)

    customRadio && setRadioAnswers(customRadio.map((el) => ''))
    questions.length && setCustomAnswers(questions.map((el) => false))
    setFullList(questions.map((el, i) => el))
    setRadioFullList(customRadio.map((el, i) => el))
    setItalicSignB64(italicSign)
    return () => { }
  }, [currentDocumentIndex])

  const updateDocuments = () => {
    const currentDocument = { ...signature.documents[currentDocumentIndex] }
    const newDocuments = [
      ...updatedDocuments,
      {
        ...currentDocument,
        questions: customQuestions.map((el, i) => ({
          ...el,
          accept: customAnswers[i] === null ? '' : customAnswers[i] ? 'Y' : 'N'
        })),
        customRadio: radioQuestions.map((el, i) => ({
          ...el,
          selectedAnswer: radioAnswers[i]
        }))
      }
    ]
    setUpdatedDocuments(newDocuments)
    return newDocuments
  }

  const nextDocument = () => {
    const errorCustom = customQuestions.find(({ mandatory }, i) => mandatory && !customAnswers[i]) ||
      radioQuestions.find(({ mandatory }, i) => mandatory && !radioAnswers[i])
    if (errorCustom) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Alcuni opzioni sono obbligatori'
      })
      return false
    }
    updateDocuments()
    setCurrentDocumentIndex(currentDocumentIndex + 1)
  }

  const handleSign = async (signB64) => {
    if (!signB64 || !signB64.length) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Inserire un tratto nel blocco firma'
      })
      return
    }
    const docs = updateDocuments()
    // const { latitude, longitude } = cap.locationData.state
    const payload = {
      action: 'doSignature',
      requestId: signature._id,
      requestData: signature.requestData,
      signMetadata: {
        // latitude,
        // longitude
      },
      documents: [...docs],
      signBase64: signB64,
      signToken: signature.remoteSign.signToken
    }

    const response = await FC.service('public').create(payload)
    if (!response) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Firma non andata a buon fine'
      })
    } else {
      setSignCompleted(true)
      const returnUrl = getUrlParams('returnUrl')
      if (returnUrl) window.location.href = returnUrl
    }
  }

  const onChangeCustomAnswers = (update) => setCustomAnswers(update)

  const onChangeCustomRadio = (update) => setRadioAnswers(update)

  const openPanel = () => {
    const errorCustom = customQuestions.find(({ mandatory }, i) => mandatory && !customAnswers[i]) ||
      radioQuestions.find(({ mandatory }, i) => mandatory && !radioAnswers[i])
    if (errorCustom) {
      return window.growl.show({
        severity: 'error',
        summary: 'Errore',
        detail: 'Alcuni opzioni sono obbligatori'
      })
    }
    return setOpen((o) => !o)
  }

  return (
    <>
      {signCompleted || sentMissingFields
        ? <SignCompleted logo={designPage.logo} signature={signature} missingFieldInfo={!!sentMissingFields} />
        : (
          <Flex fw row as>
            {screenWidth > cap.smallScreen.state && <DocumentViewer documentURL={documentURL} designPage={designPage} />}
            <Flex width={screenWidth < cap.smallScreen.state ? '100%' : '40%'} style={{ padding: 20 }} js>
              <Flex fw js>
                <Card style={{ width: '100%' }}>
                  <Flex fw js as>
                    <span translate='no' class='notranslate'>
                      <Text title bold value='RICHIESTA DI FIRMA: ' />
                    </span>
                  </Flex>
                  <Questions fullList={fullList} onChangeCustomAnswers={onChangeCustomAnswers} signature={signature} />
                  <RadioQuestions radioFullList={radioFullList} onChangeCustomRadio={onChangeCustomRadio} />
                  <Flex fw>
                    <SignatureModal
                      handleSign={handleSign}
                      italicSign={italicSignB64}
                      designPage={designPage}
                      open={open}
                      setOpen={setOpen}
                    />
                    {currentDocumentIndex + 1 === numberOfDocuments
                      ? (
                        <span translate='no' class='notranslate'>
                          <Button
                            label='Firma'
                            onClick={openPanel}
                            style={{ backgroundColor: designPage?.mainColor, color: '#fff' }}
                            inverted
                          />
                        </span>
                        )
                      : (
                        <Button
                          label='Documento Successivo'
                          onClick={nextDocument}
                          style={{ backgroundColor: designPage?.mainColor, color: '#fff' }}
                          inverted
                        />)}
                    {screenWidth < cap.smallScreen.state &&
                      (
                        <Button
                          label='Visualizza documento'
                          style={{ backgroundColor: designPage?.mainColor, marginTop: 20 }}
                          styleText={{ color: CommonColors.white }}
                          inverted
                          onClick={() => window.open(documentURL)}
                        />)}
                  </Flex>
                </Card>
                {signature?.multiSignId && <MissingFieldsForm signature={signature} onSendMissingFields={() => setSentMissingFields(true)} />}
              </Flex>
            </Flex>
          </Flex>)}
    </>
  )
}
