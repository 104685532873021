import React from 'react'
import { Button } from '../../Components'

export default function EditBtn ({ rowData, editSelectedClient }) {
  const onClickEdit = (rowData) => () => {
    editSelectedClient && editSelectedClient(rowData)
  }
  return (
    <Button
      rich
      round
      tooltip='Modifica cliente'
      icon='edit'
      onClick={onClickEdit(rowData)}
      style={{ cursor: 'pointer', marginLeft: 5 }}
    />
  )
}
