import { FC, invalidateQuery } from '../../../../Services'

/**
 * Soft delete the email template by setting the deleted flag to true
 * and invalidate the cache
 */
export const deleteEmailTemplates = async (emailTemplateToDelete) => {
  await FC.service('emailTemplates').patch(emailTemplateToDelete._id, {
    deleted: true,
    deletedAt: new Date(),
    updatedAt: new Date()
  })
  invalidateQuery('emailTemplates')
}

export const toggleRestoreMenuButtonStyle = (marginRightParam) => ({
  width: 'max-content',
  marginRight: marginRightParam || 0,
  padding: '5px'
})

/**
   * Get the correct email templates to show, based on the showDeletedEmails flag
   */
export const getTableElements = (emailTemplates, showDeletedEmails) => (showDeletedEmails
  ? emailTemplates.filter(e => e.deleted === true)
  : emailTemplates.filter(e => e.deleted === false))

/**
   * Show the modal and execute the function if it exists,
   * passing the args if they exist
   *
   * @param {function} func - Function to execute
   * @param {object} ref - The modal ref
   * @param {object} args - Arguments to pass to the function
   *
   * @example (edit, refModalEdit, emailTemplate)
   */
export const emailTemplateActionModal = (func, ref, args) => {
  func && args
    ? func(args)
    : func && func()
  ref && ref.current.show()
}
