import React from 'react'

export const PageContainer = ({ children, style }) => (
  <div
    id='empty-page-container'
    style={{
      scrollBehavior: 'smooth',
      position: 'absolute',
      top: 50,
      bottom: 0,
      left: 0,
      right: 0,
      padding: 20,
      paddingTop: 80,
      overflow: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'none',
      ...style
    }}
  >
    {children}
  </div>
)
