import React, { useState } from 'react'
import { Flex, Text } from '../../../Components'
import Option from './Option'

export default function Questions ({ fullList, onChangeCustomAnswers, signature }) {
  const [customAnswers, setCustomAnswers] = useState([])

  const setCustomAnswer = (index, answer) => {
    const newAnswers = [...customAnswers]
    newAnswers[index] = answer
    setCustomAnswers(newAnswers)
    onChangeCustomAnswers(newAnswers)
  }

  const customOptions = (index, element, length) => (
    <Option
      key={index}
      item={element}
      optionId={index}
      switchAction={setCustomAnswer}
      switchValue={customAnswers[index]}
      signature={signature}
    />
  )

  return (
    <Flex fw as style={{ marginTop: 15 }}>
      {fullList.length > 0 &&
        <Flex fw as style={{ backgroundColor: '#f8f8ff ', opacity: '0.9', padding: 12, borderRadius: 12, margin: '0.5em 0em' }}>
          <Text title bold value='Accettazione Consensi' />
          {fullList.map((pr, index) => customOptions(index, pr, fullList.length, signature))}
        </Flex>}
    </Flex>
  )
}
