import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState, useRef } from 'react'
import { AgencyLogo, Button, Card, Flex, ModalGeneric, Spinner, Text } from '../../../Components'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { useNavigate, useParams } from 'react-router-dom'
import { cap } from '../../../State'
import { useMount } from 'react-use'
import { PageContainer } from '../../../Components/Layout/PageContainer'

const PdfList = () => {
  const { environment } = useParams()
  const { data: currentEnvironment = {}, isSuccess: isSuccessEnv } = useCQuery(['environmentById', environment])
  const mappedDocs = currentEnvironment?.documentsDefinition || []
  const [documentToDelete, setDocumentToDelete] = useState({})
  const refModalDelete = useRef(null)
  const navigate = useNavigate()

  useMount(() => cap.selectedEnv.setState(environment))

  return !isSuccessEnv
    ? <Spinner />
    : (
      <PageContainer>
        <AgencyLogo />
        <ModalGeneric
          ref={refModalDelete}
          header='Cancellazione documento'
          content={
            <Flex style={{ marginBottom: 20 }}>
              <Text value='Vuoi cancellare il documento selezionato?' size={16} />
            </Flex>
          }
          styleText={{ textAlign: 'center' }}
          yes='Elimina documento'
          no='Annullare'
          action={async () => {
            await FC.client.service('environments').patch(currentEnvironment?._id, {
              documentsDefinition: currentEnvironment?.documentsDefinition.filter(({ url }) => url !== documentToDelete)
            })
            invalidateQuery('environment')
          }}
          style={{ backgroundColor: '#E1EAF4' }}
        />
        <Card style={{ padding: 50, borderRadius: 20, marginTop: 50 }}>
          <Flex><Text value='Gestione firme documenti' size={30} bold /></Flex>
          <Flex row je ae style={{ marginTop: 30 }}>
            <Button
              rich icon='plus' label='Crea nuovo documento'
              onClick={() => navigate('/pdf/' + environment + '/edit')}
              style={{ cursor: 'pointer' }}
            />
          </Flex>
          <DataTable
            value={mappedDocs} paginator
            rows={10} sortField='createdAt'
            sortOrder={-1} responsive
            emptyMessage='Nessun documento da visualizzare'
            className='TableGeneral'
          >
            <Column field='name' header='Nome' />
            <Column field='createdAt' header='Data Creazione' body={({ createdAt }) => new Date(createdAt).toLocaleString('IT-it')} />
            <Column
              style={{ overflowX: 'hidden' }} field='url' header='URL' body={({ url = '' }) =>
                <a href={url} style={{ color: 'black' }} target='_blank' rel='noreferrer'>{url?.replace(url?.substring(50), '...')}</a>}
            />
            <Column body={({ mappingMode }) => mappingMode === 'fixed' ? 'Firma riferimento assoluto' : 'Firma riferimento relativo'} header='Tipo documento' />
            <Column
              style={{ border: 'none' }}
              body={(row) => (
                <Flex row style={{ width: 220, height: '15px', textAlign: 'center' }}>
                  <Button
                    rich round icon='bin' style={{ cursor: 'pointer', marginRight: 5 }}
                    tooltip='Cancella documento'
                    onClick={() => {
                      setDocumentToDelete(row?.url)
                      refModalDelete?.current?.show()
                    }}
                  />
                  <Button
                    rich round icon='info' style={{ cursor: 'pointer' }} tooltip='Visualizza Anteprima'
                    onClick={() => navigate('/pdf/' + environment + '/' + row?.url?.replace('.pdf', '').replaceAll('/', '_'))}
                  />
                </Flex>
              )}
            />
          </DataTable>
        </Card>
      </PageContainer>)
}

export default PdfList
