import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useWindowSize } from 'react-use'
import { Button, Card, Flex, Text } from '../../../Components'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const DocumentViewer = ({ documentURL, designPage }) => {
  const [numPages, setNumPages] = useState()
  const [pageNumber, setPageNumber] = useState(1)
  const { width: screenWidth } = useWindowSize()

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages)

  return (
    <Flex js style={{ padding: 10 }}>
      <Flex fw>
        <Document
          className='PDFDocument' file={documentURL}
          onLoadError={() => window.growl.show({ severity: 'info', summary: 'Info', detail: 'Per visualizzare il documento clicca sul bottone "Visualizza Documento"' })}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            className='PDFPage'
            width={screenWidth * 0.4}
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderInteractiveForms={false}
          />
        </Document>
      </Flex>
      <Card style={{ width: '100%', padding: 10 }}>
        <Flex row fw jb style={{ padding: 0 }}>
          <Button onClick={() => (pageNumber - 1) && setPageNumber(pageNumber - 1)} label='Indietro' />
          <Text value={`Pagina ${pageNumber} di ${numPages}`} />
          <Button onClick={() => pageNumber !== numPages && setPageNumber(pageNumber + 1)} label='Avanti' />
        </Flex>
      </Card>
      <Button
        label='Apri documento' inverted onClick={() => window.open(documentURL)}
        style={{ backgroundColor: designPage?.mainColor, marginTop: 10 }}
      />
    </Flex>
  )
}

export default DocumentViewer
