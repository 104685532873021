import _ from 'lodash'
import { FC, invalidateQuery } from '../../../../../Services'
import { cap } from '../../../../../State'

const elementTypes = ['sign', 'date', 'fixedLabel']

export const mountFn = async (documentLink, awsLink) => {
  const obj = {}
  const res = await FC.service('pdfParser').get('parser', { query: { documentUrl: documentLink } })
  let editedParsedPDF = []
  if (res.ok) {
    res.data.pages.forEach(page => {
      const arrayPageContent = page.content.map(word => ({
        page: page.pageInfo.num,
        ...word,
        pageWidth: page.pageInfo.width,
        pageHeight: page.pageInfo.height
      }))
      editedParsedPDF = [...editedParsedPDF, ...arrayPageContent]
    })
    obj.parsedDocument = editedParsedPDF.filter(({ str }) => str?.length > 1)
  } else {
    console.log('ERRORE ', res.error)
  }

  const [environment] = await FC.service('environments').find({ query: { _id: cap.selectedEnv.state } })

  const addElement = (element, ind, type) => {
    const foundStr = editedParsedPDF.find(({ str }) => str === element?.keyString)
    return ({
      key: {
        x: (foundStr?.x * 565 / foundStr.pageWidth),
        y: (foundStr?.y * 799 / foundStr.pageHeight),
        str: foundStr?.str
      },
      status: 2,
      ...(element?.title ? { title: element.title } : {}),
      page: foundStr.page,
      ...(type === 'sign'
        ? { width: 200, height: 45 }
        : {}),
      progressiveNumber: ind + 1,
      id: type + Math.floor(Math.random() * 10000000000000),
      type: type,
      coordinates: {
        x: (foundStr?.x * 565 / foundStr.pageWidth) + element.offsetX,
        y: type === 'sign' ? (((foundStr?.y * 799 / foundStr.pageHeight) + element.offsetY) - 45) : ((foundStr?.y * 799 / foundStr.pageHeight) + element.offsetY)
      }
    })
  }

  if (awsLink) {
    const foundMappedDocument = environment?.documentsDefinition?.find(({ url, mappingMode }) => url === documentLink && mappingMode === 'relative') || false
    if (foundMappedDocument) {
      const documents = await FC.service('documents').find({ query: { uri: documentLink, $sort: { createdAt: -1 } } })
      obj.documentId = documents[0]._id
      obj.documentName = foundMappedDocument?.name || ''
      obj.signDateArray = elementTypes.map(t => (foundMappedDocument?.documentElements[t] || [])?.map((e, i) => addElement(e, i, t))).flat()
      const custumQuestionArray = foundMappedDocument?.documentElements?.customCheck?.map((element, ind) => {
        const foundStr = editedParsedPDF.find(({ str }) => str === element?.keyString)
        return ({
          id: element.idFlagQuestion,
          progressiveNumber: ind,
          status: 3,
          key: {
            x: foundStr?.x,
            y: foundStr?.y,
            str: foundStr?.str
          },
          page: foundStr.page,
          type: 'customCheck',
          question: element?.title || '',
          idFlagPrivacy: element?.idFlagPrivacy || 0,
          isFlagPrivacy: element?.isFlagPrivacy || false,
          mandatory: element?.mandatory || false,
          answerQuestion: element?.answerQuestion?.map(el => ({
            width: 18,
            height: 18,
            id: 'customCheck' + Math.floor(Math.random() * 10000000000000),
            page: foundStr.page,
            isYesAnswer: el.isYesAnswer,
            coordinates: {
              x: foundStr.x + el.offsetX,
              y: (foundStr.y + el.offsetY) - 20
            }
          }))
        })
      })
      obj.listCustomQuestions = custumQuestionArray
    }
  }
  return obj
}

export const saveAll = async ({ documentLink, documentName }, signDateArray, listCustomQuestions) => {
  const [currentEnvironment] = await FC.service('environments').find({ query: { _id: cap.selectedEnv.state } })
  const positionDefinitionDocElements = { sign: [], date: [], customCheck: [], fixedLabel: [] }
  signDateArray.filter(({ status }) => status === 2).forEach(item => {
    const { type, key, title } = item
    const getCorrectOffsetByType = ({ x: offsetLeft, y: offsetTop }, typeOffset) => {
      if (typeOffset === 'sign') return { coordinateX: offsetLeft, coordinateY: offsetTop + 45 }
      return { coordinateX: offsetLeft, coordinateY: offsetTop }
    }
    const { coordinateX, coordinateY } = getCorrectOffsetByType(item.coordinates, type)
    positionDefinitionDocElements[type] = [
      ...positionDefinitionDocElements[type],
      {
        ...(title ? { title } : {}),
        keyString: key?.str,
        offsetX: coordinateX - key?.x,
        offsetY: coordinateY - key?.y
      }]
  })
  listCustomQuestions.filter(({ status }) => status === 3).forEach(({ question, id, isFlagPrivacy, idFlagPrivacy = 0, mandatory, key, ...otherData }) => {
    const { answerQuestion } = otherData
    const getAnswerQuestion = () =>
      answerQuestion.map(({ page, isYesAnswer = false, ...check }) => {
        const offsetX = check.coordinates.x - key?.x
        const offsetY = check.coordinates.y - key?.y + 20
        return {
          offsetX,
          offsetY,
          refFlagQuestion: id,
          isYesAnswer
        }
      })
    if (answerQuestion?.length) {
      positionDefinitionDocElements.customCheck = [...positionDefinitionDocElements.customCheck, {
        keyString: key?.str,
        title: question,
        mandatory: mandatory || false,
        idFlagPrivacy: parseInt(idFlagPrivacy),
        isFlagPrivacy,
        idFlagQuestion: id,
        answerQuestion: [...getAnswerQuestion()]
      }]
    }
  })
  try {
    const oldEnv = await FC.service('environments').get(currentEnvironment?._id)
    const res = await FC.service('pdfParser').get('parser', { query: { documentUrl: documentLink } })
    if (res?.ok) {
      const keyDoc = _?.uniq(res?.data?.pages?.map(({ content }) => content?.map(({ str }) => str))?.flat())?.sort((a, b) => a?.length > b?.length ? -1 : 1)?.slice(0, 10)
      const foundDocumentDef = oldEnv?.documentsDefinition?.find(d => !_.difference(d.keyDoc, keyDoc).length)
      if (foundDocumentDef) {
        await FC.service('environments').patch(currentEnvironment?._id, {
          documentsDefinition: oldEnv?.documentsDefinition?.map((d) =>
            d.name !== foundDocumentDef.name
              ? d
              : {
                  ...d,
                  url: documentLink,
                  name: documentName,
                  keyDoc,
                  mappingMode: 'relative',
                  signWidth: 200,
                  checkSize: 15,
                  dateSize: 12,
                  documentElements: positionDefinitionDocElements
                })
        })
      } else {
        await FC.service('environments').patch(currentEnvironment?._id, {
          documentsDefinition: [
            ...(oldEnv?.documentsDefinition || []),
            {
              createdAt: new Date(),
              url: documentLink,
              name: documentName,
              keyDoc,
              mappingMode: 'relative',
              signWidth: 200,
              checkSize: 15,
              dateSize: 12,
              documentElements: positionDefinitionDocElements
            }
          ]
        })
      }
    }
    invalidateQuery('environment')
  } catch (error) {
    console.log(error)
  }
}

export const typeToLabel = (type) => {
  switch (type) {
    case 'sign':
      return 'Firma'
    case 'customCheck':
      return 'Domanda personalizzata'
    case 'date':
      return 'Data'
    case 'fixedLabel':
      return 'Testo'
    default:
      break
  }
}

export const generateNewSignDate = (item, typeSelected, [signDateArray, setSignDateArray]) => {
  const getSignDateObject = (item) => {
    const timestamp = Date.now()
    const getWidthAndHeight = () => {
      switch (typeSelected) {
        case 'sign':
          return { width: 200, height: 45 }
        case 'date':
          return { /* width: 30, height: 20  */ }
        default:
          break
      }
    }
    const newElement = {
      ...(item?.fixedLabel ? { title: item.fixedLabel } : {}),
      coordinates: { x: 250, y: 400 },
      type: typeSelected,
      id: typeSelected + (timestamp),
      progressiveNumber: signDateArray.length,
      ...getWidthAndHeight()
    }
    return newElement
  }

  const newAr = [...signDateArray]
  newAr[signDateArray.length - 1] = {
    key: { x: item?.x * 565 / item.pageWidth, y: item?.y * 799 / item.pageHeight, str: item?.str },
    status: 2,
    page: item?.page,
    ...getSignDateObject(item)
  }
  setSignDateArray(newAr)
}

export const generateNewCustomQuestion = (item, typeSelected, [listCustomQuestions, setListCustomQuestions]) => {
  const getCustomQuestionObject = () => ({
    question: '',
    id: typeSelected + (Date.now()),
    type: typeSelected,
    progressiveNumber: listCustomQuestions.length - 1,
    answerQuestion: []
  })

  const newAr = [...listCustomQuestions]
  newAr[listCustomQuestions.length - 1] = {
    key: {
      x: item?.x,
      y: item?.y,
      str: item?.str
    },
    status: 2,
    page: item?.page,
    ...getCustomQuestionObject()
  }
  setListCustomQuestions(newAr)
}
