import React from 'react'
import { Draggable } from './Draggable'
import crossMark from '../Image/crossMark.png' // 18 x 18

const DraggableCustomQuestion = ({ parentRef, listState: [elements, setElements], pageNumber }) => {
  const updateElementListQuestion = (idCross, update) =>
    setElements(elements.map(({ answerQuestion, ...otherData }) => ({
      ...otherData,
      answerQuestion: answerQuestion.map(cross => cross.id === idCross ? { ...cross, ...update } : cross)
    })))

  return elements.length !== 0 &&
    elements.map(({ answerQuestion = [], id, type, progressiveNumber }) => {
      return answerQuestion.filter(item => item.page === pageNumber).map((item, ind) =>
        <Draggable
          isCustomQuestion
          marginDefault={10}
          key={`${id}-${ind}`}
          dimensionContainer={{ width: item.width, height: item.height }}
          refParentDiv={parentRef}
          startX={item.coordinates.x}
          startY={item.coordinates.y}
          callbackUpdatePosition={(x, y) => updateElementListQuestion(item.id, { coordinates: { x: x - 20, y: y - 20 } })}
        >
          <img id={item.id} src={crossMark} style={{ backgroundColor: item.isYesAnswer ? '#008800' : '#D02F24', width: 20, height: 20 }} />
          <span style={{ position: 'absolute', left: 18 }} className='p-badge'>{progressiveNumber + 1}</span>
        </Draggable>
      )
    })
}

export default DraggableCustomQuestion
