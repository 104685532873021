import React, { useState } from 'react'
import { FC, getHost } from '../../Services'
import { Background } from '../Layout/Background'
import { prefetchQueries } from '../../Services/QueryCache'
import { cap, doLogout } from '../../State'
import { PageUtils } from '../Layout/PageUtils'
import { ReactQueryDevtools } from 'react-query-devtools'
import { Spinner } from './Spinner'
import { useMount } from 'react-use'

const AppContainer = ({ children }) => {
  const [{ jwt = '' }, setAuth] = cap.auth.useState()
  const [waiting, setWaiting] = useState(true)

  useMount(async () => {
    if (FC.authenticated || !jwt) {
      setWaiting(false)
      return true
    }
    const { user } = await FC.login({ strategy: 'jwt', accessToken: jwt })
    setWaiting(false)
    if (!FC.authenticated) return doLogout()
    const [agency] = await FC.service('agencies').find({ query: { _id: user.agency } })
    const environment = await FC.service('environments').get(agency?.environment)

    setAuth({
      jwt,
      environment: environment?._id,
      hasEstemporaneo: environment?.hasEstemporaneo,
      hasMultiSign: environment?.hasMultiSign,
      userId: user._id,
      username: user.username,
      agency: user.agency,
      noTablet: user.noTablet,
      isEnvAdmin: user.isEnvAdmin,
      isSingleUO: user.isSingleUO,
      isAdmin: user.isAdmin,
      hasAtl: agency?.hasAtl || false,
      hasFtoSign: environment?.hasFtoSign || false
    })
    prefetchQueries()
  })

  return (
    <Background theme={getHost().theme}>
      <ReactQueryDevtools initialIsOpen={false} />
      <PageUtils />
      {waiting
        ? <Spinner />
        : children}
    </Background>
  )
}

export default AppContainer
