import { Dropdown } from 'primereact/dropdown'
import React, { useState } from 'react'
import { Button, Flex, Input } from '../../../../../Components'
import { headerLabel } from '../../../../MultiSign/New/Functions'

export const CorrectButton = ({ type, signDateClick, customCheckClick, customRadioCheckClick, fixedLabelClick, clientFields, specialClick }) => {
  const [fixedLabel, setFixedLabel] = useState('')
  const [customQuestion, setCustomQuestion] = useState('')
  const [clientField, setClientField] = useState('')
  const [customRadio, setCustomRadio] = useState('')

  const renderButton = () => {
    switch (type) {
      case 'sign':
      case 'date':
        return <Button icon='add' style={{ width: 250 }} label={type === 'sign' ? 'Inserisci firma' : 'Inserisci data'} onClick={() => signDateClick({ type })} />
      case 'special':
        return (
          <Flex row>
            <Dropdown
              value={clientField} options={clientFields.map(i => ({ label: headerLabel(i), value: i }))}
              onChange={({ value }) => setClientField(value)} placeholder='Seleziona Campo'
              style={{ width: 250, marginRight: 10 }}
            />
            <Button icon='add' label='Inserisci' onClick={() => specialClick(clientField)} />
          </Flex>
        )
      case 'customCheck':
        return (
          <Flex row>
            <Input
              label='Testo domanda:' id='customQuestion' value={customQuestion}
              onChange={(e) => setCustomQuestion(e.customQuestion)}
            />
            <Button
              icon='add' style={{ marginBottom: 5, marginLeft: 10, width: 500 }} label='Crea domanda personalizzata'
              onClick={() => { customCheckClick(customQuestion); setCustomQuestion('') }} disabled={!customQuestion}
            />
          </Flex>
        )
      case 'fixedLabel':
        return (
          <Flex row>
            <Input
              label='Testo:' id='fixedLabel' value={fixedLabel}
              onChange={(e) => setFixedLabel(e.fixedLabel)}
            />
            <Button
              icon='add' style={{ marginLeft: 10, width: 220 }} label='Inserisci'
              onClick={() => { fixedLabelClick(fixedLabel); setFixedLabel('') }} disabled={!fixedLabel}
            />
          </Flex>
        )
      case 'RadioType':
        return (
          <Flex row>
            <Input
              label='Testo Domanda:' id='customRadio' value={customRadio}
              onChange={(e) => setCustomRadio(e.customRadio)}
            />
            <Button
              icon='add' style={{ marginBottom: 5, marginLeft: 10, width: 500 }} label='Crea Domanda'
              onClick={() => { customRadioCheckClick(customRadio); setCustomRadio('') }} disabled={!customRadio}
            />
          </Flex>
        )
      default:
        return null
    }
  }
  return renderButton()
}
