import React from 'react'
import { Button, Flex, Text } from '../../../Components'

export default function ModalContent ({ onHide, onConfirm, contentText = '', contentBody }) {
  return (
    <Flex>
      <Text value={contentText} />
      {contentBody && contentBody()}
      <Flex row style={{ marginTop: 10 }}>
        <Button label='Annulla' icon='close' onClick={onHide} style={{ marginRight: 10 }} />
        <Button label='Conferma' icon='check' onClick={onConfirm} style={{ marginLeft: 10 }} />
      </Flex>
    </Flex>
  )
}
