import React from 'react'
import { Answer } from './Answer'

export const CustomQuestion = ({
  questionsState: [listCustomQuestions, setListCustomQuestions],
  id: key, item, page
}) => {
  const updateCustomCheck = (newData, id) =>
    setListCustomQuestions(listCustomQuestions.map((el = {}) => el.id === id ? { ...el, ...newData } : el))

  return (
    <Answer
      page={page} item={item} index={key} updateCustomCheck={updateCustomCheck}
      customQuestion={[listCustomQuestions, setListCustomQuestions]}
    />
  )
}
