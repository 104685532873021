import React, { useState } from 'react'
import { useMount } from 'react-use'

import { prefetchQueries } from '../../Services/QueryCache'
import { getUrlParams, isJWTValid, FC, getLogoByAgency } from '../../Services'
import { cap, doLogout } from '../../State'

const gotoHome = (success) => {
  window.location = '/'
}

export const restoreState = async (ssoJWT) => {
  const { jwt = '' } = ssoJWT ? { jwt: ssoJWT } : cap.auth.state

  if (!jwt) return false
  if (!isJWTValid(jwt)) return doLogout()

  const { user, accessToken } = await FC.login({ strategy: 'jwt', accessToken: jwt })
  if (!FC.authenticated) return doLogout()
  prefetchQueries()

  if (ssoJWT && accessToken) {
    const logo = await getLogoByAgency(user.agency)
    const [agency] = await FC.service('agencies').find({ query: { _id: user.agency } })
    const environment = await FC.service('environments').get(agency?.environment)
    cap.logo.patchState({ url: logo })

    cap.auth.patchState({
      hasEstemporaneo: environment?.hasEstemporaneo,
      hasMultiSign: environment?.hasMultiSign,
      environment: environment?._id,
      jwt: accessToken,
      userId: user._id,
      username: user.username,
      agency: user.agency,
      noTablet: user.noTablet,
      isEnvAdmin: user.isEnvAdmin,
      isSingleUO: user.isSingleUO,
      isAdmin: user.isAdmin,
      hasAtl: agency?.hasAtl || false,
      hasFtoSign: environment?.hasFtoSign || false
    })
    if (user?.isEnvAdmin) window.location = '/env'
    else window.location = '/'
  }
}

export default function SingleSignOn () {
  const [status, setStatus] = useState(true)
  const [message, setMessage] = useState('')

  const token = getUrlParams('token')

  const handleSSOFailure = (data) => {
    setTimeout(() => gotoHome(false), 2000) && setStatus(false)
    setMessage(data ? data.reason : 'Errore Sconosciuto')
  }

  const handleSSOResponse = ({ jwt }) =>
    isJWTValid(jwt) ? restoreState(jwt) : handleSSOFailure()

  useMount(() =>
    token
      ? FC.service('public').get('singleSignOn', { query: { token } }).then((data) => handleSSOResponse(data || {}))
      : handleSSOFailure({ reason: 'Token non fornito' })
  )

  return token
    ? <div>{status ? 'Richiesta Single Sign On in corso...' : `Richiesta SSO Fallita: ${message}`}</div>
    : <div>Token SSO mancante: http://server.com/sso?token=XXX</div>
}
