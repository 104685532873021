
import { Logos, Themes } from '../Resources'

export const DOMAINS = [

  {
    host: ['dockertest01.siapcn.lan', 'dockertest01'],
    logo: Logos.siap,
    theme: Themes.siap,
    url: 'http://dockertest01.siapcn.lan:10802'
  },
  {
    host: ['firmadoc.robintur.it'],
    logo: Logos.robintur,
    theme: Themes.robintur,
    url: 'https://firmadoc.robintur.it'
  },
  {
    host: ['firmadoctest.robintur.it'],
    logo: Logos.robintur,
    theme: Themes.robintur,
    url: 'https://firmadoctest.robintur.it'
  },
  {
    host: ['firmaci.siap.cloud'],
    logo: Logos.blueteam,
    theme: Themes.siblueteamap,
    url: 'https://firmaci.siap.cloud'
  },
  {
    host: ['firmacitest.siap.cloud'],
    logo: Logos.blueteam,
    theme: Themes.siap,
    url: 'https://firmacitest.siap.cloud'
  },
  {
    host: ['localhost', '192.168.1.191', '192.168.71.133'],
    logo: Logos.siap,
    theme: Themes.siap,
    url: 'http://localhost:4001'
  },
  {
    host: ['sign.siap.cloud'],
    logo: Logos.siap,
    theme: Themes.siap,
    url: 'https://sign.siap.cloud'
  },
  {
    host: ['signgattinonitest.siap.cloud'],
    logo: Logos.gattinoni,
    theme: Themes.gattinoni,
    url: 'https://signgattinonitest.siap.cloud'
  },
  {
    host: ['firmadoc.gattinoni.it'],
    logo: Logos.gattinoni,
    theme: Themes.gattinoni,
    url: 'https://signgattinonitest.siap.cloud'
  },
  {
    host: ['sign.siap.cloud'],
    logo: Logos.siap,
    theme: Themes.isolaazzurra,
    url: 'https://sign.siap.cloud'
  },
  {
    host: ['sign.siap.cloud'],
    logo: Logos.siap,
    theme: Themes.viaggigiovani,
    url: 'https://sign.siap.cloud'
  }
]
