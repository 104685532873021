import React from 'react'
import { Flex, Text } from '../../../Components'

export default function SignInformation ({ generalInfo, code, isExpired, logo }) {
  return (
    <>
      <Flex style={{ margin: 10 }}>
        {generalInfo?.environment === 'viaggigiovani' && generalInfo?.userEmail && generalInfo?.userPhone
          ? (<Text value='RICHIESTA FIRMA DIGITALE' size={32} />)
          : (<Text value='Benvenuto nella pagina per effettuare una richiesta di firma da remoto.' size={25} />)}
        {generalInfo.agencyName && generalInfo.userEmail && generalInfo.userPhone
          ? (
            <Flex style={{ marginTop: 25 }}>
              <Text
                value={`La seguente richiesta di firma proviene da: ${generalInfo.agencyName}.`}
                size={25}
              />
              <Text
                value={`Email dell'utente a cui è indirizzata la firma: ${generalInfo.userEmail}.`}
                size={25}
                style={{ marginTop: 10 }}
              />
              <Text
                value={`Telefono dell'utente a cui è indirizzata la firma: ${generalInfo.userPhone}.`}
                size={25}
                style={{ marginTop: 10 }}
              />
            </Flex>
            )
          : (
            <Flex>
              {isExpired
                ? <Text value={"La tua richiesta di firma è scaduta, contatta l'agenzia per maggiori informazioni."} size={25} />
                : (
                  <>
                    <Text value={`Il codice ${code} non corrisponde a nessuna richiesta di firma ancora da completare.`} size={25} />
                    <Text value={'Contatta l\'agenzia che te lo ha fornito per maggiori informazioni.'} size={25} />
                  </>)}
            </Flex>
            )}

      </Flex>
    </>
  )
}
