import React, { useState } from 'react'
import { Button, Card, Flex, Input, Text } from '../../../Components'
import { useMount } from 'react-use'
import { FC } from '../../../Services'

export const headerLabel = (text) => {
  switch (text) {
    case 'userName': return 'Nome'
    case 'userLastname': return 'Cognome'
    case 'userEmail': return 'Email'
    case 'userPhone': return 'Cellulare'
    case 'cap': return 'CAP'
    case 'cit': return 'Città'
    case 'prov': return 'Provincia'
    case 'piva': return 'P.Iva'
    case 'cfis': return 'Codice Fiscale'
    case 'descGr': return 'Descrizione Gruppo'
    case 'ind': return 'Indirizzo'
    case 'codUn': return 'Codice Univoco'
    case 'pec': return 'PEC'
    case 'notes': return 'Note'
    default: return text
  }
}
const inputProps = (value, set, label) => ({
  id: [value],
  value,
  style: { width: '100%', marginRight: 20 },
  label,
  onChange: (e) => set(e[value])
})

export const MissingFieldsForm = ({ signature, onSendMissingFields }) => {
  const [showClientData, setShowClientData] = useState(false)
  const [clientData, setClientData] = useState({})

  useMount(() => {
    const getClientFields = async () => {
      const res = await FC.service('public').get('getClientFieldMultiSign', { query: { signatureId: signature._id.toString() } })
      res && setClientData({ ...res, notes: '' })
    }
    getClientFields()
  })

  const sendEmailToAgency = async () => {
    const res = await FC.service('public').create({ action: 'sendFieldsToAgency', clientData, agency: signature?.agency })
    res && window.growl.show({ severity: 'success', summary: 'Email inviata con successo' })
    onSendMissingFields()
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <Flex fw>
        <Text size={16} value='Prima di firmare verifica che i campi inseriti nel documento siano COMPLETI e CORRETTI. Se ti sembrano errati, premi il bottone qua sotto.' />
        {!showClientData && <Button label='Contatta Agenzia' icon='info' onClick={() => setShowClientData(true)} />}
        {showClientData && (
          <Flex fw>
            <Text value='Modifica o inserisci i campi errati' size={18} bold title />
            {
              Object.keys(clientData).map((key, ind) => (
                <Input key={ind} {...inputProps(clientData[key], (text) => setClientData({ ...clientData, [key]: text }), headerLabel(key))} style={{ marginTop: 10 }} />
              ))
            }
            <Button style={{ marginTop: 10 }} label='Invia Richiesta di Modifica' icon='send' onClick={sendEmailToAgency} />
          </Flex>
        )}
      </Flex>
    </Card>
  )
}
