import React from 'react'

export const BadgeKeyWord = ({ pageNumber, badgeKeyWordRender }) =>
  badgeKeyWordRender.filter(bad => bad.page === pageNumber).map((badge, index) => (
    <div
      key={index}
      style={{
        position: 'absolute',
        left: (badge.x / badge.pageWidth) * 565,
        top: (badge.y / badge.pageHeight) * 799,
        zIndex: 20
      }}
    >
      <span className='p-badge'>{index + 1}</span>
    </div>
  ))
