import React from 'react'
import { AgencyLogo } from '../../Components'
import Devices from './Devices'
import { PageContainer } from '../../Components/Layout/PageContainer'

const DevicesPage = () => {
  return (
    <PageContainer>
      <AgencyLogo />
      <Devices />
    </PageContainer>
  )
}

export default DevicesPage
