import { FC, invalidateQuery } from '../../../../Services'

/**
   * Input props for the Input component
   *
   * @param {string} id
   * @param {string} placeholder
   * @param {string} value
   * @param {function} setValue
   *
   * @returns {object} props
   */
export const inputPropsDropdown = (id, placeholder, value, setValue) => ({
  placeholder,
  id,
  value: value || '',
  onChange: (e) => setValue(e[id]),
  style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
})

/**
   * Input props for the ObjectInput component
   *
   * @param {string} id
   * @param {string} placeholder
   * @param {string} value
   * @param {function} setValue
   *
   * @returns {object} props
   */
export const objectInputPropsDropdown = (id, placeholder, value, setValue) => ({
  placeholder,
  id,
  value: value || '',
  setValue: setValue,
  onChange: (e) => setValue(e[id]),
  style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
})

export const nullEmailTypes = {
  signatureRequest: null,
  reminder: null,
  error: null,
  expired: null
}

/**
   * Delete the type by setting the template type to empty string
   * and invalidate the cache
   *
   * @param {string} type - The type to delete
   *
   * @example ('signatureRequest')
   */
export const deleteTemplateType = async ({
  templateTypeToDelete: template, targetEmailTypeByEnvironment, setTargetEmailTypesByEnvironment,
  templateExists, setTemplateExists, setIsCalledAfterDelete
}) => {
  setTargetEmailTypesByEnvironment({ ...targetEmailTypeByEnvironment, [template.type]: null })
  setTemplateExists({ ...templateExists, [template.type]: null })
  setIsCalledAfterDelete(true)
  await FC.service('emailTemplates').patch(template.id, { [template.type]: '', updatedAt: new Date() })
  invalidateQuery('emailTemplates')
}

export const emailTypes = (environment) => ({
  signatureRequest: environment,
  reminder: environment,
  error: environment,
  expired: environment
})

/**
   * Edit the email template environment
   * and invalidate the cache
   */
export const editEmailTemplate = async (types, targetEnvironment, sourceEnvironment, onClose, _id) => {
  const data = {}

  try {
    for (const type of Object.keys(types)) {
      await (async () => {
        data[type] = types[type]
          ? (await FC.service('emailTemplates').find({ query: { environment: types[type] } }))[0][type]
          : ''
      })()
    }
  } catch (error) {
    console.error(error)
  }

  const res = await FC.service('emailTemplates').patch(_id, {
    ...data,
    environment: targetEnvironment || sourceEnvironment,
    updatedAt: new Date()
  })
  res._id && window.growl.show({
    severity: 'success',
    summary: 'Email Creata',
    detail: 'Email Modifica con successo!'
  })
  invalidateQuery('emailTemplates')
  onClose()
}

export const objectInputContainerStyle = (
  type, templateExists, targetEmailTypeByEnvironment, isCalledAfterDelete
) => ({
  width: '500px',
  textTransform: 'capitalize',
  marginRight: templateExists[type] ? '0' : (isCalledAfterDelete ? '0' : '245px'),
  alignItems: targetEmailTypeByEnvironment[type] ? 'end' : 'center',
  justifyContent: targetEmailTypeByEnvironment[type] ? 'center' : 'end'
})
