import { FC, invalidateQuery } from '../../../../Services'

/**
   * Input props for the Input component
   *
   * @param {string} id
   * @param {string} placeholder
   * @param {string} value
   * @param {function} setValue
   *
   * @returns {object} props
   */
export const inputPropsDropdown = (id, placeholder, value, setValue) => ({
  placeholder,
  id,
  value: value || '',
  onChange: (e) => setValue(e[id]),
  style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
})

/**
   * Input props for the ObjectInput component
   *
   * @param {string} id
   * @param {string} placeholder
   * @param {string} value
   * @param {function} setValue
   *
   * @returns {object} props
   */
export const objectInputPropsDropdown = (id, placeholder, value, setValue) => ({
  placeholder,
  id,
  value: value || '',
  setValue: setValue,
  onChange: (e) => setValue(e[id]),
  style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
})

export const nullEmailTypes = () => ({
  signatureRequest: null,
  reminder: null,
  error: null,
  expired: null
})

export const createEmailTemplate = async (targetEnvironment, targetEmailTypesByEnvironment, onClose) => {
  if (targetEnvironment) {
    try {
      const data = {}
      for (const type of Object.keys(targetEmailTypesByEnvironment)) {
        if (targetEmailTypesByEnvironment[type] === null) {
          data[type] = ''
        } else {
          await (async () => {
            const [template] = await FC.service('emailTemplates').find({
              query: { environment: targetEmailTypesByEnvironment[type] }
            })
            data[type] = template[type]
          })()
        }
      }
      await FC.service('emailTemplates').create({
        environment: targetEnvironment,
        ...data,
        deleted: false,
        deletedAt: null,
        createdAt: new Date(),
        updatedAt: null
      })
      window.growl.show({
        severity: 'success',
        summary: 'Email Created',
        detail: 'Email successfully created!'
      })
    } catch (error) {
      console.error(error)
    }
    invalidateQuery('emailTemplates')
    onClose()
  } else {
    window.growl.show({
      severity: 'error',
      summary: 'Target email is required.',
      detail: 'Select one from the \'Receiver:\''
    })
  }
}
