import React, { useState } from 'react'
import EmailPreview from '../EmailPreview'
import { Flex, Text, ObjectInput } from '../../../../Components'
import { nullEmailTypes, objectInputPropsDropdown } from './functions'

/**
   * Create a block of inputs and a preview for each email type
   *
   * @param {string} type
   *
   * @returns {JSX.Element}
   */
const TypeSetterArea = ({
  targetEmailTypesByEnvironment: [targetEmailTypesByEnvironment, setTargetEmailTypesByEnvironment],
  previewsEnvironments: [previewsEnvironments, setPreviewsEnvironments],
  filteredEnvironments, type
}) => {
  const title = type === 'signatureRequest' ? 'Signature' : type
  const [templateExists, setTemplateExists] = useState(nullEmailTypes())

  /**
   * Handle enviroment change per email type
   *
   * @param {string} input - The new environment
   * @param {string} type - The email type
   *
   * @example ('esitur', 'signatureRequest')
   */
  const handleTargetEnvironmentInputChangePerType = (input, type) => {
    setTargetEmailTypesByEnvironment({ ...targetEmailTypesByEnvironment, [type]: input })
    setPreviewsEnvironments({ ...previewsEnvironments, [type]: input })
  }

  return (
    <>
      <Text center value={title} size='31px' style={titleStyle} />
      <Flex width='100%' je>
        <Flex row style={{ width: '30%', textTransform: 'capitalize' }}>
          <ObjectInput
            {...objectInputPropsDropdown(
              type, 'Environment', targetEmailTypesByEnvironment[type],
              handleTargetEnvironmentInputChangePerType
            )}
            dropdown options={filteredEnvironments}
          />
        </Flex>
        {targetEmailTypesByEnvironment[type] &&
          <EmailPreview
            environment={previewsEnvironments[type]} type={type} previews={previewsEnvironments}
            setTypeByEnvironment={(env) => handleTargetEnvironmentInputChangePerType(env, type)}
            setAllPreviewsEnvironments={(e) => setPreviewsEnvironments(e)}
            setTemplateExists={setTemplateExists} templateExists={{ ...templateExists, [type]: true }}
          />}
      </Flex>
    </>
  )
}

const titleStyle = {
  display: 'block',
  textTransform: 'capitalize',
  margin: '20px 0'
}

export default TypeSetterArea
