import _ from 'lodash'
import { FC, invalidateQuery } from '../../../../../Services'
import { cap } from '../../../../../State'

const elementTypes = ['sign', 'date', 'fixedLabel', 'special']

export const saveAll = async ({ documentId, documentLink, documentName }, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions, isEstemporaneo = false, multiSignId = '') => {
  const [currentEnvironment] = await FC.service('environments').find({ query: { _id: cap.selectedEnv.state || cap.auth.state?.environment } })
  const positionDefinitionDocElements = { sign: [], date: [], customCheck: [], customRadio: [], fixedLabel: [], ...(multiSignId ? { special: [] } : {}) }
  elementAddedSignDate.forEach(item => {
    const { page, type } = item
    const getCorrectOffsetByType = ({ x: offsetLeft, y: offsetTop }, typeOffset) => {
      if (typeOffset === 'sign') return { coordinateX: offsetLeft, coordinateY: offsetTop + 45 }
      return { coordinateX: offsetLeft, coordinateY: offsetTop }
    }
    const { coordinateX, coordinateY } = getCorrectOffsetByType(item.coordinates, type)
    positionDefinitionDocElements[type] = [...positionDefinitionDocElements[type], {
      ...(item?.title ? { title: item.title } : {}),
      ...(item?.clientField ? { clientField: item.clientField } : {}),
      page,
      coefficientX: coordinateX / 565,
      coefficientY: coordinateY / 799
    }]
  })
  listCustomQuestions.forEach(({ question, id, mandatory, idFlagPrivacy = 0, answerQuestion, ...otherData }) => {
    const getAnswerQuestion = () =>
      answerQuestion.map(({ page, isYesAnswer = false, ...check }) => {
        const coefficientX = (check.coordinates.x) / 565
        const coefficientY = (check.coordinates.y + 20) / 799
        return {
          coefficientX,
          coefficientY,
          page,
          refFlagQuestion: id,
          isYesAnswer
        }
      })
    if (answerQuestion?.length) {
      positionDefinitionDocElements.customCheck = [...positionDefinitionDocElements.customCheck, {
        ...otherData,
        title: question,
        mandatory: mandatory || false,
        idFlagQuestion: id,
        idFlagPrivacy: parseInt(idFlagPrivacy),
        answerQuestion: [...getAnswerQuestion()]
      }]
    }
  })
  listCustomRadioQuestions.forEach(({ question, id, mandatory, answerRadio, page, coordinates, ...otherData }) => {
    if (answerRadio?.length) {
      const coefficientX = (coordinates.x) / 565
      const coefficientY = (coordinates.y) / 799
      positionDefinitionDocElements.customRadio = [...positionDefinitionDocElements.customRadio, {
        ...otherData,
        title: question,
        coefficientX,
        coefficientY,
        page,
        mandatory: mandatory || false,
        answerQuestion: answerRadio
      }]
    }
  })

  if (!isEstemporaneo) {
    try {
      const oldEnv = await FC.service('environments').get(currentEnvironment?._id)
      const res = await FC.service('pdfParser').get('parser', {
        query: {
          documentUrl: documentLink
        }
      })
      if (res?.ok) {
        const keyDoc = _?.uniq(res?.data?.pages?.map(({ content }) => content?.map(({ str }) => str))?.flat())?.sort((a, b) => a?.length > b?.length ? -1 : 1)?.slice(0, 10)
        const foundDocumentDef = oldEnv?.documentsDefinition?.find(d => !_.difference(d.keyDoc, keyDoc).length && (!d.multiSignId || multiSignId === d.multiSignId))
        if (foundDocumentDef) {
          await FC.service('environments').patch(currentEnvironment?._id, {
            documentsDefinition: oldEnv?.documentsDefinition?.map((d) =>
              d.name !== foundDocumentDef.name
                ? d
                : {
                    ...d,
                    url: documentLink,
                    name: documentName,
                    keyDoc,
                    mappingMode: 'fixed',
                    signWidth: 200,
                    checkSize: 15,
                    dateSize: 12,
                    ...(multiSignId ? { multiSignId } : {}),
                    documentElements: positionDefinitionDocElements
                  }
            )
          })
          multiSignId && invalidateQuery('multiSign')
        } else {
          await FC.service('environments').patch(currentEnvironment?._id, {
            documentsDefinition: [
              ...(oldEnv?.documentsDefinition || []),
              {
                createdAt: new Date(),
                url: documentLink,
                name: documentName,
                keyDoc,
                mappingMode: 'fixed',
                signWidth: 200,
                checkSize: 15,
                dateSize: 12,
                ...(multiSignId ? { multiSignId } : {}),
                documentElements: positionDefinitionDocElements
              }
            ]
          })
          multiSignId && invalidateQuery('multiSign')
        }
      }
    } catch (error) {
      console.log(error)
    }
  } else {
    try {
      const res = await FC.service('pdfParser').get('parser', {
        query: {
          documentUrl: documentLink
        }
      })
      if (res?.ok) {
        const keyDoc = _?.uniq(res?.data?.pages?.map(({ content }) => content?.map(({ str }) => str))?.flat())?.sort((a, b) => a?.length > b?.length ? -1 : 1)?.slice(0, 10)
        await FC.service('documents').patch(documentId, {
          isEstemporaneo: true,
          documentsDefinition: [
            {
              url: documentLink,
              name: documentName,
              keyDoc,
              mappingMode: 'fixed',
              signWidth: 200,
              checkSize: 15,
              dateSize: 12,
              documentElements: positionDefinitionDocElements
            }
          ]
        })
        return { ok: true }
      }
    } catch (error) {
      console.log(error)
    }
  }
  invalidateQuery('environment')
}

export const mountFn = async (documentLink, awsLink, multiSignIdRef) => {
  const obj = {}

  const addElement = (element, ind, type) => {
    return ({
      page: element.page,
      ...(type === 'sign'
        ? { width: 200, height: 45 }
        : {}),
      progressiveNumber: ind + 1,
      id: type + Math.floor(Math.random() * 10000000000000),
      type: type,
      coordinates: {
        x: 565 * element.coefficientX,
        y: type === 'sign' ? ((799 * element.coefficientY) - 45) : (799 * element.coefficientY)
      },
      ...(element?.title ? { title: element?.title || '' } : {}),
      ...(element?.clientField ? { clientField: element?.clientField || '' } : {})
    })
  }

  if (!cap.auth.state.jwt) window.location = window.origin
  const [environment] = await FC.service('environments').find({ query: { _id: cap.selectedEnv.state || cap.auth.state?.environment } })
  if (awsLink) {
    const foundMappedDocument = environment?.documentsDefinition?.find(({ url, mappingMode, multiSignId }) => url === documentLink && mappingMode === 'fixed' && (!multiSignId || multiSignId === multiSignIdRef)) || false
    if (foundMappedDocument) {
      const documents = await FC.service('documents').find({ query: { uri: documentLink, $sort: { createdAt: -1 } } })
      obj.documentId = documents[0]._id
      obj.documentName = foundMappedDocument?.name || ''
      obj.elementAddedSignDate = elementTypes.map(t => (foundMappedDocument?.documentElements[t] || [])?.map((e, i) => addElement(e, i, t))).flat()
      const custumQuestionArray = foundMappedDocument?.documentElements?.customCheck?.map((element, ind) => {
        return ({
          id: element.idFlagQuestion,
          progressiveNumber: ind,
          type: 'customCheck',
          descPrivacy: element?.descPrivacy || '',
          question: element?.title || '',
          idFlagPrivacy: element?.idFlagPrivacy || 0,
          mandatory: element?.mandatory || false,
          answerQuestion: element?.answerQuestion?.map(el => ({
            width: 18,
            height: 18,
            id: 'customCheck' + Math.floor(Math.random() * 10000000000000),
            page: el.page,
            isYesAnswer: el.isYesAnswer,
            coordinates: {
              x: 565 * el.coefficientX,
              y: (799 * el.coefficientY) - 20
            }
          }))
        })
      })
      obj.listCustomQuestions = custumQuestionArray
      const customRadioArray = foundMappedDocument?.documentElements?.customRadio?.map((element, ind) => {
        return ({
          id: element.idFlagQuestion,
          progressiveNumber: ind,
          type: 'RadioType',
          question: element?.title || '',
          mandatory: element?.mandatory || false,
          page: element?.page,
          coordinates: {
            x: 565 * element?.coefficientX,
            y: (799 * element?.coefficientY)
          },
          answerRadio: element?.answerQuestion?.map(el => ({
            id: 'customRadio' + Math.floor(Math.random() * 10000000000000),
            answer: el?.answer
          }))
        })
      })
      obj.listCustomRadioQuestions = customRadioArray
    }
  }
  return obj
}

export const translateLabelType = (type) => {
  switch (type) {
    case 'sign':
      return 'Firma'
    case 'customCheck':
      return 'Domanda personalizzata'
    case 'RadioType':
      return 'Domanda '
    case 'PrivacyCheck':
      return 'Privacy atlante crocette'
    case 'date':
      return 'Data documento'
    case 'fixedLabel':
      return 'Testo'
    case 'special':
      return 'Speciale'
    default:
      break
  }
}

export const generateCustomQuestion = (listCustomQuestions, questionText) => {
  const timestamp = Date.now()
  const newElement = {
    question: questionText,
    id: 'customCheck' + (timestamp),
    type: 'customCheck',
    progressiveNumber: listCustomQuestions.length,
    answerQuestion: []
  }
  const newList = [...listCustomQuestions, newElement]
  return newList
}
export const generateCustomRadioQuestion = (listCustomRadioQuestions, questionText, page) => {
  const timestamp = Date.now()
  const newElement = {
    question: questionText,
    id: 'customRadio' + (timestamp),
    type: 'RadioType',
    progressiveNumber: listCustomRadioQuestions.length,
    coordinates: { x: 250, y: 400 },
    answerRadio: [],
    page
  }
  const newList = [...listCustomRadioQuestions, newElement]
  return newList
}

export const handleSendRequest = async (documentId, userInformation, signType, remoteMode, mailType, tablet, forceOtp, selection = 'send') => {
  // signType => TABLET / REMOTE
  // mailType => mail / PEC
  // tablet => {deviceCode:'xxx',...}
  // forceOtp => true / false

  await FC.service('services').create({
    action: 'signRequest',
    deviceCode: signType === 'REMOTE' ? 'REMOTE' : tablet?.deviceCode,
    error: false,
    messageError: '',
    requestSource: 'WEB',
    requestDest: signType === 'REMOTE' ? 'REMOTE' : 'APP',
    requestDestType: remoteMode,
    userInformation,
    documents: [{ documentId, type: 'EST' }],
    success: false,
    forceOtp: forceOtp ? 'useOtp' : 'noOtp',
    usePec: !!(mailType === 'PEC'),
    wait: selection === 'wait'
  })
}
