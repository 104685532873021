import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useRef, useState } from 'react'
import { Button, Flex, ModalGeneric, Spinner, Text } from '../../../Components'
import { FC, useCQuery, invalidateQuery } from '../../../Services'
import { AgencyEditor } from './AgencyEditor'
import { FilterMatchMode } from 'primereact/api'

const AgencyList = () => {
  const { data: agencies = [], isSuccess } = useCQuery('agencies')
  const refModalEdit = useRef(null)
  const refDelete = useRef(null)
  const [edit, setEdit] = useState('new')
  const [agencyToDelete, setAgencyToDelete] = useState(null)
  const [filters] = useState({
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    codSociety: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    opUnit: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  })

  const editAgency = (agency) => {
    setEdit(agency)
    refModalEdit.current.show()
  }

  const createAgency = () => {
    setEdit('new')
    refModalEdit.current.show()
  }

  const deleteAgency = async () => {
    await FC.service('agencies').remove(agencyToDelete)
    invalidateQuery('agencies')
  }

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js>
      <ModalGeneric ref={refDelete} content="Vuoi cancellare l'utente selezionato?" action={deleteAgency} />
      <ModalGeneric ref={refModalEdit} header={`${edit === 'new' ? 'Crea' : 'Modifica'} agenzia`} content={<AgencyEditor edit={edit} onClose={() => refModalEdit?.current.hide()} />} style={{ width: '70%' }} />
      <Flex fw>
        <Flex fw jb row>
          <Text size={22} bold value='Elenco Agenzie' style={{ marginBottom: 15 }} />
          <Button label='Crea nuova Agenzia' onClick={createAgency} />
        </Flex>
        <DataTable style={{ width: '100%' }} value={agencies} filter={filters} filterDisplay='row' paginator rows={20} emptyMessage='Nessuna agenzia trovato' className='TableSignatures'>
          <Column field='_id' header='Id' sortable filter filterPlaceholder='Cerca per Id' showFilterMenu={false} />
          <Column field='codSociety' header='Codice Società' sortable filter filterPlaceholder='Cerca per Cod Soc' showFilterMenu={false} />
          <Column field='opUnit' header='Unità Operativa' sortable filter filterPlaceholder='Cerca per opUnit' showFilterMenu={false} />
          <Column field='email' header='Email' sortable filter filterPlaceholder='Cerca per Email' showFilterMenu={false} />
          <Column body={(agency) => (
            <Flex row>
              <Button label='Modifica' icon='edit' style={{ marginRight: 10 }} onClick={() => editAgency(agency)} />
              <Button label='Elimina' icon='bin' onClick={() => { setAgencyToDelete(agency._id); refDelete.current.show() }} />
            </Flex>)}
          />
        </DataTable>
      </Flex>
    </Flex>
  )
}

export default AgencyList
