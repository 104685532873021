import React, { useRef } from 'react'
import { Button, ModalGeneric } from '../../Components'
import { FC, invalidateQuery, showGrowl } from '../../Services'
import ModalContent from '../Signatures/Components/ModalContent'

export default function DeleteBtn ({ rowData }) {
  const deleteRef = useRef()

  const deleteSelectedDevice = async (rowData) => {
    try {
      const response = await FC.service('services').create({ action: 'removeTablet', deviceCode: rowData.deviceCode })
      if (!response?.ok) return showGrowl('error', 'Errore', 'Errore nell\'eliminazione del dispositivo. Riprova')
      invalidateQuery('devices')
      return showGrowl('success', 'Dispositivo eliminato', 'Dispositivo eliminato con successo!')
    } catch {
      return showGrowl('error', 'Errore', 'Errore nell\'eliminazione del dispositivo. Riprova')
    }
  }

  return (
    <>

      <ModalGeneric
        ref={deleteRef}
        header='Eliminazione Tablet'
        content={<ModalContent
          onHide={() => deleteRef.current.hide()}
          onConfirm={() => deleteSelectedDevice(rowData)}
          contentText='Sicuro di volere disassociare questo tablet?'
                 />}
      />
      <Button
        rich
        round
        tooltip='Dissocia dispositivo'
        icon='close'
        onClick={() => deleteRef.current.show()}
        style={{ cursor: 'pointer' }}
      />
    </>
  )
}
