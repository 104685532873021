import { FC } from '../../../Services'
import Handlebars from 'handlebars'

Handlebars.registerHelper({
  isDocumentTypeCON: (type) => {
    return !!(type === 'CON')
  },
  isDocumentTypePRI: (type) => {
    return !!(type === 'PRI')
  },
  isDocumentTypeEST: (type) => {
    return !!(type === 'EST')
  },
  isDocumentTypeCSTM: (type) => {
    return !!(type === 'CSTM')
  },
  isCrisalideEnvironment: (environment) => {
    return !!(environment === 'crisalide')
  },
  isRequestDestinationRemote: (requestDestination) => {
    return !!(requestDestination === 'REMOTE')
  },
  isRequestDestinationApp: (requestDestination) => {
    return !!(requestDestination === 'APP')
  },
  isNotSigned: (signStatus) => {
    return !!(signStatus === 'requested')
  },
  isUrlProvided: (url) => {
    return !!(url)
  },
  isDocInfoProvided: (type, year, number) => {
    return !!(type && year && number)
  }
})

/**
 * Get environment data
 *
 * @param {string} env - Environment id
 *
 * @returns {object} environment
 *
 * @example 'esitur'
 */
export const getEnv = async (env) => {
  return (await FC.service('environments').find({ query: { _id: env } }))
}

/**
 * Get agency data
 *
 * @param {string} agency - Agency id
 *
 * @returns {object} agency
 *
 * @example 'esitur'
 */
export const getAgency = async (agency) => {
  return (await FC.service('agencies').find({ query: { _id: agency } }))
}

/**
 * Get sample data for the template compilation
 *
 * @param {string} type - Type of Email
 * @param {object} data.dataRequestInformation - Data to fill the template
 *
 * @returns {object} templateData
 */
const getSampleTemplateData = async (type, data) => {
  let templateData = {}
  if (type === 'signatureRequestMail' || type === 'reminderMail') {
    const [environment] = await getEnv(data.environment)
    const [agency] = await getAgency(data.agency)
    const { name, logo, mainColor, logoBkgc = '#51536B', logoMail = false, links } = (agency.design || environment.design)
    const url = `${window.location.host}/X44UH5UTRLYTHA8EA85V?env=${data.environment}`

    const fullUserName = data.userName && data.userLastname
      ? `${data.userName} ${data.userLastname}`
      : data.userName || data.userLastname
        ? data.userName || data.userLastname
        : null

    templateData = {
      design: {
        name: name === 'Amministrazione Enterprise' ? 'Enterprise' : name,
        logo: logo || logoMail,
        mainColor: mainColor,
        logoBkgc: logoBkgc,
        logoMail: logoMail,
        socialsLinks: links?.socials?.pages,
        socialsImageLinks: links?.socials?.images,
        supportLink: links?.support,
        contactLink: links?.contacts,
        description: 'NAPOLI TRENO + HOTEL EXCELSIOR - 09/11/2023',
        trimmedDescription: 'NAPOLI TRENO + HOTEL EXCELSIOR',
        agencyPhone: '3344556677',
        userName: (fullUserName || 'Cliente'),
        userFirstName: (data?.userName || ''),
        userNameUppercase: ((fullUserName && fullUserName.toUpperCase()) || 'Cliente')
      },
      url: url,
      docType: 123,
      docYear: 456,
      docNumber: 789,
      browserLinkCode: url.split('/').reverse()[0]?.replace('?', '&'),
      signStatus: data.signStatus,
      webBaseUrl: window.location.host,
      environment: data.environment,
      documentType: 'CON',
      requestDestination: Math.random() > 0.5 ? 'REMOTE' : 'APP',
      btnSignText: 'Firma il documento',
      extraMailBody: data.environment === 'enterprise'
        ? (data.extraMailBody || 'Non specificato')
        : (data.extraMailBody || '')
    }
    templateData.design.upperCaseName = templateData.design.name.toUpperCase()

    if (type === 'reminderMail') {
      const { expiringHours = 72 } = await getEnv(data.environment)
      const daysAdd = Math.round(expiringHours / 24)
      templateData.createdDate = new Date(data.createdAt).toLocaleDateString('it-IT')
      templateData.createdTime = new Date(data.createdAt).toLocaleTimeString('it-IT')
      const expiringAt = new Date(data.createdAt)

      templateData.expiringDate = new Date(expiringAt
        .setDate(new Date(data.createdAt).getDate() + daysAdd)
      ).toLocaleDateString('it-IT')
      templateData.expiringTime = new Date(expiringAt
        .setDate(new Date(data.createdAt).getDate() + daysAdd)
      ).toLocaleTimeString('it-IT')
    }
  }

  if (type === 'errorMail') {
    templateData.customerRecordCode = data.customerRecordCode
    templateData.bookingFileCode = data.bookingFileCode
    templateData.opUnit = data.opUnit
    templateData.codSociety = data.codSociety
    templateData.error = data.error
  } else if (type === 'expiredMail') {
    templateData.code = data.code
    templateData.userName = data.userName ? data.userName : 'Cliente'
    templateData.userLastname = data.userLastname
    templateData.createdAt = data.createdAt
  }

  return templateData
}

/**
 * Compile a handlebars partial with the name browserLink
 *
 * @param {string} documentType - Type of document (CON, PRI, EST, CSTM)
 * @param {string} signStatus - Requested/Signed/Cancelled
 * @param {string} environment
 * @param {string} url
 *
 * @returns {string} browserLinkPartial
 */
const browserLinkPartial = Handlebars.compile(`
{{#if (isNotSigned signStatus)}}

  {{#if (isUrlProvided url)}}
    {{webBaseUrl}}/browserEmail?type={{documentType}}&code={{browserLinkCode}}
  {{else}}
    {{webBaseUrl}}/browserEmail?type={{documentType}}&code=error&env={{browserLinkCode}}
  {{/if}}
{{else}}

  {{webBaseUrl}}/browserEmail?type={{documentType}}&env={{environment}}
{{/if}}
`)

/**
 * Compile a handlebars partial with the name documentLabel
 *
 * @param {string} documentType - Type of document (CON, PRI, EST, CSTM)
 * @param {string} signStatus - Requested/Signed/Cancelled
 * @param {string} url
 *
 * @returns {string} documentLabelPartial
 */
const documentLabelPartial = Handlebars.compile(`
{{#if (isNotSigned signStatus)}}

    il <a href="{{url}}" target="_blank">link</a> per effettuare la firma del documento da remoto.
{{else}}
  {{#if (isDocumentTypeCON documentType)}}

    la copia del tuo contratto firmato.
  {{else if (isDocumentTypePRI documentType)}}

    la copia dell'informativa privacy firmata.
  {{else if (isDocumentTypeEST documentType)}}

    la copia del tuo documento firmato.
  {{else if (isDocumentTypeCSTM documentType)}}

    la copia del tuo documento firmato.
  {{/if}}
{{/if}}
`)

/**
 * Compile a handlebars partial with the name extraMailBody
 *
 * @param {string} extraMailBody - Extra mail body
 *
 * @returns {string} extraMailBodyPartial
 */
const extraMailBodyPartial = Handlebars.compile(`
{{#if (extraMailBody)}}
  <p>Informazioni Aggiuntive</p>
  <p>{{extraMailBody}}</p>
{{/if}}
`)

/**
 * Add a class to the style of the template to contain
 * the styles so they don't affect the rest of the page
 * using the type parameter as the class name
 *
 * @param {string} template - Template string
 * @param {string} type - Type of email
 *
 * @returns {string} templateWithContainedStyle
 */
export const cotainTemplateStyle = (template, type) => {
  if (!template.includes('<style>')) return template

  // This regex matches all the CSS selectors in the template
  const styleRegex = /(?<=(\\s|\\S)*\n|,\n(\\s|\\S)*)(?!\*)(?!\s)(?!{)(?:.)*({\n|,\n)/g
  let templateWithContainedStyle = template

  template.match(styleRegex) && template.match(styleRegex).forEach((selector) => {
    if (!templateWithContainedStyle.includes(`.${type} ${selector}`)) {
      templateWithContainedStyle = templateWithContainedStyle.replaceAll(selector, `.${type} ${selector}`)
    }
  })
  templateWithContainedStyle = templateWithContainedStyle.replace('* {', `* .${type} {`)
  templateWithContainedStyle = templateWithContainedStyle.replace('\t', '  ')
  return templateWithContainedStyle
}

const determineSampleData = (email, template) => {
  switch (email.type) {
    case 'signatureRequest':
      return [{
        dataRequestInformation: {
          environment: email.environment,
          agency: email.agency,
          signStatus: 'requested',
          extraMailBody: ''
        },
        type: 'signatureRequestMail',
        label: 'Richiesta di Firma',
        template: template
      },
      {
        dataRequestInformation: {
          environment: email.environment,
          agency: email.agency,
          signStatus: 'signed',
          extraMailBody: ''
        },
        type: 'signatureRequestMail',
        label: 'Richiesta di Firma Completata',
        template: template
      }]
    case 'reminder':
      return [{
        dataRequestInformation: {
          environment: email.environment,
          agency: email.agency,
          createdAt: new Date()
        },
        type: 'reminderMail',
        label: 'Promemoria Email',
        template: template
      }]
    case 'error':
      return [{
        dataRequestInformation: {
          customerRecordCode: 'AAAA',
          bookingFileCode: 'BBBB',
          opUnit: '001',
          codSociety: 'AA',
          error: 'Error Email',
          agency: email.agency,
          environment: email.environment
        },
        type: 'errorMail',
        label: 'Email D\' Errore',
        template: template
      }]
    case 'expired':
      return [{
        dataRequestInformation: {
          code: 'Document Name',
          userName: 'TestUsername',
          userLastname: 'TestLastName',
          createdAt: new Date(),
          agency: email.agency,
          environment: email.environment
        },
        type: 'expiredMail',
        label: 'Email Scaduta',
        template: template
      }]
    default:
      break
  }
}

/**
 * Get the template string from the database or
 * from the user input in case of editing
 *
 * @param {object} email - Email object
 * @param {string} email.type - Type of email
 * @param {string} email.templateInput - Template string
 * @param {string} email.environment
 *
 * @returns {object} { template, newEmail }
 */
const getTemplateString = async (email) => {
  const { environment } = email
  const [defaultEnv] = await FC.service('environments').find()
  const correctEnvironment = environment === 'Common' ? defaultEnv._id : environment
  // if templateInput is provided, use that
  if (email?.templateInput) {
    return {
      template: cotainTemplateStyle(email.templateInput, email.type),
      newEmail: { ...email, environment: correctEnvironment }
    }
  }
  // otherwise get the template from the database
  const [res] = await FC.service('emailTemplates').find({ query: { environment: email.environment } })
  return {
    template: cotainTemplateStyle(res[email.type], email.type),
    newEmail: { ...email, environment: correctEnvironment }
  }
}

/**
 * Compile one or more templates using the Handlebars library
 * with the sample data and return the html string/s
 *
 * @param {object} params.type - Type of email
 * @param {object} params.templateInput - Template string
 * @param {object} params.environment
 *
 * @returns {string} html
 */
export const sampleDataTemplateCompiler = async (params) => {
  const templatesFillData = []
  for (const email of Array.isArray(params) ? params : [params]) {
    const { template, newEmail } = await getTemplateString(email)
    templatesFillData.push(...determineSampleData(newEmail, template))
  }

  try {
    return await Promise.all(templatesFillData.map(async (email) => {
      const templateFillData = await getSampleTemplateData(email.type, email.dataRequestInformation)

      Handlebars.registerPartial({
        browserLink: browserLinkPartial(templateFillData),
        documentLabel: documentLabelPartial(templateFillData),
        extraMailBody: extraMailBodyPartial(templateFillData.extraMailBody)
      })

      const templateFunction = Handlebars.compile(email.template)
      return {
        content: templateFunction(templateFillData),
        contentLabel: email?.label
      }
    }))
  } catch (e) {
    throw new Error(e)
  }
}

/**
 * Get regex for html element
 *
 * @param {string} htmlElement - Html tag
 *
 * @returns {RegExp} regex
 *
 * @example 'style' => /(?<=<style>)([\\s\\S]*)(?=</style>)/g
 */
export const elementRegex = (htmlElement) => {
  if (htmlElement === 'font') return /<link\sclass="font-import"(.)*rel="stylesheet">/g
  return new RegExp(`(?<=<${htmlElement}>)([\\s\\S]*)(?=</${htmlElement}>)`, 'g')
}

/**
 * Get default template for creating a new email
 *
 * @param {string} data.type
 * @param {string} data.fontImport - Imported fonts
 * @param {string} data.updatedStyle - Styles
 * @param {string} data.updatedHtml - Html
 *
 * @returns {string} template
 */
export const getDefaultTemplate = ({ type, fontImport, updatedStyle, updatedHtml }) => {
  // Using string templating instead of Handlebars
  // because compiling at this stage results in an
  // encoded html string and is buggy and unpredictable

  if (type === 'style') {
    return `
    * .${type} {
      margin: 0;
      border: 0;
      padding: 0;
      box-sizing: border-box;
    }`
  } else {
    return `
    <!DOCTYPE html>
    <html lang='en'>
    <head>
      ${fontImport}
      <meta charset='UTF-8'>
      <meta name='viewport' content='width=device-width, initial-scale=1.0'>
      <title>Email</title>
      ${updatedStyle ? '  <style>\n' + updatedStyle + '\n  </style>' : ''}
    </head>
    <body>
      ${updatedHtml}
    </body>
    </html>`
  }
}
