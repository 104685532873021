import robintur from './RobinturLogo.png'
import siap from './SiapLogo.png'
import blueteam from './BlueteamLogo.png'
import gattinoni from './GattinoniLogo.png'

export default {
  robintur,
  siap,
  blueteam,
  gattinoni,
  default: siap
}
