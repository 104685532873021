import React from 'react'
import { Button } from '../../../Components'

/**
 * Button to view an email template
 *
 * @param {object} props.tooltip - Tooltip text
 * @param {function} props.setViewTemplate - Set the template to view
 * @param {function} props.showModalView - Show the view modal
 *
 * @returns {JSX.Element}
 */
const TemplateButton = (props) => (
  <Button
    tooltip={props.tooltip} onClick={() => { props.setViewTemplate(); props.showModalView() }}
    styleText={{ margin: 0 }}
    style={{ margin: 10, width: 'max-content', padding: '5px' }}
  />
)

export default TemplateButton
