import { FC, openUrlFromBuffer, showGrowl } from '../../Services'

export const downloadDocuments = async (requestsIds = [], name = '', isMultiSign = false) => {
  if (!requestsIds.length) {
    showGrowl('error', 'Errore download', 'Selezionare almeno un documento da scaricare')
    return false
  }
  try {
    showGrowl('info', 'Download', 'Download documenti in corso...')
    const res = await FC.service('services').get('getDocsBySignature', {
      query: { requestsIds, isMultiSign }
    })
    if (typeof res === 'object') {
      openUrlFromBuffer(res, 'application/octet-stream', name)
      return true
    }
    window.open(res)
  } catch (e) {
    showGrowl('error', 'Errore download', 'Impossibile scaricare il documento')
  }
}

export const resendDocuments = async (requestsIds = []) => {
  if (!requestsIds.length) {
    showGrowl('error', 'Errore download', 'Selezionare almeno una richiesta')
    return false
  }
  try {
    const res = await FC.service('services').get('reSendSignature', { query: { requestsIds } })
    return res
  } catch {
    return false
  }
}
