import React from 'react'
import { Card, Flex, Text, Spinner, Button } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useCQuery } from '../../Services'
import moment from 'moment'
import { exportTable } from './Utils'

export const StatsAgenzie = ({ range }) => {
  const { isSuccess, data = {} } = useCQuery(['statAgenzie', range])
  const { data: environment = {} } = useCQuery('environment')
  const title = `Statistiche Agenzia dal ${moment(range.from).format('DD/MM/YYYY')} al ${moment(range.to).format('DD/MM/YYYY')} - ` +
    `Firme Totali: ${data.agencies ? data.agencies.reduce((tot, curr) => tot + curr.totalSigns, 0) : 0} - ` +
    `Firme Remote: ${data.agencies ? data.agencies.reduce((tot, curr) => tot + (curr.destination.REMOTE || 0), 0) : 0} - ` +
    `Firme su Tablet: ${data.agencies ? data.agencies.reduce((tot, curr) => tot + (curr.destination.APP || 0), 0) : 0}`

  const tableHeader = (
    <Flex row fw jb>
      <Text bold value={title} />
      <Button icon='file-excel' label='Esporta Tabella' onClick={() => exportTable('agencies', data.agencies)} />
    </Flex>
  )

  return (
    <Card style={{ marginTop: 20 }}>
      <DataTable
        value={data.agencies} paginator rows={20} emptyMessage='Nessuna richiesta di firma trovata per il range di date selezionato.'
        header={isSuccess ? tableHeader : <Spinner />}
      >
        <Column field='name' header='Nome' sortable style={{ width: 300 }} />
        <Column field='states.signed' header='Firmate' sortable style={{ textAlign: 'right' }} />
        <Column field='states.requested' header='In Richiesta' sortable style={{ textAlign: 'right' }} />
        <Column field='states.cancelled' header='Annullate' sortable style={{ textAlign: 'right' }} />
        <Column field='states.expired' header='Scadute' sortable style={{ textAlign: 'right' }} />
        {!environment.hasFtoSign && (
          <>
            <Column field='destination.APP' header='Totali su Tablet' sortable style={{ textAlign: 'right' }} />
            <Column field='destination.REMOTE' header='Totali Remote' sortable style={{ textAlign: 'right' }} />
          </>
        )}
        <Column field='totalSigns' header='Firme Totali' sortable style={{ textAlign: 'right' }} />
      </DataTable>
    </Card>
  )
}
