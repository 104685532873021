import React, { useState } from 'react'
import { Spinner } from '../../Components'
import moment from 'moment'
import { useCQuery } from '../../Services'
import { StatsAgenzie } from './StatsAgenzie'
import { DateRangePicker } from './DateRangePicker'
import { StatsGiornaliere } from './StatsGiornaliere'
import { StatsDettagliate } from './StatsDettagiate'
import { PageContainer } from '../../Components/Layout/PageContainer'

const defaultFrom = moment().startOf('month').startOf('day').format('YYYY-MM-DD')
const defaultTo = moment().endOf('day').format('YYYY-MM-DD')

const Stats = ({ range }) => (
  <>

    <StatsGiornaliere range={range} />
    <StatsAgenzie range={range} />
    <StatsDettagliate range={range} />
  </>
)

const EnvAdminHome = (props) => {
  const [range, setRange] = useState({ from: defaultFrom, to: defaultTo })
  const { isSuccess } = useCQuery(['statAgenzie', range])

  return (
    <PageContainer style={{ marginTop: -60 }}>
      <DateRangePicker state={[range, setRange]} />
      {isSuccess ? <Stats range={range} /> : <Spinner />}
    </PageContainer>
  )
}

export default EnvAdminHome
