import React from 'react'
import { AgencyLogo } from '../../Components'
import Clients from './Clients'
import { PageContainer } from '../../Components/Layout/PageContainer'

const ClientsPage = () => {
  return (
    <PageContainer>
      <AgencyLogo />
      <Clients />
    </PageContainer>
  )
}

export default ClientsPage
