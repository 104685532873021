import { sampleDataTemplateCompiler, getDefaultTemplate } from '../utils'
import { FC, invalidateQuery } from '../../../../Services'

export const createPreview = async (getUpdatedInput, fontImport, environment, type, setPreview) => {
  const { html: updatedHtml, style: updatedStyle } = getUpdatedInput()
  const [{ content }] = await sampleDataTemplateCompiler([{
    environment,
    type,
    templateInput: getDefaultTemplate({ type: 'body', fontImport, updatedStyle, updatedHtml })
  }])
  setPreview(content)
}

export const saveTemplate = async (
  getUpdatedInput, fontImport, environment, type, id, updateMenu, onClose
) => {
  const { html: updatedHtml, style: updatedStyle } = getUpdatedInput()
  const template = (!updatedHtml && updatedStyle !== getDefaultTemplate({ type: 'style' }))
    ? ''
    : getDefaultTemplate({ type: 'body', fontImport, updatedStyle, updatedHtml })

  await FC.service('emailTemplates').patch(id, {
    [type]: template,
    updatedAt: new Date(),
    environment
  })
  invalidateQuery('emailTemplates')
  updateMenu()
  onClose()
}

export const editButtonStyle = {
  maxWidth: 'fit-content',
  margin: '10px',
  boxShadow: 'unset',
  backgroundColor: 'rgb(102, 102, 122)',
  focusRing: 'unset',
  height: 'fit-content'
}

export const fontButtonStyle = (editButtonLabel) => ({
  display: editButtonLabel === 'Edit HTML' ? 'flex' : 'none',
  margin: '10px',
  boxShadow: 'unset',
  backgroundColor: 'rgb(102, 102, 122)',
  focusRing: 'unset',
  height: 'fit-content'
})

export const fontEditorStyle = {
  backgroundColor: '#323238',
  color: 'white',
  borderRadius: '0',
  height: '200px',
  fontFamily: 'monospace',
  fontSize: '14px'
}

export const editorStyle = {
  backgroundColor: '#343440',
  color: 'white',
  borderRadius: '0',
  minHeight: '100%',
  fontFamily: 'monospace',
  fontSize: '14px',
  overflow: 'auto'
}
