import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { useMount } from 'react-use'

const DEFAULT_FADE = 0.5

const FadeIn = (props) => {
  const [isVisible, setIsVisible] = useState(false)

  useMount(() => {
    props.toggle && props.toggle(toggleFade)
    setIsVisible(true)
  })

  const toggleFade = () => {
    setIsVisible(!isVisible)
  }

  const { duration, children, refreshOn, style } = props
  const fadeDuration = duration || DEFAULT_FADE

  return isVisible
    ? (
      <motion.div
        variants={{
          enter: {
            opacity: 1,
            transition: {
              y: { type: 'spring', stiffness: 100, damping: 15 },
              default: { duration: fadeDuration }
            }
          },
          exit: {
            opacity: 0,
            transition: { duration: fadeDuration }
          }
        }}
        initial='exit'
        animate={isVisible ? 'enter' : 'exit'}
        key={refreshOn || 'fadeInContent'}
        style={style}
      >
        {children}
      </motion.div>)
    : null
}

export default FadeIn
