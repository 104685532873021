import React, { useState } from 'react'

import { Button, Flex, Input, Text } from '../../Components'
import { FC, showGrowl, invalidateQuery } from '../../Services'

export default function ClientData ({ clientData = {}, onCreate }) {
  const [mainData, setMainData] = useState({
    _id: clientData?._id || '',
    identifier: clientData?.identifier || '',
    address: clientData?.address || '',
    city: clientData?.city || '',
    province: clientData?.province || '',
    phoneNumber: clientData?.phoneNumber || ''
  })
  const [contactPersonData, setContactPersonData] = useState({
    name: clientData?.contactPerson?.name || '',
    lastname: clientData?.contactPerson?.lastname || '',
    email: clientData?.contactPerson?.email || '',
    phoneNumber: clientData?.contactPerson?.phoneNumber || ''

  })

  const updateMainData = (update) => {
    setMainData({ ...mainData, ...update })
  }

  const updateContactPersonData = (update) => {
    setContactPersonData({ ...contactPersonData, ...update })
  }

  const inputPropsText = (id, placeholder, value, extraStyle = {}) => ({
    placeholder,
    id,
    value: value || '',
    style: { border: '1px solid black', ...extraStyle }
  })

  const onSave = async () => {
    if (!mainData?.identifier) return showGrowl('error', 'Dati obbligatori', 'Compilare almeno il campo Identificativo')

    const payload = { ...mainData, contactPerson: { ...contactPersonData } }
    const { _id, ...body } = payload
    let response = null

    try {
      // Sono nel caso di un edit
      if (_id) response = await FC.service('clients').patch(_id, { ...body })
      else response = await FC.service('clients').create(body)

      if (!response) return showGrowl('error', 'Attnezione', 'errore creazione')
      invalidateQuery('clients')
      showGrowl('success', `Cliente ${_id ? 'modificato' : 'creato'}`, 'Salvataggio effettuato con successo')
      onCreate && onCreate(response)
      return true
    } catch {
      return showGrowl('error', 'Attnezione', 'errore creazione')
    }
  }

  return (
    <Flex fw>
      <Text value='Dati società/cliente' bold />
      <Flex fw js as style={{ marginBottom: 10, marginTop: 10 }}>
        <Input {...inputPropsText('identifier', 'Identificativo', mainData?.identifier)} onChange={updateMainData} error={!mainData?.identifier} />
      </Flex>
      <Flex fw js as style={{ marginBottom: 10 }}>
        <Flex row style={{ marginBottom: 10 }}>
          <Input {...inputPropsText('address', 'Indirizzo', mainData?.address, { marginRight: 5 })} onChange={updateMainData} />
          <Input {...inputPropsText('city', 'Città', mainData?.city, { margin: '0px 5px' })} onChange={updateMainData} />
          <Input {...inputPropsText('province', 'Provincia', mainData?.province, { marginLeft: 5 })} onChange={updateMainData} />
        </Flex>
        <Input {...inputPropsText('phoneNumber', 'Telefono', mainData?.phoneNumber)} onChange={updateMainData} />
      </Flex>
      <Text value='Persona di riferimento' bold />
      <Flex fw style={{ marginTop: 10, marginBottom: 10 }}>
        <Input {...inputPropsText('name', 'Nome', contactPersonData?.name, { marginBottom: 10 })} onChange={updateContactPersonData} />
        <Input {...inputPropsText('lastname', 'Cognome', contactPersonData?.lastname, { marginBottom: 10 })} onChange={updateContactPersonData} />
        <Input {...inputPropsText('email', 'Email', contactPersonData?.email, { marginBottom: 10 })} onChange={updateContactPersonData} />
        <Input {...inputPropsText('phoneNumber', 'Telefono', contactPersonData?.phoneNumber, { marginBottom: 10 })} onChange={updateContactPersonData} />
      </Flex>
      <Button label='Salva' icon='check' onClick={onSave} />
    </Flex>

  )
}
