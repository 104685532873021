import React, { useEffect, useRef, useState } from 'react'
import { ObjectInput, ModalGeneric, Flex, Text, Button } from '../../../../Components'
import EmailPreview from '../EmailPreview'
import EditEmailCode from '../EditEmailCode'
import {
  objectInputPropsDropdown, deleteTemplateType, emailTypes, objectInputContainerStyle
} from './functions'

/**
   * Create a block of inputs and a preview for
   * each email type
   *
   * @param {string} type
   *
   * @returns {JSX.Element}
   */
const TypeSetterArea = ({
  targetEmailTypeByEnvironment: [targetEmailTypeByEnvironment, setTargetEmailTypesByEnvironment],
  previewsEnvironments: [previewsEnvironments, setPreviewsEnvironments],
  filteredEnvironments, type, id, targetEnvironment
}) => {
  const title = type === 'signatureRequest' ? 'Signature' : type
  const refDelete = useRef(null)
  const refModalEditCode = useRef(null)
  const [templateTypeToDelete, setTemplateTypeToDelete] = useState(null)
  const [isCalledAfterDelete, setIsCalledAfterDelete] = useState(false)
  const [editType, setEditType] = useState('')
  const [templateExists, setTemplateExists] = useState(emailTypes(false))
  const [toggleRender, setToggleRender] = useState(false)

  /**
   * Handle enviroment change per email type
   * and update the previews
   *
   * @param {string} input - The new environment
   * @param {string} type - The email type
   *
   * @example ('esitur', 'signatureRequest')
   */
  const handleTargetEnvironmentInputChangePerType = (input, type) => {
    setTargetEmailTypesByEnvironment({ ...targetEmailTypeByEnvironment, [type]: input })
    setPreviewsEnvironments({ ...previewsEnvironments, [type]: input })
  }

  useEffect(() => {}, [toggleRender])

  return (
    <>
      <ModalGeneric
        ref={refDelete} content='Are you sure you want to delete this email?'
        action={() => {
          deleteTemplateType({
            templateTypeToDelete,
            targetEmailTypeByEnvironment,
            setIsCalledAfterDelete,
            setTargetEmailTypesByEnvironment,
            templateExists,
            setTemplateExists
          })
          setToggleRender(!toggleRender)
        }}
      />
      <ModalGeneric
        ref={refModalEditCode} style={{ width: '98%', maxHeight: '100%', height: '98%' }}
        content={
          <EditEmailCode
            updateMenu={() => setToggleRender(!toggleRender)}
            previewsEnvironments={previewsEnvironments} id={id} type={editType}
            environment={targetEmailTypeByEnvironment[editType] || targetEnvironment}
            onClose={() => refModalEditCode?.current.hide()} setPreviewsEnvironments={setPreviewsEnvironments}
          />
        }
      />
      <Text
        center value={title} size='31px'
        style={{ display: 'block', textTransform: 'capitalize', margin: '20px 0' }}
      />
      <Flex width='100%'>
        <Flex row style={{ width: '100%' }}>
          <Flex style={objectInputContainerStyle(
            type, templateExists, targetEmailTypeByEnvironment, isCalledAfterDelete)}
          >
            <ObjectInput
              {...objectInputPropsDropdown(
                type, 'Environment', targetEmailTypeByEnvironment[type],
                handleTargetEnvironmentInputChangePerType
              )}
              dropdown options={filteredEnvironments}
            />
          </Flex>
          {templateExists[type] &&
            <>
              <Button
                label='Edit' icon='edit' style={{ marginLeft: 20, marginRight: 20 }}
                onClick={() => {
                  setEditType(type)
                  refModalEditCode.current.show()
                }}
              />
              <Button
                style={{ marginRight: targetEmailTypeByEnvironment[type] ? '250px' : '0' }}
                onClick={() => { setTemplateTypeToDelete({ id: id, type: type }); refDelete.current.show() }}
                label='Delete' icon='bin'
              />
            </>}
        </Flex>
        {targetEmailTypeByEnvironment[type] &&
          <EmailPreview
            environment={previewsEnvironments[type]} type={type} templateExists={templateExists}
            setTypeByEnvironment={(env) => handleTargetEnvironmentInputChangePerType(env, type)}
            setAllPreviewsEnvironments={(e) => setPreviewsEnvironments(e)} previews={previewsEnvironments}
            setTemplateExists={setTemplateExists} toggleRender={toggleRender}
          />}
      </Flex>
    </>
  )
}

export default TypeSetterArea
