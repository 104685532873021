import React from 'react'
import { useWindowSize } from 'react-use'
import { cap } from '../../State'
import { Card } from '../Common/Card'

export function AgencyLogo ({ logo }) {
  const { width: screenWidth } = useWindowSize()

  const width = screenWidth < cap.smallScreen.state ? screenWidth - 20 : ((screenWidth * 40) / 100)

  return (
    <Card style={{ position: 'absolute', top: 20, right: 40 }}>
      <img
        src={logo || cap.logo.state.url} alt='logo'
        style={{ height: 'auto', width: 'auto', maxHeight: 60, maxWidth: width }}
      />
    </Card>

  )
}
