/* import { FC } from '.' */
import { cap } from '../State'
import { invalidateQuery } from './QueryCache'
import { openUrlFromBuffer } from './Utils'

export const eventsHandler = async (data) => {
  cap.modals.setState({
    ...cap.modals.state,
    modalDecision: false
  })
  window.growl.show({
    severity: 'info',
    summary: 'test socket',
    detail: 'mannaia'
  })
}

export const eventsSignDocDone = (data) => {
  const { status, documentsUriS3, link, requestDest, pendingSign, multiSignId } = data
  cap.modals.setState({
    ...cap.modals.state,
    modalSignature: false
  })
  if (multiSignId) invalidateQuery(['multiSignatures', 'multiSign'])
  if ((requestDest === 'REMOTE' || pendingSign) && status === 'signed') return invalidateQuery('signaturesDoc')

  if (status === 'signed' && documentsUriS3.length) {
    // se status completed and uri S3 ha docs
    window.growl.show({
      severity: 'info',
      summary: 'Firma avvenuta con successo',
      detail: 'Documento firmato correttamente'
    })
    /*  cap.documentDownloadList.setState(documentsUriS3) */
    if (typeof link === 'object') {
      // se è uno ZIP
      openUrlFromBuffer(link, 'application/octet-stream')
      return true
    }
    window.location.pathname = '/'
    window.open(link)
    invalidateQuery('signaturesDoc')

    return true
  }
  if (status === 'error') {
    window.growl.show({
      severity: 'error',
      summary: 'Errore firma',
      detail: 'Errore'
    })
    return false
  }
  if (status === 'requested') {
    invalidateQuery('signaturesDoc')
    return true
  }
}

export const handleNewMultiSign = (data) => {
  const { multiSignId } = data
  if (multiSignId) return invalidateQuery(['multiSignatures', 'multiSign'])
  return true
}
