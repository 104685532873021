import React, { useState, useRef } from 'react'
import { Button, Flex, ModalGeneric, Spinner, Text } from '../../../../../../Components'
import { handleSendRequest, saveAll } from '../Functions'
import { SelectClient } from './SelectClient'
import { SelectMailType } from './SelectMailType'
import StepWizard from 'react-step-wizard'
import { useNavigate } from 'react-router-dom'
import Confirm from './Confirm'
import { invalidateQuery } from '../../../../../../Services'

export const ModalSendRequest = ({ document: { documentId, documentName, documentLink }, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions, refModal }) => {
  const [selectedClient, setSelectedClient] = useState()
  const [selectedSignType, setSelectedSignType] = useState('REMOTE')
  const [selectedMailType, setSelectedMailType] = useState('mail')
  const [selectedTablet, setSelectedTablet] = useState()
  const [otp, setOtp] = useState(true)
  const navigate = useNavigate()
  const [showSpinner, setShowSpinner] = useState(false)
  const [remoteMode, setRemoteMode] = useState('MAIL')

  const refModalSign = useRef(null)

  const sendRequest = async (selection) => {
    await saveAll({ documentId, documentName, documentLink }, elementAddedSignDate, listCustomQuestions, listCustomRadioQuestions, true)
    const usrInfo = { phone: selectedClient?.contactPerson?.phoneNumber, email: selectedClient?.contactPerson?.email, name: selectedClient?.contactPerson?.name, lastname: selectedClient?.contactPerson?.lastname, societyIdentifier: selectedClient?.identifier }
    await handleSendRequest(documentId, usrInfo, selectedSignType, remoteMode, selectedMailType, selectedTablet, otp, selection)
    if (selectedSignType === 'REMOTE' || selection === 'wait') {
      invalidateQuery('signaturesDoc')
      navigate('/?tab=1')
      window.growl.show({
        severity: 'success',
        summary: selection === 'wait' ? 'Attesa' : 'Richiesta Inviata',
        detail: selection === 'wait' ? 'Richiesta di firma messa correttamente in attesa' : 'Documento da firmare inviato correttamente'
      })
    } else {
      setShowSpinner(true)
    }
  }

  const NavigationButtons = ({ goToStep, currentStep }) => {
    const renderNextButton = () => {
      const canGoNextPage = () => {
        if (currentStep === 1 && selectedClient?.identifier && (selectedSignType === 'REMOTE' || selectedTablet?.deviceCode)) return true
        if (currentStep === 2 && selectedClient?.contactPerson?.email) return true
        return false
      }
      return currentStep === 2
        ? <Button label='Conferma' rich onClick={() => refModalSign.current.show()} icon='check' disabled={!canGoNextPage()} />
        : <Button label='Avanti' icon='right' onClick={() => goToStep(currentStep + 1)} disabled={!canGoNextPage()} />
    }
    return (
      <Flex row style={{ height: 50, marginTop: 50 }}>
        <Button label='Indietro' icon='left' style={{ marginRight: 10 }} onClick={() => goToStep(currentStep - 1)} disabled={currentStep === 1} />
        {renderNextButton()}
      </Flex>
    )
  }

  return showSpinner
    ? (
      <Flex style={{ height: '50%' }}>
        <Text bold value='Firma in corso...' />
        <Spinner />
      </Flex>)
    : (
      <Flex fw style={{ height: '85%' }}>
        <ModalGeneric
          header='Conferma richiesta di firma'
          ref={refModalSign}
          noDismissable
          content={(<Confirm action={sendRequest} />)}
        />
        <StepWizard className='stepWizard' nav={<NavigationButtons />}>
          <SelectClient remoteMode={[remoteMode, setRemoteMode]} client={[selectedClient, setSelectedClient]} type={[selectedSignType, setSelectedSignType]} tablet={[selectedTablet, setSelectedTablet]} />
          <SelectMailType otp={[otp, setOtp]} mail={[selectedMailType, setSelectedMailType]} client={[selectedClient?.contactPerson || {}, (data) => setSelectedClient({ ...selectedClient, contactPerson: { ...selectedClient.contactPerson, ...data } })]} />
        </StepWizard>
      </Flex>)
}
