import React from 'react'
import { AgencyLogo } from '../../Components'
import Signatures from './Signatures'
import { PageContainer } from '../../Components/Layout/PageContainer'

const MainPage = () => {
  return (
    <PageContainer>
      <AgencyLogo />
      <Signatures />
    </PageContainer>
  )
}

export default MainPage
