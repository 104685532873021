import React from 'react'
import { useWindowSize } from 'react-use'
import { Button } from '../../../Components'
import { downloadDocuments } from '../Functions'

export function DownloadBtn ({ rowData: { _id } }) {
  const { width: screenWidth } = useWindowSize()

  const onClickDownload = async (e) => {
    e.stopPropagation()
    downloadDocuments([_id])
  }

  return (
    <Button
      rich
      round
      tooltip='Scarica'
      icon='download'
      onClick={onClickDownload}
      style={{ cursor: 'pointer', marginLeft: screenWidth > 1200 ? 10 : 0 }}
    />
  )
}
