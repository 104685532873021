import { Dropdown } from 'primereact/dropdown'
import React, { useRef } from 'react'
import { Button, Flex, ModalGeneric, Text } from '../../../../../../Components'
import { useCQuery } from '../../../../../../Services'
import ClientData from '../../../../../Clients/ClientData'
import { Select } from '../../../../../../Components/Common/Select'

const remoteOtpions = [{ value: 'MAIL', label: 'Email' }, { value: 'SMS', label: 'SMS' }]
const signTypes = [{ value: 'REMOTE', label: 'Firma da remoto' }, { value: 'TABLET', label: 'Firma da tablet' }]

export const SelectClient = ({ remoteMode: [remoteMode, setRemoteMode], tablet: [selectedTablet, setSelectedTablet], client: [selectedClient, setSelectedClient], type: [selectedSignType, setSelectedSignType] }) => {
  const { data: clients = [] } = useCQuery('clients')
  const { data: devices = [] } = useCQuery('devices')

  const modalAddClient = useRef()

  const clientTemplate = (option) => option ? `${option?.identifier} - ${option?.phoneNumber} - ${option?.contactPerson?.name} - ${option?.contactPerson?.lastname}` : 'Seleziona un cliente'

  const tabletTemplate = (option) => option ? `${option?.deviceCode} - ${option?.descriptionDevice}` : 'Seleziona un tablet'

  return (
    <Flex style={{ padding: 40 }}>
      <Text value='Seleziona un cliente o creane uno nuovo' bold size={16} title />
      <Flex fw js fh>
        <Dropdown
          style={{ width: 500, marginTop: 20 }}
          value={selectedClient}
          options={clients}
          onChange={(e) => setSelectedClient(e.value)}
          optionLabel='identifier'
          filter showClear filterBy='identifier'
          placeholder='Seleziona un cliente'
          valueTemplate={clientTemplate} itemTemplate={clientTemplate}
        />
        <Button icon='add' label='Crea nuovo cliente' style={{ marginTop: 20 }} onClick={() => modalAddClient?.current?.show()} />
        <ModalGeneric ref={modalAddClient} content={(<ClientData onCreate={(res) => { modalAddClient.current.hide(); setSelectedClient(res) }} />)} />
        <Text value='Scegli la modalità di Firma' size={16} style={{ marginTop: 30 }} bold title />
        <Select
          value={selectedSignType} options={signTypes}
          style={{ fontSize: 12, marginTop: 10 }}
          onChange={({ value }) => setSelectedSignType(value)}
        />
        {selectedSignType === 'TABLET' && (
          <>
            <Text value='Seleziona il tablet a cui mandare la richiesta di firma' style={{ marginTop: 20 }} />
            <Dropdown
              style={{ width: 500, marginTop: 10 }} value={selectedTablet} options={devices} onChange={({ value }) => setSelectedTablet(value)}
              filter showClear filterBy='deviceCode' placeholder='Seleziona un tablet' optionLabel='deviceCode'
              valueTemplate={tabletTemplate} itemTemplate={tabletTemplate}
            />
          </>
        )}
        {selectedSignType === 'REMOTE' && (
          <>
            <Text value='Seleziona la modalità di firma da Remoto.' style={{ marginTop: 20 }} />
            <Text value='Puoi scegliere se mandare il link di firma tramite SMS o tramite email.' />
            <Select value={remoteMode} options={remoteOtpions} style={{ fontSize: 12, marginTop: 10 }} onChange={({ value }) => setRemoteMode(value)} />
          </>
        )}
      </Flex>
    </Flex>
  )
}
