import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useRef, useState } from 'react'
import { Button, Flex, Spinner, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services'
import TemplateButton from '../TemplateButton'
import CreateMenuModals from './Modals'
import { toggleRestoreMenuButtonStyle, getTableElements, emailTemplateActionModal } from './functions'

const types = [
  { value: 'signatureRequest', label: 'Signature Request' },
  { value: 'reminder', label: 'Reminder' },
  { value: 'error', label: 'Error' },
  { value: 'expired', label: 'Expired' }]

/**
 * List all of the email templates
 *
 * @returns {JSX.Element}
 */
const EmailList = () => {
  const { data: emailTemplates = [], isSuccess } = useCQuery('emailTemplates')
  const refModalCreate = useRef(null)
  const refModalView = useRef(null)
  const refModalEdit = useRef(null)
  const refDelete = useRef(null)
  const refModalRestore = useRef(null)
  const [showDeletedEmails, setShowDeletedEmails] = useState(false)
  const [edit, setEdit] = useState(null)
  const [restore, setRestore] = useState(null)
  const [emailTemplateToDelete, setEmailTemplatesToDelete] = useState(null)
  const [viewTemplate, setViewTemplate] = useState(null)

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js>
      <CreateMenuModals
        refDelete={refDelete} refModalCreate={refModalCreate} refModalEdit={refModalEdit}
        refModalView={refModalView} edit={edit} viewTemplate={viewTemplate} restore={restore}
        refModalRestore={refModalRestore} emailTemplateToDelete={emailTemplateToDelete}
      />
      <Flex fw>
        <Flex fw jb row>
          <Text size={22} bold value='Email List' style={{ marginBottom: 15 }} />
          <Flex row>
            <Button
              tooltip={`Show ${showDeletedEmails ? '' : 'Deleted '}Emails`}
              onClick={() => setShowDeletedEmails(!showDeletedEmails)} style={toggleRestoreMenuButtonStyle(20)}
            />
            <Button
              tooltip='Create New Email' onClick={() => emailTemplateActionModal(null, refModalCreate, null)}
              style={toggleRestoreMenuButtonStyle()}
            />
          </Flex>
        </Flex>
        <DataTable
          style={{ width: '100%' }} value={getTableElements(emailTemplates, showDeletedEmails)} paginator rows={20}
          emptyMessage='No emails found.' className='TableSignatures'
        >
          <Column field='_id' header='Id' sortable />
          <Column field='environment' header='Society' style={{ textTransform: 'capitalize' }} sortable />
          <Column
            header='View email types'
            body={(emailTemplate) => (
              <Flex row js>
                {types.filter(({ value: type }) => emailTemplate[type]).map(({ value: type, label }, key) => (
                  <TemplateButton
                    key={key} tooltip={label}
                    setViewTemplate={() => setViewTemplate({ environment: emailTemplate.environment, type })}
                    showModalView={() => refModalView.current.show()}
                  />
                ))}
              </Flex>
            )}
          />
          <Column body={(emailTemplate) =>
            !showDeletedEmails
              ? (
                <Flex row style={{ minWidth: '10vw' }}>
                  <Button
                    round tooltip='Edit' icon='edit' style={{ marginRight: 10 }}
                    onClick={() => emailTemplateActionModal(setEdit, refModalEdit, emailTemplate)}
                  />
                  {(emailTemplate.environment !== 'Common') &&
                    <Button
                      round tooltip='Delete' icon='bin'
                      onClick={() => { setEmailTemplatesToDelete(emailTemplate); refDelete.current.show() }}
                    />}
                </Flex>)
              : (
                <Flex row style={{ minWidth: '10vw' }}>
                  <Button
                    round tooltip='Restore' icon='plus'
                    onClick={() => emailTemplateActionModal(setRestore, refModalRestore, emailTemplate)}
                  />
                </Flex>)}
          />
        </DataTable>
      </Flex>
    </Flex>
  )
}

export default EmailList
