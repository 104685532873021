import React, { useState } from 'react'
import { showGrowl } from '../../../../../Services'
import { Button, Card, Flex, Icon, Input, Text } from '../../../../../Components'

const NewContact = ({ onSend, onSave, goBack }) => {
  const [isContactSelected, setIsContactSelected] = useState(false)
  const [newContactData, setNewContactData] = useState({ name: '', lastname: '', email: '', phoneNumber: '' })
  const updateNewContactData = (update) => setNewContactData({ ...newContactData, ...update })

  const addNewContact = () => {
    const requiredFields = ['phoneNumber', 'email', 'name', 'lastname']
    // Controlla se tutti i campi richiesti sono diversi da stringa vuota
    if (requiredFields.every(field => newContactData[field] !== '')) {
      /* showGrowl('success', 'Nuovo Contatto', 'Contatto aggiunto con successo') */
      setIsContactSelected(true)
      return onSave(newContactData)
    } else {
      showGrowl('error', 'Errore', 'Tutti i campi sono obbligatori')
    }
  }

  const inputPropsText = (id, placeholder, value) => ({
    placeholder,
    id,
    value: value || '',
    style: { width: '100%' },
    disabled: isContactSelected
  })

  return (
    <Flex js style={{ padding: '2em', borderRadius: 20, backgroundColor: '#e6f7ff', gap: '1em', width: '50vw' }}>
      <Button tooltip='Indietro' round icon='back' style={{ alignSelf: 'flex-start' }} onClick={() => goBack()} />
      <Text style={{ textAlign: 'center' }} size={24} bold upCase value='Crea nuovo contatto' />
      <Flex row nowrap fw style={{ gap: '1em', width: '90%' }}>
        <Input {...inputPropsText('name', 'Nome', newContactData?.name)} onChange={updateNewContactData} />
        <Input {...inputPropsText('lastname', 'Cognome', newContactData?.lastname)} onChange={updateNewContactData} />
      </Flex>
      <Flex row nowrap fw style={{ gap: '1em', width: '90%' }}>
        <Input {...inputPropsText('email', 'Email', newContactData?.email)} onChange={updateNewContactData} />
      </Flex>
      <Flex row nowrap fw style={{ gap: '1em', width: '90%' }}>
        <Input {...inputPropsText('phoneNumber', 'Telefono', newContactData?.phoneNumber)} onChange={updateNewContactData} />
      </Flex>
      <Card style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{
          width: '320px',
          padding: '.4em',
          marginBottom: '1em',
          transform: 'scale(1.01)',
          border: '2px solid #32324e',
          borderRadius: '12px',
          cursor: 'default',
          backgroundColor: '#fff',
          boxShadow: '7px 7px 2px -3px rgba(0,0,0,0.55)',
          WebkitBoxShadow: '7px 7px 2px -3px rgba(0,0,0,0.75)'
        }}
        >
          <Flex style={{ gap: '.5em', padding: '.4em' }}>
            <Flex row style={{ gap: '0.5em' }}>
              <Icon name='account' size={18} style={{ fontWeight: 600 }} />
              <Text bold size={18} value={newContactData.name.charAt(0).toUpperCase() + newContactData.name.slice(1) || 'Nome'} />
              <Text size={18} bold value={newContactData.lastname.charAt(0).toUpperCase() + newContactData.lastname.slice(1) || 'Cognome'} />
            </Flex>
            <span style={{ width: '60%', height: '1px', backgroundColor: '#32324e', margin: '0 auto' }} />
            <Flex style={{ borderLeft: '2px solid #32324e', paddingLeft: '.5em', gap: '.5em' }} />
            <Flex as js style={{ gap: '.2em' }}>
              <Flex js row style={{ gap: '.2em' }}>
                <Icon size={18} style={{ fontWeight: 600 }} name='mail' />
                <Text size={16} value={newContactData.email || 'Email'} />
              </Flex>
              <Flex js row style={{ gap: '.2em' }}>
                <Icon size={18} style={{ fontWeight: 600 }} name='telephone' />
                <Text size={16} value={newContactData.phoneNumber || 'Telefono'} />
              </Flex>
            </Flex>
          </Flex>
        </div>
        <Flex row nowrap style={{ gap: '1em', marginTop: '1em' }}>
          {!isContactSelected
            ? (
              <>
                <Button label='Salva' icon='check' onClick={() => addNewContact(newContactData)} />
                <Button label='Pulisci' icon='bin' onClick={() => setNewContactData({ name: '', lastname: '', email: '', phoneNumber: '' })} />
              </>)
            : (
              <>
                <Button icon='send' label='Invia Via Email' onClick={() => { onSend(newContactData, 'MAIL'); setNewContactData({ name: '', lastname: '', email: '', phoneNumber: '' }) }} />
                <Button icon='send' label='Invia Via SMS' onClick={() => { onSend(newContactData, 'SMS'); setNewContactData({ name: '', lastname: '', email: '', phoneNumber: '' }) }} />
              </>
              )}
        </Flex>
      </Card>
    </Flex>
  )
}

export default NewContact
