import React, { useState } from 'react'
import { Flex, TabHeader, Tabs } from '../../Components'
import EnvList from './Env/EnvList'
import AgencyList from './Agency/AgencyList'
import UsersList from './User/UsersList'
import { PageContainer } from '../../Components/Layout/PageContainer'
import EmailList from './Email/EmailList'
import Sms from './Sms/Sms'

const tabs = [
  { id: 0, icon: 'doc-2', title: 'Gestione Environment', content: <EnvList /> },
  { id: 1, icon: 'edit-2', title: 'Gestione Agenzie', content: <AgencyList /> },
  { id: 2, icon: 'expired', title: 'Gestione Utenti', content: <UsersList /> },
  { id: 3, icon: 'edit-2', title: 'Gestione Email', content: <EmailList /> },
  { id: 4, icon: 'user', title: 'Gestione SMS', content: <Sms /> }
]

const Admin = () => {
  const [activeTab, setActiveTab] = useState(0)
  return (
    <PageContainer>
      <Flex>
        <Tabs
          style={{ width: '100%', height: '100%', padding: 20, paddingTop: 0 }}
          onTabSelection={(e) => setActiveTab(e)}
          currentTab={activeTab}
        >
          {tabs?.map((tab, index) => (
            <TabHeader
              title={tab?.title}
              key={index}
              index={index}
              icon={tab?.icon}
            >
              {tab?.content}
            </TabHeader>
          ))}
        </Tabs>
      </Flex>
    </PageContainer>
  )
}

export default Admin
