import React, { useRef } from 'react'
import { Button, ModalGeneric } from '../../../Components'
import { useWindowSize } from 'react-use'
import ModalContent from './ModalContent'
import { invalidateQuery, showGrowl } from '../../../Services'
import { resendDocuments } from '../Functions'

export const ResendBtn = ({ rowData: { _id: requestId, requestDest, status, remoteSign, requestSource }, headerText, contentText, tooltip }) => {
  const { width: screenWidth } = useWindowSize()
  const resendRef = useRef(null)

  const openResendModal = () => resendRef?.current?.show ? resendRef.current.show() : resendRef.show()
  const hideResendModal = () => resendRef?.current?.hide ? resendRef.current.hide() : resendRef.hide()

  const resendRequest = async () => {
    // Pensare eventualmente a sviluppo per permettere di rinviare le firme con reuqetsDest APP, status "signed" e requestSource diverso da ATL
    if ((requestDest === 'APP' && status === 'requested') || (requestDest === 'APP' && status === 'signed') || (requestDest === 'APP' && requestSource === 'ATL')) {
      hideResendModal()
      return showGrowl('error', 'Errore re-invio richiesta', 'Impossibile re-inviare una richiesta destinata ad un tablet e proveniente da Atlante.')
    }

    const res = await resendDocuments([requestId])
    hideResendModal()

    if (!res) return showGrowl('error', 'Errore re-invio richiesta', 'Impossibile re-inviare la richiesta. Riprova')

    showGrowl('success', 'Operazione andata a buon fine', '')
    invalidateQuery('signaturesDoc')
  }

  return (
    <>
      <ModalGeneric
        noDismissable
        closable='no'
        ref={resendRef}
        header={headerText} // 'Re-invio richiesta di firma'
        content={
          <ModalContent
            onHide={hideResendModal}
            onConfirm={resendRequest}
            contentText={contentText}

          />
        }
      />
      <Button
        rich
        round
        tooltip={tooltip}
        icon='send'
        onClick={openResendModal}
        style={{ cursor: 'pointer', marginLeft: screenWidth > 1200 ? 10 : 0 }}
      />
    </>

  )
}
