import robintur from './backgroundRobintur.jpg'
import gattinoni from './gattinoni.jpg'
import siap from './siap.jpg'
import isolaazzurra from './isolaazzurra.png'
import viaggigiovani from './viaggigiovani.jpg'
export default {
  gattinoni,
  robintur,
  siap,
  isolaazzurra,
  blueteam: '',
  viaggigiovani
}
