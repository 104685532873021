import React, { useState, useEffect } from 'react'
import { Button, Flex, Input, ModalGeneric } from '../../../Components'
import { headerLabel } from './Functions'

const inputProps = (value, set, label) => ({
  id: [value],
  value,
  style: { width: '100%', marginRight: 20 },
  label,
  onChange: (e) => set(e[value])
})

export const ModalEditClient = ({ clientToEdit, editRef, saveClient, ...props }) => (
  <ModalGeneric
    {...props} ref={editRef} style={{ width: '90%', height: '90%' }}
    content={
      <EditPage
        saveClient={(i) => {
          saveClient(i)
          editRef.current.hide()
        }} clientToEdit={clientToEdit}
      />
    }
  />
)

const EditPage = ({ clientToEdit, saveClient }) => {
  const [client, setClient] = useState(clientToEdit)

  useEffect(() => setClient(clientToEdit), [clientToEdit])

  const checkAndSave = () => {
    const { userEmail, userLastname, userName, userPhone } = client
    userEmail?.match(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/) && userPhone?.split('.')?.join('').match(/^[0-9]+$/) && (userName || userLastname)
      ? saveClient(client)
      : window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Dati non validi!' })
  }

  return (
    <Flex fw fh js>
      <Flex row wrap fw as>
        {Object.keys(client).map((key, ind) => (
          key !== 'id' && key !== 'isClientExcluded' && <Input key={ind} {...inputProps(client[key], (text) => setClient({ ...client, [key]: text }), headerLabel(key))} style={{ width: 400, marginBottom: 10, marginRight: 10, marginLeft: 10 }} />
        ))}
      </Flex>
      <Button label='Salva Cliente' onClick={checkAndSave} />
    </Flex>
  )
}
