import React, { useRef } from 'react'
import { Icon } from './Icon'
import { Text } from './Text'
import { motion } from 'framer-motion'

const clickButtonVariants = (e) => ({
  idle: { x: 0, y: 0, scale: 1, boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.24)' },
  expanded: { scale: 1, x: 0, y: 0, width: e, transition: { duration: 0.5 } }
})

const clickButtonNoShadowVariants = (e) => ({
  idle: { x: 0, y: 0, scale: 1 },
  expanded: { scale: 1, x: 0, y: 0, width: e, transition: { duration: 0.5 } }
})

export const Button = ({ invertedIcon, round, iconSize, disabled, hidden, icon, label, onClick, style, iconStyle, width, tooltip, inverted, noShadow, styleText, textColor }) => {
  const tooltipRef = useRef(null)

  if (hidden) return null

  const buttonStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: inverted ? '#32324e' : 'white',
    borderRadius: 20,
    cursor: disabled ? 'not-allowed' : 'pointer',
    opacity: disabled ? 0.6 : 1,
    userSelect: 'none',
    padding: !round && label && 6,
    width: width || 'auto',
    overflow: 'hidden',
    ...(label ? { paddingLeft: 20, paddingRight: 20 } : {}),
    ...(round ? { width: 30, height: 30, borderRadius: 15 } : {}),
    ...style
  }

  const expansion = tooltipRef.current?.offsetWidth ? tooltipRef.current?.offsetWidth + 30 : 'auto'
  const variants = noShadow ? clickButtonNoShadowVariants : clickButtonVariants

  const doNothing = () => null

  const onClickFunction = (e) => {
    // Evita il doppio click
    if (e?.detail !== 1) doNothing()
    else onClick(e)
  }

  return (
    <motion.div
      whileHover={round && tooltip && 'expanded'}
      onClick={!disabled ? onClickFunction : undefined}
      initial='idle'
      style={buttonStyle}
      variants={variants(expansion)}
    >
      {icon && !invertedIcon ? <Icon name={icon} size={iconSize || 18} style={{ ...iconStyle, margin: 6 }} color={inverted ? 'white' : '#32324e'} /> : null}
      {label && !round ? (<Text color={textColor || (inverted ? 'white' : '#32324e')} bold center value={label} style={{ marginLeft: icon && 15, flexGrow: width ? 1 : 0, textAlign: 'center', ...styleText, textColor, width: '100%' }} />) : null}
      {<div ref={tooltipRef}><Text style={{ whiteSpace: 'nowrap', marginLeft: 10, marginRight: 10, display: 'block' }} bold value={tooltip} color={textColor || (inverted ? 'white' : '#32324e')} /></div>}
      {icon && invertedIcon ? <Icon name={icon} size={iconSize || 18} style={{ ...iconStyle, margin: 6 }} color={inverted ? 'white' : '#32324e'} /> : null}
    </motion.div>
  )
}
