import React, { useState } from 'react'
import { FC, invalidateQuery, showGrowl } from '../../../../../Services'
import { Button, Flex } from '../../../../../Components'
import LightBox from '../LightBox'
import NewContact from './NewContact'
import { SearchClients } from './SearchClients'

const ContactForm = ({ docId, docName, docType, onSend, navToFirstStep }) => {
  const [view, setView] = useState(null)

  const onSave = async (newContactData) => {
    const payload = { contactPerson: { ...newContactData } }
    const { _id, ...body } = payload
    const response = await FC.service('clients').create(body)
    if (!response) return showGrowl('error', 'Attenzione', 'Errore Creazione')
    invalidateQuery('clients')
    showGrowl('success', `Cliente ${_id ? 'modificato' : 'creato'}`, 'Salvataggio effettuato con successo')
  }

  const sendRequest = async (client, method) => {
    const { phoneNumber, email, name, lastname } = client
    const usrInfo = { phone: phoneNumber, email, name, lastname }
    const res = await FC.service('services').create({
      action: 'signRequest',
      deviceCode: 'REMOTE',
      error: false,
      messageError: '',
      requestSource: 'WEB',
      requestDest: 'REMOTE',
      requestDestType: method,
      userInformation: usrInfo,
      documents: [{ documentId: docId, type: docType || 'CSTM', userDocName: docName || '' }],
      success: false
    })
    if (!res.error) {
      await onSend(true)
      return true
    }
    showGrowl('error', 'Errore', res.message)
  }

  const handleBackBtn = () => setView(null)

  if (view === 'existing') return <SearchClients goBack={handleBackBtn} onSend={sendRequest} />
  if (view === 'new') return <NewContact goBack={handleBackBtn} onSend={sendRequest} onSave={onSave} />
  return (
    <Flex style={{ padding: '3em', borderRadius: 20, width: '520px', backgroundColor: '#e6f7ff', gap: '2em' }}>
      <Flex fw as js>
        <Button tooltip='Indetro' round icon='back' onClick={navToFirstStep} />
      </Flex>
      <LightBox label='SELEZIONA UN CONTATTO ESISTENTE' icon='lens' view='existing' setView={setView} />
      <LightBox label='CREA UN NUOVO CONTATTO' icon='add' view='new' setView={setView} />
    </Flex>
  )
}

export default ContactForm
