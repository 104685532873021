import React from 'react'
import { ModalGeneric } from '../../../../Components'
import EmailViewer from '../EmailViewer'
import CreateEmail from '../CreateEmail'
import EditEmail from '../EditEmail'
import RestoreEmail from '../RestoreEmail'
import { deleteEmailTemplates } from './functions'

const CreateMenuModals = ({
  refDelete, refModalCreate, refModalEdit, refModalRestore,
  refModalView, edit, viewTemplate, restore, emailTemplateToDelete
}) => {
  return (
    <>
      <ModalGeneric
        ref={refDelete} content='Are you sure you want to delete this email?'
        action={() => deleteEmailTemplates(emailTemplateToDelete)}
      />
      <ModalGeneric
        ref={refModalCreate} header='Create email'
        content={<CreateEmail onClose={() => refModalCreate?.current.hide()} />} style={{ width: '80%' }}
      />
      <ModalGeneric
        ref={refModalEdit} header='Edit email'
        content={<EditEmail edit={edit} onClose={() => refModalEdit?.current.hide()} />} style={{ width: '80%' }}
      />
      <ModalGeneric
        ref={refModalView} style={{ width: '90%' }}
        content={<EmailViewer data={viewTemplate} onClose={() => refModalView?.current.hide()} />}
      />
      <ModalGeneric
        ref={refModalRestore} header='Restore email' style={{ width: '35%' }}
        content={<RestoreEmail restore={restore} onClose={() => refModalRestore?.current.hide()} />}
      />
    </>
  )
}

export default CreateMenuModals
