import React, { useState } from 'react'
import { Card, Flex, Text, Spinner, Button } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Chart } from 'primereact/chart'
import { Checkbox } from 'primereact/checkbox'
import { useCQuery } from '../../Services'
import moment from 'moment'
import { exportTable } from './Utils'

export const StatsGiornaliere = ({ range }) => {
  const [noWeekends, setNoWeekends] = useState(false)
  const { isSuccess, data = {} } = useCQuery(['statAgenzie', range])
  const { data: environment = {} } = useCQuery('environment')

  const title = `Statistiche Giornaliere dal ${moment(range.from).format('DD/MM/YYYY')} al ${moment(range.to).format('DD/MM/YYYY')}`

  const tableHeader = (
    <Flex row fw jb>
      <Text bold value={title} />
      <Button icon='file-excel' label='Esporta Tabella' onClick={() => exportTable('days', data.days)} />
    </Flex>
  )
  const days = data.days || []
  const getData = (field, subField) => (noWeekends ? days.filter(({ day }) => moment(day).isoWeekday() < 6) : days)
    .map(d => subField ? d[field][subField] || 0 : d[field])

  return (
    <Card style={{ marginTop: 20 }}>
      <Flex>
        <Flex row>
          <Checkbox onChange={e => setNoWeekends(e.checked)} checked={noWeekends} />
          <Text value='Escludi i Weekend' style={{ marginLeft: 10 }} />
        </Flex>
        <Flex>
          <Chart
            type='bar' data={{
              labels: getData('day').map(d => moment(d).format('DD MMMM')),
              datasets: [
                { type: 'line', label: 'Firme da Tablet', borderColor: '#F5772F', fill: false, data: getData('destination', 'APP') },
                { type: 'line', label: 'Richieste da Remoto', borderColor: '#2196F3', fill: false, data: getData('destination', 'REMOTE') },
                { type: 'line', label: 'Documenti Firmati', borderColor: '#4CAF50', fill: false, data: getData('states', 'signed') }

              ]
            }}
            options={{
              title: { display: true, text: title },
              tooltips: { mode: 'index', intersect: true }
            }}
            width='800'
            height='400'
          />
        </Flex>ss
        <DataTable
          value={days} paginator rows={20} emptyMessage='Nessuna richiesta di firma trovata per il range di date selezionato.'
          style={{ marginTop: 20 }} header={isSuccess ? tableHeader : <Spinner />}
        >
          <Column field='day' header='Giorno' body={(v) => moment(v.day).format('DD/MM/YYYY')} sortable />
          <Column field='states.signed' header='Firmate' sortable style={{ textAlign: 'right' }} />
          <Column field='states.requested' header='In Richiesta' sortable style={{ textAlign: 'right' }} />
          <Column field='states.cancelled' header='Annullate' sortable style={{ textAlign: 'right' }} />
          <Column field='states.expired' header='Scadute' sortable style={{ textAlign: 'right' }} />
          {!environment?.hasFtoSign && (
            <>
              <Column field='destination.REMOTE' header='Totali Remote' sortable style={{ textAlign: 'right' }} />
              <Column field='destination.APP' header='Totali su Tablet' sortable style={{ textAlign: 'right' }} />
            </>
          )}
          <Column field='totalSigns' header='Frime Totali' sortable style={{ textAlign: 'right' }} />

        </DataTable>
      </Flex>
    </Card>
  )
}
