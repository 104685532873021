import _ from 'lodash'
import { Checkbox } from 'primereact/checkbox'
import React from 'react'
import { Button, Flex, Input } from '../../../../../Components'
import { Select } from '../../../../../Components/Common/Select'
import { Text } from '../../../../../Components/Common/Text'

const dropDownOptions = ['Crocetta caso negativo', 'Crocetta caso affermativo']

export const CustomQuestionElement = ({
  item: { answerQuestion, id, type, page, question, mandatory, isFlagPrivacy, idFlagPrivacy, key }, id: index,
  customQuestions: [listCustomQuestions, setListCustomQuestions]
}) => {
  const updateCustomCheck = (newData, id) => setListCustomQuestions(listCustomQuestions.map((el = {}) => el.id === id ? { ...el, ...newData } : el))

  return (
    <Flex key={id} style={{ width: '80%' }}>
      <Flex style={{ border: '1px solid black', position: 'relative', padding: 10, margin: 20, width: '80%' }}>
        <Text bold value={`Domanda personalizzata ${index + 1}`} />
        <Text value={`Testo domanda: ${question}`} />
        <Text value={`Parola di riferimento: "${key?.str}"`} />
        <Flex row>
          <Text value='Obbligatoria' style={{ marginRight: 10 }} />
          <Checkbox
            checked={mandatory}
            onChange={e => {
              const array = [...listCustomQuestions]
              const newArray = array.map((obj) => obj.id !== id
                ? obj
                : { ...obj, mandatory: !obj?.mandatory })
              setListCustomQuestions(newArray)
            }}
          />
        </Flex>
        <Flex row>
          <Text value='Flag privacy' style={{ marginRight: 10 }} />
          <Checkbox
            checked={isFlagPrivacy}
            onChange={e => {
              const array = [...listCustomQuestions]
              const newArray = array.map((obj) => obj.id !== id
                ? obj
                : { ...obj, isFlagPrivacy: !obj?.isFlagPrivacy })
              setListCustomQuestions(newArray)
            }}
          />
          {isFlagPrivacy && (
            <Input
              keyfilter='int'
              placeholder='idFlagPrivacy in Altante'
              id='idFlagPrivacy'
              style={{ border: '2px solid black', marginLeft: 10 }}
              value={idFlagPrivacy || ''}
              onChange={({ idFlagPrivacy }) => {
                const array = [...listCustomQuestions]
                const newArray = array.map((obj) => obj.id !== id
                  ? obj
                  : { ...obj, idFlagPrivacy })
                setListCustomQuestions(newArray)
              }}
            />)}
        </Flex>
        <Button
          style={{ cursor: 'pointer', marginTop: 10, marginBottom: 10 }}
          rich label='Aggiungi nuova crocetta'
          onClick={() =>
            updateCustomCheck({
              answerQuestion: [...answerQuestion, {
                coordinates: { x: 250, y: 400 }, width: 18, height: 18, id: type + Date.now(), page
              }]
            }, id)}
        />
        {answerQuestion.map((item, ind) =>
          <Flex key={ind} style={{ borderRadius: 4, padding: 10, border: '1px solid black', margin: '10px 0px', position: 'relative' }}>
            <Text value={`Pagina: ${item.page}`} />
            <Flex>
              <Select
                value={dropDownOptions[item.isYesAnswer ? 1 : 0]}
                options={dropDownOptions} style={{ fontSize: 12 }}
                onChange={() => {
                  const array = [...listCustomQuestions]
                  const newArray = array.map((obj) =>
                    obj.id !== id
                      ? obj
                      : { ...obj, answerQuestion: obj?.answerQuestion.map(answer => answer?.id !== item.id ? answer : { ...answer, isYesAnswer: !answer.isYesAnswer }) })
                  setListCustomQuestions(newArray)
                }}
              />
            </Flex>
            <Button
              style={{ position: 'absolute', top: -11, right: -11, width: 22 }}
              icon='close' iconSize={10}
              onClick={(e) => {
                const array = [...listCustomQuestions]
                const newArray = array.map((obj) =>
                  obj.id !== id ? obj : { ...obj, answerQuestion: obj?.answerQuestion.filter(answer => answer?.id !== item.id) })
                setListCustomQuestions(newArray)
              }}
            />
          </Flex>
        )}
        <Button
          style={{ cursor: 'pointer', position: 'absolute', top: -11, right: -11, width: 22 }}
          icon='close' iconSize={10}
          onClick={(e) => {
            const array = [...listCustomQuestions]
            _.remove(array, (el) => el.id === id)
            setListCustomQuestions(array)
          }}
        />
      </Flex>
    </Flex>
  )
}
