import React from 'react'
import { Draggable } from './Draggable'
import facSimileSign from '../Image/facSimileSign.png' // 200 x 45
import { Text } from '../../../../Components'
import moment from 'moment'

export const DraggableElement = ({ item: { idElement, type, title }, isYesAnswer, signIndex }) => {
  if (type === 'sign') {
    return (
      <>
        <img id={idElement} src={facSimileSign} style={{ width: 200, height: 45 }} />
        <span style={{ position: 'absolute', left: 200 }} className='p-badge'>{signIndex + 1}</span>
      </>
    )
  }
  if (type === 'date') {
    return <Text id={idElement} style={{ border: '1px solid red' }} value={moment().format('DD/MM/YYYY')} />
  }
  if (type === 'fixedLabel' || type === 'special') {
    return <Text id={idElement} style={{ border: '1px solid red' }} value={title || 'Testo non trovato'} />
  }
  return null
}

const DraggableSignDate = ({ listState: [elements, setElements], pageNumber, parentRef }) => {
  const updateElementAddedSignDate = (id, update) => setElements(elements.map((el = {}) => id === el.id ? { ...el, ...update } : el))
  const marginTop = document.getElementsByClassName('marginDocumentPDF')[0]?.offsetTop
  const marginLeft = document.getElementsByClassName('marginDocumentPDF')[0]?.offsetLeft

  return elements.length !== 0 &&
    elements.filter(item => item.page === pageNumber).map((item, ind) => (
      <Draggable
        key={item.id}
        dimensionContainer={{ width: item.width, height: item.height }}
        refParentDiv={parentRef}
        startX={item.coordinates.x}
        startY={item.coordinates.y}
        callbackUpdatePosition={(x, y) => updateElementAddedSignDate(item.id, { coordinates: { x: x - marginLeft * 2, y: y - marginTop * 2 } })}
      >
        <DraggableElement item={{ idElement: item.id, type: item.type, title: item.title }} isYesAnswer={false} signIndex={elements?.slice(0, ind)?.filter(({ type }) => type === 'sign')?.length} />
      </Draggable>
    ))
}

export default DraggableSignDate
