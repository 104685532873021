import { useQuery, queryCache } from 'react-query'
import { FC } from '.'
import moment from 'moment'
import { cap } from '../State'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 3 * time.minutes,
    cacheTime: 5 * time.minutes,
    refetchInterval: 2 * time.minutes
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const queries = {
  environments: {
    config: queryConfig.rare,
    queryFn: () => FC.service('environments').find({})
  },
  agencies: {
    config: queryConfig.rare,
    queryFn: () => FC.service('agencies').find({})
  },
  users: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find({ query: { deviceCode: { $exists: false } } })
  },
  devices: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find()
  },
  clients: {
    config: queryConfig.rare,
    queryFn: () => FC.service('clients').find()
  },
  sms: {
    config: queryConfig.rare,
    queryFn: (key, environment, from, to) => {
      return FC.service('sms').find()
    }
  },
  multiSignatures: {
    config: queryConfig.rare,
    queryFn: (key, onlyRequested, onlySigned) => {
      if (onlyRequested) return FC.service('multiSignatures').find({ query: { status: { $ne: 'signed' } } })
      if (onlySigned) return FC.service('multiSignatures').find({ query: { status: 'signed' } })
      return FC.service('multiSignatures').find()
    }
  },
  signaturesDoc: {
    config: queryConfig.autoFetchFrequent,
    queryFn: (key, noTablet) => FC.service('services').get('getSignatureByAgency', { query: { ...(noTablet ? { requestDest: 'REMOTE' } : {}), status: { $ne: 'cancelled' } } }),
    defaultKeys: [true]
  },
  statAgenzie: {
    config: queryConfig.rare,
    queryFn: (key, query) => FC.service('stats').get('agencies', { query }),
    defaultKeys: [{ from: moment().startOf('month').format('YYYY-MM-DD'), to: moment().endOf('day').format('YYYY-MM-DD') }],
    noInitalPrefetch: true
  },
  environment: {
    config: queryConfig.rare,
    queryFn: async (key) => {
      const [agency] = await FC.service('agencies').find({ query: { _id: cap.auth.state.agency } })
      const environment = await FC.service('environments').get(agency?.environment)
      return environment || {}
    }
  },
  environmentById: {
    config: queryConfig.rare,
    queryFn: async (key, envId) => {
      if (!envId || envId === 'new') return { }
      const environment = await FC.service('environments').get(envId)
      return environment || {}
    }
  },
  agency: {
    config: queryConfig.rare,
    queryFn: async (key, agencyId) => {
      const [agency] = await FC.service('agencies').find({ query: { _id: agencyId } })
      return agency
    }
  },
  multiSign: {
    config: queryConfig.rare,
    queryFn: async (key, _id) => {
      if (!_id || _id === 'new') return null
      const [multisign = null] = await FC.service('multiSignatures').find({ query: { _id } })
      return multisign
    }
  },
  emailTemplates: {
    config: queryConfig.rare,
    queryFn: () => FC.service('emailTemplates').find({}),
    noInitalPrefetch: true
  }
}

export const useCQuery = (queryKey) => {
  const { queryFn, config } = queries[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  return useQuery({ queryKey, queryFn, config: { ...config, enabled: FC.isReady() } })
}

export const prefetchQuery = (queryKey) => {
  const { queryFn, config } = queries[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryCache.prefetchQuery(queryKey, queryFn, config)
}

export const prefetchQueries = () => {
  Object.entries(queries).forEach(([queryKey, { config, queryFn, defaultKeys, noInitalPrefetch }]) => {
    const key = defaultKeys ? [queryKey, ...defaultKeys] : queryKey
    !noInitalPrefetch && queryCache.prefetchQuery(key, queryFn, config)
  })
}

export const invalidateQuery = (queryKey) =>
  (Array.isArray(queryKey) ? queryKey : [queryKey])
    .forEach(key => queryCache.invalidateQueries(key, { refetchInactive: true, force: true }))
