import React, { useState } from 'react'
import { Button, Flex, Input } from '../../../../../Components'
import { Question } from './Question'
import { typeToLabel } from './Functions'
import { WordSearch } from './WordSearch'

export const CorrectButton = ({
  type, signDate: [signDateArray, setSignDateArray],
  customQuestions: [listCustomQuestions, setListCustomQuestions],

  parsedDocument, handleNewItem, setBadgeKeyWordRender
}) => {
  const [fixedLabel, setFixedLabel] = useState('')

  const wordSearch = <WordSearch type={type} parsedDocument={parsedDocument} handleNewItem={(item) => { handleNewItem({ ...item, ...(fixedLabel ? { fixedLabel } : {}) }, type); setFixedLabel('') }} setBadgeKeyWordRender={setBadgeKeyWordRender} />

  const updateCustomCheck = (newData, id) => setListCustomQuestions(listCustomQuestions.map((el = {}) => el.id === id ? { ...el, ...newData } : el))

  const renderButton = () => {
    switch (type) {
      case 'sign':
      case 'date': {
        const correctTypeArray = signDateArray.filter(el => el.type === type)
        if (!correctTypeArray?.length || correctTypeArray[correctTypeArray.length - 1].status === 2) {
          return (
            <Button
              style={{ cursor: 'pointer' }} rich label={`Inserisci nuova ${typeToLabel(type)}`}
              onClick={() => { setSignDateArray([...signDateArray, { status: 1, type }]) }}
            />
          )
        }
        if (correctTypeArray[correctTypeArray.length - 1].status === 1) return wordSearch
        return null
      }
      case 'customCheck': {
        if (!listCustomQuestions?.length || listCustomQuestions[listCustomQuestions.length - 1].status === 3) {
          return (
            <Button
              style={{ cursor: 'pointer' }} rich label={`Crea ${typeToLabel(type)}`}
              onClick={() => { setListCustomQuestions([...listCustomQuestions, { status: 1 }]) }}
            />
          )
        }
        if (listCustomQuestions[listCustomQuestions.length - 1].status === 2) {
          return <Question item={listCustomQuestions[listCustomQuestions.length - 1]} index={listCustomQuestions.length - 1} updateCustomCheck={updateCustomCheck} />
        }
        if (listCustomQuestions[listCustomQuestions.length - 1].status === 1) return wordSearch
        return null
      }
      case 'fixedLabel': {
        const correctTypeArray = signDateArray.filter(el => el.type === type)
        if (!correctTypeArray?.length || correctTypeArray[correctTypeArray.length - 1].status === 2) {
          return (
            <Flex row>
              <Input
                label='Testo:' id='fixedLabel' value={fixedLabel}
                onChange={(e) => setFixedLabel(e.fixedLabel)}
              />
              <Button
                icon='add' style={{ marginLeft: 10, width: 220 }} label='Inserisci'
                onClick={() => { setSignDateArray([...signDateArray, { status: 1, type }]) }} disabled={!fixedLabel}
              />
            </Flex>
          )
        }
        if (correctTypeArray[correctTypeArray.length - 1].status === 1) return wordSearch
        return null
      }
    }
  }

  return renderButton()
}
