import React from 'react'
import App from './App'
import { createRoot } from 'react-dom/client'
import 'primereact/resources/themes/nova/theme.css'
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

// import 'primeflex/primeflex.css'

// import '@Common/Services/Sentry'
const container = document.getElementById('root')
const root = createRoot(container)
root.render(<React.StrictMode><App /></React.StrictMode>)
