import { PropTypes } from 'prop-types'
import React from 'react'
import { Input } from './Input'
import { useWindowSize } from 'react-use'
import COUNTRIES from '../../Resources/json/countriesPrefixPriority.json'

const countryListDropDefault = COUNTRIES.map((elem, index) => ({
  label: `${elem.name} --> +${elem.dialCode}`,
  prefix: elem.dialCode,
  value: index,
  priority: elem.priority
}))
export const NumberPrefix = ({
  style,
  styleDrop,
  stylePrefix,
  disabledDrop,
  prefixCountry,
  onChangeSelectedCountry
}) => {
  const { width: screenWidth } = useWindowSize()
  return (
    <div style={{ display: 'flex', flexDirection: 'row', ...style }}>
      <Input
        disabled={disabledDrop} // !!client._id
        dropdown
        id='dropPrefix'
        style={{
          marginRight: 5,
          minWidth: 180,
          maxWidth: screenWidth > 640 ? 180 : '100%',
          marginTop: screenWidth > 640 ? 0 : 5,
          ...styleDrop
        }}
        options={countryListDropDefault}
        placeholder={prefixCountry.label}
        onChange={(e) => {
          const filt = countryListDropDefault.filter((item) => item.value === e.dropPrefix)
          onChangeSelectedCountry(filt[0])
        }}
      />
      <Input
        disabled
        id='prefix'
        style={{
          height: 35,
          marginRight: 5,
          width: 90,
          marginTop: screenWidth > 640 ? 0 : 5,
          ...stylePrefix
        }}
        placeholder={prefixCountry.prefix}
        value={prefixCountry.prefix || ''}
      />
    </div>
  )
}
NumberPrefix.propTypes = {
  style: PropTypes.object,
  styleDrop: PropTypes.object,
  stylePrefix: PropTypes.object,
  disabledDrop: PropTypes.bool,
  prefixCountry: PropTypes.object,
  onChangeSelectedCountry: PropTypes.func
}
