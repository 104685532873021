import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../../Services'
import { setValueInLocation } from '../../../Services/Utils'

const defaultTheme = {
  colors: {
    white: '#ffffff',
    black: '#000000',
    translucent: 'rgba(255, 255, 255, 0.55)',
    opaque: 'rgba(255, 255, 255, 0.8)',
    transparent: 'rgba(0, 0, 0, 0)',
    grey: '#d3d3d3',
    themeColor: '#5F991F',
    darkTransparent: 'rgba(0,0,0,0.5)',
    fontColor: '#32324e',
    textButtonColor: '#ffffff'
  },
  logoImage: 'https://s3.eu-central-1.amazonaws.com/sign.siap/0f2242e4ede5f737736ac4833affaf70dba96365b3c1d1d8259fde1a90942853.png'
}

export const useEnvironment = (environmentId) => {
  const [environment, setEnvironment] = useState()
  const { data, isSuccess } = useCQuery(['environmentById', environmentId])

  useEffect(() => {
    isSuccess && setEnvironment({ ...data, appTheme: { ...defaultTheme, ...data?.appTheme }, ssoKey: data?.ssoKey || Math.random().toString(36).slice(2) })
  }, [isSuccess, data])

  const setEnvironmentProp = (prop, value) => {
    const newEnvironment = setValueInLocation({ ...environment }, prop, value)
    setEnvironment(newEnvironment)
  }

  return [environment, setEnvironmentProp, isSuccess]
}

export const saveEnvironment = async (environment, isNew) => {
  if (isNew) {
    try {
      const res = await FC.service('environments').create({ ...environment })
      invalidateQuery(['environment', 'environments'])
      window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
      return res
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
      return false
    }
  }
  try {
    const res = await FC.service('environments').patch(environment._id, environment)
    invalidateQuery(['environment', 'environments'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  } catch (e) {
    process.env.NODE_ENV === 'development' && console.log(e)
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
    return false
  }
}
