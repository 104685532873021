import React, { useState, useRef, useEffect } from 'react'
import { Button, Card, Flex, Spinner, Text } from '../../../Components'
import { FC, useCQuery } from '../../../Services'
import { Calendar } from 'primereact/calendar'
import SmsHistory from './SmsHistory'
import SmsTable from './SmsTable'

const Sms = () => {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [mobytMessages, setMobytMessages] = useState([])
  const [searchResult, setSearchResult] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isLoaded, setIsLoaded] = useState(true)
  const { data: environments = [], isSuccess } = useCQuery('environments')

  const bottomRef = useRef(null)

  useEffect(() => {
    if (isVisible) {
      setTimeout(() => bottomRef.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 0)
      setIsScrolled(false)
    }
  }, [isVisible, isScrolled])

  const formatDate = (date) => {
    return date.toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })
  }

  const adjustDayTime = (date) => {
    const fixedDate = new Date(date)
    fixedDate.setHours(23, 59, 59, 999)
    return new Date(fixedDate)
  }

  const buildQuery = (from, to, env) => {
    const query = { createdAt: { $gte: new Date(from), $lte: adjustDayTime(to) } }
    if (env) { query.environment = env }
    return query
  }

  const fetchSMSRecords = async (query) => {
    try {
      return await FC.service('sms').find({ query })
    } catch (error) {
      console.error('Error fetching SMS records:', error)
      throw error
    }
  }

  const calculateTotal = (resSMS) => {
    let totalIT = 0
    let totalOthers = 0

    resSMS.forEach(sms => {
      if (sms.countryCode && sms.countryCode === '39') {
        totalIT += 0.10
      } else {
        totalOthers += 0.30
      }
    })

    return { totalIT, totalOthers }
  }

  const processAllEnvironmentsResults = async (res) => {
    const groupedResults = res.reduce((acc, message) => {
      const envId = message.environment

      if (!acc[envId]) {
        const envData = environments.find(env => env._id === envId) || {}
        acc[envId] = {
          environment: envData._id || envId,
          logo: envData.design?.logo || '',
          from: formatDate(from) || 'ND',
          to: formatDate(to) || 'ND',
          totalMessaggesValue: 0,
          totalIT: 0,
          totalOthers: 0,
          total: 0
        }
      }

      acc[envId].totalMessaggesValue += 1

      return acc
    }, {})

    Object.keys(groupedResults).forEach(envId => {
      const messages = res.filter(message => message.environment === envId)
      const { totalIT, totalOthers } = calculateTotal(messages)
      groupedResults[envId].totalIT = `€ ${totalIT.toFixed(2)}`
      groupedResults[envId].totalOthers = `€ ${totalOthers.toFixed(2)}`
      groupedResults[envId].total = `€ ${(totalIT + totalOthers).toFixed(2)}`
    })

    const resultsArray = Object.values(groupedResults)
    await setMobytMessages(res)
    await setSearchResult(resultsArray)
  }

  const getSMSRecordsByDateRange = async (from, to) => {
    setIsLoaded(false)
    const query = await buildQuery(from, to)
    const res = await fetchSMSRecords(query)

    await processAllEnvironmentsResults(res)
    setIsLoaded(true)
    setIsVisible(false)
    return res
  }

  const getHistoryDetails = async (from, to, envData) => {
    const query = await buildQuery(from, to, envData?.environment)
    const res = await fetchSMSRecords(query)
    setMobytMessages(res)
  }

  if (!isSuccess) return <Spinner />
  return (
    <>
      <Flex fw js style={{ padding: 30, paddingTop: 10, marginBottom: '2em' }}>
        <Card style={{ marginTop: '1em', marginBottom: '1em', width: '70%' }}>
          <Text bold size={22} value='RICERCA' />
          <Flex fw wrap ae js row>
            <Flex je as style={{ marginRight: '4em' }}>
              <Text value='DATA INIZIO' bold size={14} />
              <Calendar value={from} className='p-custom-calendar' onChange={(e) => setFrom(e.value)} dateFormat='dd/mm/yy' placeholder='gg/mm/aaaa' />
            </Flex>
            <Flex je as style={{ marginRight: '4em' }}>
              <Text value='DATA FINE' bold size={14} />
              <Calendar value={to} className='p-custom-calendar' onChange={(e) => setTo(e.value)} dateFormat='dd/mm/yy' placeholder='gg/mm/aaaa' />
            </Flex>
            <Flex je>
              <Button label='Cerca' style={{ margin: 0 }} onClick={() => { getSMSRecordsByDateRange(from, to) }} />
            </Flex>
            {!isLoaded &&
              <Flex style={{ marginLeft: '3em' }} je>
                <Spinner />
              </Flex>}
          </Flex>
          <Flex row js style={{ marginTop: '1em', alignItems: 'none', gap: '.2em' }}>
            <p>Puoi trovare lo storico dei clienti <strong>Blueteam</strong> e <strong>Stat Viaggi</strong></p>
            <a href='https://app.esendex.it/' target='_blank' rel='noreferrer' style={{ textDecoration: 'underline' }}>qui</a>
          </Flex>
        </Card>
        {searchResult?.length > 0 && (
          <Card style={{ width: '70%', marginTop: '1em', marginBottom: '1em' }}>
            <SmsTable
              searchResult={searchResult} from={from} to={to}
              environments={environments} setIsVisible={setIsVisible}
              setIsScrolled={setIsScrolled} getHistoryDetails={getHistoryDetails}
            />
          </Card>
        )}
        {isVisible && <SmsHistory isVisible={isVisible} mobytMessages={mobytMessages} />}
      </Flex>
      <Flex>
        <div ref={bottomRef} />
      </Flex>
    </>
  )
}

export default Sms
