import _ from 'lodash'
import React from 'react'
import { Button, Flex, Text } from '../../../../../Components'
import { typeToLabel } from './Functions'

export const DateSign = ({ item, id: index, signDate: [signDateArray, setSignDateArray] }) => (
  <Flex key={item.id} style={{ borderRadius: 12, border: '1px solid black', padding: 10, position: 'relative', margin: '0px 15px' }}>
    <Text value={`${typeToLabel(item.type)} ${index + 1}`} />
    <Text value={`Pagina: ${item.page}`} />
    {/*  <Flex><Text value={`Parole di riferimento: "${item?.key?.str}"`} /></Flex> */}
    <Button
      icon='close' iconSize={10}
      style={{ width: 22, position: 'absolute', right: -11, top: -11 }}
      onClick={(e) => {
        const array = [...signDateArray]
        _.remove(array, (el) => el.id === item.id)
        setSignDateArray(array)
      }}
    />
  </Flex>
)
