import { readFile } from '../../Pages/Admin/Env/EnvEditor'
import { FC } from '../../Services/FeathersClient'
import { useRef, useState } from 'react'
import { Flex } from './Flex'
import { Button } from './Button'
import { Spinner } from './Spinner'
import { Text } from './Text'

export const ImagePicker = ({ image: [image, setImage], style, label }) => {
  const refInput = useRef(null)
  const [loading, setLoading] = useState(false)

  const uploadFile = async (e) => {
    if (!e.target?.files?.length) return null
    try {
      setLoading(true)
      const uri = await readFile(e.target.files[0])
      const res = await FC.service(`upload-${FC.uploadEnv}`).create({ uri })
      res.documentUrl && setImage(res.documentUrl)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  return (
    <Flex style={{ marginTop: 10, ...style }}>
      <input type='file' accept='image/png, image/jpeg' style={{ display: 'none' }} ref={refInput} onChange={uploadFile} />
      <Text value={label} />
      {loading && <Spinner />}
      {image && !loading ? <img src={image} style={{ width: 100, height: 100, objectFit: 'contain' }} /> : <div style={{ width: 100, height: 100, border: '1px solid black' }} />}
      <Button label='Carica' onClick={() => refInput.current.click()} style={{ marginTop: 5, width: 80 }} />
    </Flex>
  )
}
