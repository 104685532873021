import React from 'react'

import { Flex, Button, Text, Background, AgencyLogo } from '../../../Components'
import { Themes } from '../../../Resources'
import { getHost } from '../../../Services/Utils'

export default function OutsMail ({ documentType, code, designMail }) {
  const getBtnSign = (type, code) => {
    if (type === 'REMOTE' && code) {
      return <Button onClick={() => window.open(`${getHost().url}/${code}`, '_blank')} style={{ marginBottom: 10, backgroundColor: designMail.mainColor, color: '#ffffff' }} textColor='#FFFFFF' label='Firma il documento' />
    }
  }

  const getDocumentLabel = (type, code) => {
    if (type === 'CON') return 'la copia del tuo contratto firmato.'
    if (type === 'PRI') return 'la copia dell\'informativa privacy firmata.'
    if (type === 'REMOTE') {
      const link = `${getHost().url}/${code}`
      return (
        <div>
          In questa email puoi trovare il{' '}
          <a
            href={link}
            target='_blank'
            rel='noopener noreferrer'
            style={{
              color: '#007C89',
              fontWeight: 'normal',
              textDecoration: 'underline'
            }}
          >
            link
          </a>{' '}
          per effettuare la firma del documento da remoto.
        </div>
      )
    }
  }

  if (!designMail) {
    return (
      <Flex>
        <Text value='Qualcosa è andato storto, riprova.' />
      </Flex>
    )
  }
  return (
    <Background theme={Themes.siap}>
      <Flex style={{ marginTop: 20 }}>
        <AgencyLogo logo={designMail.logo} />
        <Flex style={{ marginTop: 50, padding: 24, backgroundColor: 'rgba(231, 231, 231, 0.8)', borderRadius: 20, marginBottom: '1rem' }}>
          <div>Gentile cliente, </div>
          {getDocumentLabel(documentType, code)}
          <div>
            In caso in cui avessi delle domande puoi rivolgerti alla tua {' '}
            <a
              href={designMail?.links?.contacts}
              target='_blank'
              rel='noopener noreferrer'
              style={{
                color: '#007C89',
                fontWeight: 'normal',
                textDecoration: 'underline'
              }}
            >
              agenzia di fiducia
            </a>
            {' '} oppure contattare il nostro servizio clienti.
          </div>
          <div>Ti ringraziamo per aver scelto {`${designMail.name}`} e ti auguriamo buone vacanze!</div>
        </Flex>
        <Flex>
          {getBtnSign(documentType, code)}
          <Button onClick={() => window.open(designMail.links.support, '_blank')} label='Contattaci' style={{ backgroundColor: designMail.mainColor, color: '#ffffff' }} textColor='#FFFFFF' />
        </Flex>
        <Flex style={{ marginTop: 50 }}>
          <AgencyLogo logo={designMail.logo} />
        </Flex>
      </Flex>

    </Background>
  )
}
