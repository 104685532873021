import { useLocation } from 'react-router-dom'
import { cap } from '../State'

const pathMap = [
  { path: '/instantsign', label: 'Nuova Firma' },
  { path: '/', label: 'Firme' },
  { path: '/devices', label: 'Dispositivi' },
  { path: '/clients', label: 'Clienti' },
  { path: '/list', label: 'Impostazioni env.' },
  { path: '/env', label: 'Statistiche' },
  { path: '/instantsign', label: 'Firma estemporanea' },
  { path: '/multisign/list', label: 'Firma multipla' },
  { path: '/multisign/new', label: 'Firma multipla' }

]

export const usePageTitle = () => {
  useLocation()
  if (window.location.pathname === '/') return 'Firme'

  const matchTitle = ({ path }) => path === window.location.pathname ||
    (path.includes(':id') && path.includes(window.location.pathname.split('/')[1])) ||
    (path.includes(':environment') && path.includes(window.location.pathname.split('/')[1]))

  return pathMap.find(matchTitle)?.label || 'HOME'
}

export const getMenuItems = () => [
  { label: 'Nuova Firma', icon: 'plus', path: '/newrequest', disabled: !cap.auth.state.hasFtoSign },
  { label: 'Firme', icon: 'sign', path: '/', disabled: cap.auth.state.isAdmin || cap.auth.state.isEnvAdmin },
  { label: 'Statistiche', icon: 'statistics', path: '/env', disabled: !cap.auth.state.isEnvAdmin && !cap.auth.state.isSingleUO },
  { label: 'Impostazioni', icon: 'settings', path: '/list', disabled: !cap.auth.state.isAdmin },
  {
    label: 'Dispositivi',
    icon: 'tablet',
    path: '/devices',
    disabled: (cap.auth.state.isAdmin || cap.auth.state.isEnvAdmin) || !cap.auth.state?.hasEstemporaneo || cap.auth.state.hasAtl
  },
  {

    label: 'Clienti',
    icon: 'account',
    path: '/clients',
    disabled: (cap.auth.state.isAdmin || cap.auth.state.isEnvAdmin) || !cap.auth.state?.hasFtoSign
  },
  {

    label: 'Firma estemporanea',
    icon: 'add',
    // path: '/pdf/' + cap.auth.state.environment + '/edit',
    path: '/instantsign',
    disabled: (cap.auth.state.isAdmin || cap.auth.state.isEnvAdmin) || !cap.auth.state?.hasEstemporaneo
  },
  {

    label: 'Firme Multiple',
    icon: 'database',
    // path: '/pdf/' + cap.auth.state.environment + '/edit',
    path: '/multisign/list',
    disabled: (cap.auth.state.isAdmin || cap.auth.state.isEnvAdmin) || !cap.auth.state?.hasMultiSign
  },
  {
    label: 'Logout',
    icon: 'primeicon pi-power-off',
    path: 'logout'
  }
]
