
import React, { useRef, useState, useEffect } from 'react'
import UploadDoc from './UploadDoc'
import ContactForm from './ContactForm'
import { Steps } from 'primereact/steps'
import { Toast } from 'primereact/toast'
import { Flex } from '../../../../Components'
import Confirmation from './Confirmation'
import { useLocation } from 'react-use'

const StepWizard = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [docId, setDocId] = useState(null)
  const [docName, setDocName] = useState('')
  const [docType, setDocType] = useState('')
  const toast = useRef(null)
  const { state } = useLocation()

  useEffect(() => {
    const { usr } = state || {}
    const { docInfo } = usr || {}
    const { documentId, documentName } = docInfo || {}

    if (documentId && documentName) {
      setDocId(documentId)
      setDocName(documentName)
      setDocType('EST')
      setActiveIndex(1)
    }
  }, [state])

  // Wizard stepping logic
  const navToFirstStep = () => setActiveIndex(0)
  const onSend = (bool) => { if (bool) { return setActiveIndex(2) } }

  const stepsHandler = (uploaded, file) => {
    const { name, id } = file
    if (uploaded) {
      setDocName(name)
      setDocId(id)
      setActiveIndex(1)
      toast.current.show({ severity: 'success', summary: 'Documento caricato con successo' })
    } else {
      toast.current.show({ severity: 'error', summary: 'Errore durante il caricamento, Riprova!' })
    }
  }

  const items = [
    { label: 'Carica File', content: <UploadDoc handleSelectDoc={stepsHandler} /> },
    { label: 'Contatti', content: <ContactForm docId={docId} docName={docName} docType={docType} onSend={onSend} /> },
    { label: 'Conferma', content: <Confirmation navToFirstStep={navToFirstStep} /> }
  ]

  return (
    <Flex js style={{ ...containerStyles }}>
      <Toast ref={toast} />
      <Steps model={items} style={{ width: '90%', margin: 5, minHeight: 80 }} activeIndex={activeIndex} />
      {{
        ...items[activeIndex].content,
        props: { ...items[activeIndex].content.props, navToFirstStep }
      }}
    </Flex>
  )
}

export default StepWizard

const containerStyles = {
  backgroundColor: 'rgba(231, 231, 231, 0.8)',
  borderRadius: 12,
  padding: 20,
  backdropFilter: 'blur(4px) saturate(180%)',
  WebkitBackdropFilter: 'blur(4px) saturate(180%)',
  boxShadow: '10px 10px 20px 0px  rgba(100, 100, 100, 0.24)',
  width: '80%',
  margin: '0 auto'
}
