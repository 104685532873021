import React, { useRef, useState } from 'react'
import { Button, Card, Flex, Input, Spinner, Text } from '../../../../Components'
import { FC, showGrowl, useCQuery } from '../../../../Services'
import { useParams, useNavigate } from 'react-router-dom'

const UploadDoc = ({ handleSelectDoc }) => {
  const { isSuccess, data: environment = {} } = useCQuery(['environmentById', useParams().environment])
  const refUploadDocument = useRef()
  const [docName, setDocName] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [selectedDocumentType, setSelectedDocumentType] = useState('fixed')

  const navigate = useNavigate()

  const handleUpload = () => {
    if (docName !== '') {
      setIsVisible(false)
      refUploadDocument.current.click()
    } else {
      setIsVisible(true)
      setIsUploading(false)
    }
  }

  const uploadFile = async (e) => {
    setIsUploading(true)
    const readFile = (file) =>
      new Promise((resolve, reject) => {
        const reader = new window.FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    e.stopPropagation()
    e.preventDefault()
    e.persist()
    if (!e.target.files.length) return false
    const uri = await readFile(e.target.files[0])
    try {
      const res = await FC.service(`upload-${FC.uploadEnv}`).create({ uri })
      const { documentUrl, documentId } = res
      const analyzedDoc = await FC.service('services').create({
        action: 'getDocDefinition',
        documentUrl,
        environment
      })
      if (analyzedDoc.doc.ok) {
        handleSelectDoc(true, { url: documentUrl, id: documentId, type: selectedDocumentType, name: docName })
        setIsUploading(false)
      } else {
        navigate('/newrequest/mapDoc', { state: { hasFtoSign: { url: documentUrl, id: documentId, type: 'fixed', name: docName } } })
      }
    } catch (e) {
      showGrowl('error', 'Errore', 'Errore durante il caricamento.')
    }
  }
  if (!isSuccess) return <Spinner />
  return (
    <div>
      <Card style={{ padding: '3em', borderRadius: 20, backgroundColor: '#e6f7ff' }}>
        <Text style={{ textAlign: 'center' }} value='Nuovo documento da firmare' size={22} upCase bold />
        <Flex fw style={{ gap: '1.5em' }}>
          <Text style={{ textAlign: 'center' }} value='Inserisci il nome del documento e caricalo' size={16} />
          <div style={{ position: 'relative' }}>
            <Input
              label='Nome documento:' id='docName' value={docName}
              style={{ margin: 5, width: 330, border: `${isVisible ? '1px solid red' : '1px solid #fff'}` }}
              onChange={(e) => { setDocName(e.docName); setIsVisible(false) }}
            />
            <Text style={{ textAlign: 'center', position: 'absolute', left: '1em', color: 'red', display: `${isVisible ? 'block' : 'none'}` }} size={12} value='Campo Obbligatorio' />
          </div>
          <input type='file' accept='application/pdf' ref={refUploadDocument} style={{ display: 'none' }} onChange={(e) => uploadFile(e)} />
          {!isUploading &&
            <Button
              style={{ width: 330 }} label='Carica Documento' icon='plus'
              onClick={handleUpload}
            />}
          {isUploading && <Spinner />}
        </Flex>
      </Card>
    </div>
  )
}

export default UploadDoc
