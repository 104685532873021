import React, { useEffect, useState } from 'react'
import { Text } from '../../../Components'
import { sampleDataTemplateCompiler, elementRegex } from './utils'
import { format } from '@projectwallace/format-css'

/**
 * Create a preview of an email template
 *
 * @param {object} props.data.type - Template type
 * @param {object} props.data.environment - Template environment
 *
 * @returns {JSX.Element}
 */
const EmailViewer = ({ data }) => {
  const [responseData, setResponseData] = useState([])

  useEffect(() => {
    (async () => {
      const res = await sampleDataTemplateCompiler([{
        environment: data.environment,
        type: data.type,
        templateInput: null
      }])
      setResponseData(res.map(({ content, contentLabel }) => {
        const hasStyle = content.match(elementRegex('style'))
        return {
          body: content.match(elementRegex('body'))[0].trim(),
          style: hasStyle
            ? format(content.match(elementRegex('style'))[0]).replaceAll('\t', '  ')
            : '',
          contentLabel
        }
      }))
    })()
  }, [data])

  return (
    <div style={emailsContainerStyle}>
      {responseData.map(({ contentLabel, style, body }, key) => (
        <React.Fragment key={key}>
          <Text
            center style={{ margin: '20px', textTransform: 'capitalize' }}
            size='31px' value={contentLabel}
          />
          {style && <style>{style}</style>}
          <div className={data.type} dangerouslySetInnerHTML={{ __html: body }} />
        </React.Fragment>
      )
      )}
    </div>
  )
}

const emailsContainerStyle = {
  width: '100%',
  margin: '0 auto',
  paddingBottom: '40px'
}

export default EmailViewer
