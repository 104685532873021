import React, { useEffect, useState } from 'react'
import { Button, Flex, Input, Text } from '../../../../../Components'
import ContactCard from '../ContactCard'
import { useCQuery } from '../../../../../Services'

const inputPropsText = (disabled, id, placeholder, value, extraStyle = {}) => ({ placeholder, id, value: value || '', style: { width: '100%' }, disabled })

const useClients = (personData) => {
  const { data: clients = [] } = useCQuery('clients')
  const [filteredClients, setFilteredClients] = useState([])

  useEffect(() => {
    setFilteredClients(clients.filter(({ contactPerson }) => {
      const { name, lastname, email, phoneNumber } = personData
      const matches = [
        name ? contactPerson?.name.toLowerCase().includes(name.toLowerCase()) : true,
        lastname ? contactPerson?.lastname.toLowerCase().includes(lastname.toLowerCase()) : true,
        email ? contactPerson?.email.toLowerCase().includes(email.toLowerCase()) : true,
        phoneNumber ? contactPerson?.phoneNumber.toLowerCase().includes(phoneNumber.toLowerCase()) : true
      ]
      return matches.every(Boolean)
    }))
  }, [clients, personData])

  return filteredClients
}

export function SearchClients ({ goBack, onSend }) {
  const [contactPersonData, setContactPersonData] = useState({ name: '', lastname: '', email: '', phoneNumber: '' })
  const clients = useClients(contactPersonData)
  const updateContactPersonData = (update) => setContactPersonData({ ...contactPersonData, ...update })

  const [isContactSelected, setIsContactSelected] = useState(false)

  const handleCardClick = (data) => {
    setContactPersonData(data)
    setIsContactSelected(true)
  }

  return (
    <Flex js style={{ padding: '2em', borderRadius: 20, backgroundColor: '#e6f7ff', gap: '1em' }}>
      <Button tooltip='Indietro' round icon='back' style={{ alignSelf: 'flex-start' }} onClick={goBack} />
      <Text
        style={{ textAlign: 'center' }} size={24} bold upCase
        value={isContactSelected ? 'Contatto Selezionato' : 'Seleziona contatto esistente'}
      />
      <Flex row fw style={{ gap: '1em', width: '90%' }}>
        <Input {...inputPropsText(isContactSelected, 'name', 'Nome', contactPersonData?.name)} onChange={updateContactPersonData} />
        <Input {...inputPropsText(isContactSelected, 'lastname', 'Cognome', contactPersonData?.lastname)} onChange={updateContactPersonData} />
      </Flex>
      <Flex row fw style={{ gap: '1em', width: '90%' }}>
        <Input {...inputPropsText(isContactSelected, 'email', 'Email', contactPersonData?.email)} onChange={updateContactPersonData} />
        <Input {...inputPropsText(isContactSelected, 'phoneNumber', 'Telefono', contactPersonData?.phoneNumber)} onChange={updateContactPersonData} />
      </Flex>
      {isContactSelected
        ? (
          <Flex style={{ margin: '1em' }}>
            <ContactCard client={{ contactPerson: contactPersonData }} selected />
            <Flex fw row wrap style={{ gap: '1em', margin: '1em' }}>
              <Button style={{ minWidth: 200 }} icon='mail' label='Invia Via Email' onClick={() => { onSend(contactPersonData, 'MAIL'); setIsContactSelected(false); setContactPersonData({ name: '', lastname: '', email: '', phoneNumber: '' }) }} />
              <Button style={{ minWidth: 200 }} icon='send' label='Invia Via SMS' onClick={() => { onSend(contactPersonData, 'SMS'); setIsContactSelected(false); setContactPersonData({ name: '', lastname: '', email: '', phoneNumber: '' }) }} />
            </Flex>
            <Button style={{ minWidth: 200 }} icon='close' label='Annulla' onClick={() => { setIsContactSelected(false); setContactPersonData({ name: '', lastname: '', email: '', phoneNumber: '' }) }} />
          </Flex>)
        : (
          <Flex style={{ padding: 20 }}>
            <Flex fh style={{ borderRadius: '8px', backgroundColor: 'rgba(231, 231, 231, 0.8)' }}>
              <Text
                style={{ textAlign: 'center', margin: '.5em auto' }} size={14} bold upCase
                value={`${clients.length !== 0
                ? 'Contatti Trovati: ' + clients.length
                : 'Inizia a digitare per trovare contatti esistenti'}`}
              />
              <Flex fh row wrap fw style={{ gap: 20, overflowY: 'scroll', padding: '.5em' }}>
                {clients.length > 0 && clients.map((client, idx) => (
                  <ContactCard key={idx} client={client} onCardClick={handleCardClick} />
                ))}
              </Flex>
            </Flex>
          </Flex>
          )}
    </Flex>
  )
}
