import { Component } from 'react'
import ReactDOM from 'react-dom'

const modalRoot = document.getElementById('modal-root')
class Modal extends Component {
  constructor (props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount () {
    modalRoot.appendChild(this.el)
  }

  componentWillUnmount () {
    try {
      modalRoot && modalRoot.removeChild && modalRoot.removeChild(this.el)
    } catch (e) { }
  }

  render () {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

export { Modal }
