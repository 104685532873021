import React, { useState } from 'react'
import { Flex, Input, Text } from '../../../../../Components'

export const WordSearch = ({ parsedDocument, type, handleNewItem, setBadgeKeyWordRender }) => {
  const [keyWordsInput, setKeyWordsInput] = useState('')
  const [filteredParsedDocument, setFilteredParsedDocument] = useState([])

  const filterParserByKey = (e) => {
    setKeyWordsInput(e.keyWords)
    const filtered = parsedDocument.filter(parsed => parsed.str.toLowerCase().includes(e.keyWords.toLowerCase()))
    setFilteredParsedDocument(filtered)
    if (filtered.length && filtered.length < 10) setBadgeKeyWordRender(filtered.map(({ page, x, y, pageWidth, pageHeight }, index) => ({ index, x, y, page, pageWidth, pageHeight })))
    else setBadgeKeyWordRender([])
  }

  const cardFilteredKey = () =>
    filteredParsedDocument.map((key, index) => (
      <Flex
        key={index}
        style={{ border: '1px solid black', margin: 10, borderRadius: 10, padding: 15, cursor: 'pointer' }}
        onClick={() => handleNewItem(filteredParsedDocument[index])}
      >
        <Text value={`Parola chiave: ${key.str}`} />
        <Text value={`Pagina: ${key.page}`} />
        <Text value='disabilita' />
      </Flex>
    ))

  const renderWords = () => {
    return filteredParsedDocument.length < 10 && filteredParsedDocument.length
      ? <Flex><Text value='Parole chiave filtrate' />{cardFilteredKey()}</Flex>
      : null
  }

  return (
    <>
      <Input
        id='keyWords' label='Parola chiave:' value={keyWordsInput}
        style={{ width: '90%', border: '1px solid black' }} onChange={filterParserByKey}
      />
      {renderWords()}
    </>
  )
}
