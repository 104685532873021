import React, { useState, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'

import { Button, Flex, ModalGeneric, Spinner, Input } from '../../Components'
import { FC, invalidateQuery, showGrowl, useCQuery } from '../../Services'
import DeleteBtn from './DeleteBtn'

function TabDevices () {
  const { isSuccess, data = [] } = useCQuery('devices')
  const [deviceCode, setDeviceCode] = useState('')
  const [descriptionDevice, setDescriptionDevice] = useState('')

  const modalAddDevice = useRef(null)

  if (!isSuccess) return <Spinner />

  const bodyActions = (rowData) => {
    return (
      <Flex width={160}>
        <DeleteBtn rowData={rowData} />
      </Flex>
    )
  }

  const hideModal = () => modalAddDevice?.current?.hide ? modalAddDevice.current.hide() : modalAddDevice.hide()

  const openModalAddDevice = () => modalAddDevice?.current?.show ? modalAddDevice.current.show() : modalAddDevice.show()

  const cleanData = () => {
    setDescriptionDevice('')
    setDeviceCode('')
    hideModal()
  }

  const addDevice = async () => {
    if (!deviceCode || !descriptionDevice) return showGrowl('error', 'Errore', 'Errore nell\'aggiunta del dispositivo. Compila entrambi i campi.')
    try {
      const response = await FC.service('services').get('registerDevice', { query: { descriptionDevice, deviceCode: deviceCode?.toUpperCase() } })
      if (!response?.ok) return showGrowl('error', 'Errore', 'Errore nell\'aggiunta del dispositivo. Riprova')
      invalidateQuery('devices')
      cleanData()
      return showGrowl('success', 'Dispositivo aggiunto', 'Dispositivo aggiunto con successo!')
    } catch {
      return showGrowl('error', 'Errore', 'Errore nell\'aggiunta del dispositivo. Riprova')
    }
  }

  const insertDeviceData = () => {
    return (
      <Flex>
        <Input {...inputPropsText('deviceCode', 'ID dispositivo', deviceCode, setDeviceCode)} />
        <Input {...inputPropsText('descriptionDevice', 'Descrizione', descriptionDevice, setDescriptionDevice)} />
        <Flex row fw style={{ marginTop: 10 }}>
          <Button icon='close' label='Esci' onClick={cleanData} style={{ marginRight: 10 }} />
          <Button icon='check' label='Salva' onClick={addDevice} style={{ marginLeft: 10 }} />
        </Flex>
      </Flex>
    )
  }

  return (
    <Flex fw fh>
      <ModalGeneric
        ref={modalAddDevice}
        header='Inserisci dati nuovo dispositivo'
        content={insertDeviceData()}
      />
      <Flex fw row je ae>
        <Button label='Associa nuovo dispositivo' icon='sincronize-phone' style={{ margin: 10 }} onClick={openModalAddDevice} />
      </Flex>
      <DataTable
        style={{ width: '100%' }}
        value={data} emptyMessage='Nessun dispositivo associato' responsiveLayout='scroll' paginator rows={10} className='TableSignatures'
        filterDisplay='row'
        sortField='deviceCode' sortOrder={-1}
      >
        <Column field='deviceCode' {...columnProps('ID dispositivo')} />
        <Column field='descriptionDevice' {...columnProps('Descrizione')} />
        <Column style={btnColumnStyle()} body={(rowData) => bodyActions(rowData)} />
      </DataTable>
    </Flex>
  )
}

const inputPropsText = (id, placeholder, value, setValue) => ({
  placeholder,
  id,
  value: value || '',
  onChange: (e) => setValue(e[id]),
  style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
})

const btnColumnStyle = () => ({
  border: 'none',
  width: 140,
  height: 15,
  textAlign: 'center'
})

const columnProps = (filterPlaceholder) => ({

  filter: true,
  filterMatchMode: 'contains',
  filterPlaceholder,
  style: { textAlign: 'center', border: 'none' },
  showFilterMenu: false,
  showClearButton: false
})

export default TabDevices
