import React from 'react'
import { Flex, Icon, Button, Card, Text } from '..'

export const Tabs = ({ style, currentTab, children, onTabSelection, onNewTabClick }) => {
  return (
    <Flex style={{ ...style }}>
      <Flex row fw js wrap>
        {children?.map((child, i) => {
          const { icon, title, index } = child.props
          return (
            <Flex key={'tabKey' + i} onClick={() => onTabSelection && onTabSelection(i)} row style={{ ...tabStyle, backgroundColor: currentTab === index ? 'white' : 'rgba(231, 231, 231, 0.8)' }}>
              {icon && <Icon name={icon} size={20} style={{ marginLeft: 10, marginTop: -4 }} />}

              <Text bold title size={13} style={{ marginLeft: 10 }} value={title || ''} />

            </Flex>
          )
        })}
        {onNewTabClick && <div style={{ width: 180 }}><Button noShadow icon='plus' style={newTabStyle} round tooltip='Nuova proposta' onClick={onNewTabClick} /></div>}
      </Flex>
      <Flex bg='white' fw height={3} />
      <Card fadeIn style={cardStyle}>
        {children[currentTab] ? children[currentTab].props.children : null}
      </Card>
    </Flex>
  )
}

export const TabHeader = ({ children, icon, title, index }) => null

const tabStyle = {
  cursor: 'pointer',
  backgroundColor: 'rgba(231, 231, 231, 0.8)',
  borderTopLeftRadius: 20,
  borderTopRightRadius: 20,
  width: 250,
  height: 35,
  marginRight: 10,
  boxShadow:
    '1px -1px 3px 0 rgba(0, 0, 0, 0.2), 1px -1px 1px 0 rgba(0, 0, 0, 0.14), 2px -1px 1px -1px rgba(0, 0, 0, 0.12)'
}

const cardStyle = {
  width: '100%',
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  backgroundColor: 'rgba(231, 231, 231, 0.8)'
}
const newTabStyle = {
  borderTopLeftRadius: 15,
  borderTopRightRadius: 15,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: 'rgba(231, 231, 231, 0.8)',
  boxShadow:
    '1px -1px 3px 0 rgba(0, 0, 0, 0.2), 1px -1px 1px 0 rgba(0, 0, 0, 0.14), 2px -1px 1px -1px rgba(0, 0, 0, 0.12)'
}
