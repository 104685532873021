import { Button, Flex, Input, Text } from '../../../../../Components'

export const Question = ({ item, index, updateCustomCheck }) => (
  <Flex key={item.id} style={{ marginBottom: 50, marginTop: 50 }}>
    <Text bold style={{ marginBottom: 10 }} value='Inserire la domanda per le crocette' />
    <Input
      label='Domanda:'
      id='question'
      value={item.question}
      style={{ border: '1px solid black' }}
      onChange={(e) => updateCustomCheck(e, item.id)}
    />
    <Button
      style={{ cursor: 'pointer', marginTop: 10, marginBottom: 10 }}
      rich label='Conferma domanda'
      onClick={() => {
        const timestamp = Date.now()
        updateCustomCheck({
          answerQuestion: [{
            coordinates: { x: 250, y: 400 },
            width: 18,
            height: 18,
            id: item.type + timestamp,
            page: item.page,
            isYesAnswer: true
          }],
          status: 3
        }, item.id)
      }}
    />
  </Flex>
)
