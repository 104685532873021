import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { cap } from '../State'
import AppContainer from '../Components/Common/AppContainer'
import { MainPage, RemoteSignature, Login, SingleSignOn, BrowserEmail, EnvAdminHome, Admin, Status, DevicesPage, ClientsPage, PdfList, PdfEditor } from '../Pages'
import { TopMenu } from '../Components/Layout/TopMenu'
import MultiSign from '../Pages/MultiSign/List'
import NewMultiSign from '../Pages/MultiSign/New'
import Main from '../Pages/NewRequest'
import { EnvEditor } from '../Pages/Admin/Env/EnvEditor'

const CheckRoute = ({ role: rolesList, children, hideMenu = false, hideIfAtl }) => {
  const [{ isEnvAdmin, isAdmin, jwt, hasEstemporaneo, hasAtl, hasFtoSign, hasMultiSign, isSingleUO }] = cap.auth.useState()
  const roles = rolesList?.split('-') || []

  const isCorrectUser = roles.find(role => !((role === 'admin' && !isAdmin) ||
    (role === 'hasEstemporaneo' && !hasEstemporaneo) ||
    (role === 'hasFtoSign' && !hasFtoSign) ||
    (role === 'envAdmin' && !isEnvAdmin && !isAdmin) ||
    (role === 'isSingleUO' && !isSingleUO) || (role === 'user' && !jwt) ||
    (role === 'hasMultiSign' && !hasMultiSign)))

  if (hideIfAtl && hasAtl) return <Navigate to={isAdmin ? '/list' : isEnvAdmin ? '/env' : jwt ? '/' : '/login'} />
  return (isCorrectUser || !roles?.length)
    ? children
    : <Navigate to={isAdmin ? '/list' : isEnvAdmin ? '/env' : jwt ? '/' : '/login'} />
}
const App = () => {
  return (
    <AppContainer>
      <BrowserRouter>
        <TopMenu />
        <Routes>
          <Route path='/list' element={<CheckRoute role='admin'><Admin /></CheckRoute>} />
          <Route path='/instantsign' element={<CheckRoute role='hasEstemporaneo'><PdfEditor /></CheckRoute>} />
          <Route path='/environments/:environmentId' element={<CheckRoute role='admin'><EnvEditor /></CheckRoute>} />
          <Route path='/pdf/:environment/:documentId' element={<CheckRoute role='admin-hasEstemporaneo'><PdfEditor /></CheckRoute>} />
          <Route path='/pdf/:environment/list' element={<CheckRoute role='admin-hasEstemporaneo'><PdfList /></CheckRoute>} />
          <Route path='/env' element={<CheckRoute role='envAdmin-isSingleUO'><EnvAdminHome /></CheckRoute>} />
          <Route path='/' element={<CheckRoute role='user'><MainPage /></CheckRoute>} />
          <Route path='/newrequest' element={<CheckRoute role='hasFtoSign'><Main /></CheckRoute>} />
          <Route path='/newrequest/mapDoc' element={<PdfEditor />} />
          <Route path='/browserEmail' element={<CheckRoute hideMenu><BrowserEmail /></CheckRoute>} />
          <Route path='/login' element={<CheckRoute hideMenu><Login /></CheckRoute>} />
          <Route path='/sso' element={<CheckRoute hideMenu><SingleSignOn /></CheckRoute>} />
          <Route path='/:signId' element={<CheckRoute hideMenu><RemoteSignature /></CheckRoute>} />
          <Route path='/browserEmail' element={<CheckRoute hideMenu><BrowserEmail /></CheckRoute>} />
          <Route path='/devices' element={<CheckRoute hideIfAtl role='hasEstemporaneo'><DevicesPage /></CheckRoute>} />
          <Route path='/clients' element={<CheckRoute role='hasFtoSign-hasEstemporaneo'><ClientsPage /></CheckRoute>} />
          <Route path='/multisign/list' element={<CheckRoute role='hasMultiSign'><MultiSign /></CheckRoute>} />
          <Route path='/multisign/:multisignId' element={<CheckRoute role='hasMultiSign'><NewMultiSign /></CheckRoute>} />
          <Route path='/multisign/mapDoc' element={<CheckRoute role='hasEstemporaneo'><PdfEditor /></CheckRoute>} />
          <Route path='/status' element={<Status />} />
        </Routes>
      </BrowserRouter>
    </AppContainer>
  )
}
export default App
