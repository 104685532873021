import React, { useEffect, useState } from 'react'
import { Button, Flex, Input, Text } from '../../../Components'
import { FC, invalidateQuery } from '../../../Services'
import { useCQuery } from '../../../Services/QueryCache'

export const AgencyEditor = ({ edit, onClose }) => {
  const { data: dbEnvs = [], isSuccess } = useCQuery('environments')
  const [environments, setEnvironments] = useState([])
  const [id, setId] = useState('')
  const [name, setName] = useState('')
  const [codSociety, setCodSociety] = useState('')
  const [opUnit, setOpUnit] = useState('')
  const [email, setEmail] = useState('')
  const [environment, setEnvironment] = useState('')
  const [noTablet, setNoTablet] = useState('')

  const isNew = edit === 'new'

  useEffect(() => {
    if (!isNew) {
      setId(edit?._id)
      setName(edit?.name)
      setCodSociety(edit?.codSociety)
      setOpUnit(edit?.opUnit)
      setEmail(edit?.email)
      setEnvironment(edit?.environment)
      setNoTablet((!edit?.noTablet).toString())
    }
  }, [edit])

  useEffect(() => {
    if (isSuccess) setEnvironments(dbEnvs.map(env => (env._id)))
  }, [isSuccess, dbEnvs])

  const createNewAgency = async () => {
    const res = await FC.service('agencies').create({
      _id: id,
      name,
      codSociety,
      opUnit,
      email,
      environment,
      noTablet: noTablet === 'false'
    })
    res._id && window.growl.show({ severity: 'success', summary: 'Agenzia Creata', detail: 'Agenzia Creata con successo!' })
    invalidateQuery('agencies')
  }

  const editAgency = async () => {
    const res = await FC.service('agencies').patch(id, {
      name,
      codSociety,
      opUnit,
      email,
      environment,
      noTablet: noTablet === 'false'
    })
    res._id && window.growl.show({ severity: 'success', summary: 'Agenzia Creata', detail: 'Agenzia Modifica con successo!' })
    invalidateQuery('agencies')
    onClose()
  }

  const inputPropsText = (id, placeholder, value, setValue) => ({
    placeholder,
    id,
    value: value || '',
    onChange: (e) => setValue(e[id]),
    style: { border: '1px solid black', width: '46%', margin: '10px 2%' }
  })

  return (
    <Flex fw>
      <Flex fw wrap row>
        <Input {...inputPropsText('id', 'ID', id, isNew && setId)} />
        <Input {...inputPropsText('name', 'Nome', name, setName)} />
        <Input {...inputPropsText('codSociety', 'Codice società', codSociety, setCodSociety)} />
        <Input {...inputPropsText('opUnit', 'Unità Operativa', opUnit, setOpUnit)} />
        <Input {...inputPropsText('email', 'Email', email, setEmail)} />
        <Input {...inputPropsText('environment', 'Environment', environment, setEnvironment)} dropdown options={environments} />
        <Flex row style={{ border: '1px solid black', borderRadius: 20, padding: '5px 20px', width: '46%', margin: '10px 2%' }}>
          <Text value='Visualizza firme tablet in dashboard web ' />
          <Input
            id='noTablet' value={noTablet}
            onChange={({ noTablet }) => setNoTablet(noTablet)}
            dropdown options={[{ value: 'true', label: 'Sì' }, { value: 'false', label: 'No' }]}
          />
        </Flex>
      </Flex>
      <Button label={isNew ? 'Crea' : 'Modifica'} icon='plus' style={{ marginTop: 20 }} onClick={isNew ? createNewAgency : editAgency} />
    </Flex>
  )
}
