import React from 'react'
import { PageContainer } from '../../Components/Layout/PageContainer.jsx'
import StepWizard from './Components/Wizard/index.jsx'

const Main = () => {
  return (
    <PageContainer style={{ marginTop: -60 }}>
      <StepWizard />
    </PageContainer>
  )
}

export default Main
