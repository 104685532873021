import React, { useRef } from 'react'
import { Button, Flex, ModalGeneric } from '../../../Components'
import { resendSignatures, sendMultiSign } from './Functions'
import { useParams } from 'react-router-dom'
import ModalContent from '../../Signatures/Components/ModalContent'
import { showGrowl, useCQuery } from '../../../Services'
import { SelectMultiSignMode } from './Components'
import { MultiSignDoc } from './MultiSignDoc'
import { MultiSignTable } from './MultiSignTable'

const MultiSignMainData = ({ clients: [clients, setClients], onSave }) => {
  const { multisignId } = useParams()
  const { data: multiSign } = useCQuery(['multiSign', multisignId])

  const resendRef = useRef()
  const sendRef = useRef()

  const { status, name: multiSignName = '' } = multiSign || {}
  const showSignaturesStatus = status === 'requested' || status === 'sending' || status === 'signed'
  const isNew = multisignId === 'new'

  const checkErrorSave = () => !multiSignName || !clients.length
  const checkErrorSend = () => checkErrorSave() || !multiSign?.mappedDocument

  const sendSignRequests = (sendMode) => {
    sendRef.current.hide()
    if (checkErrorSend()) return showGrowl('error', 'Attenzione', 'Compilare tutti i campi obbligatori e mappare il documento')
    sendMultiSign(multisignId, sendMode)
  }

  return (
    <Flex fw style={{ padding: 10, paddingTop: 20 }}>
      <MultiSignTable clients={[clients, setClients]} />
      {!isNew && <MultiSignDoc />}
      <Flex row style={{ marginTop: 20 }}>
        {!showSignaturesStatus && <Button icon='send' label={`${isNew ? 'Crea' : 'Salva'} firma multipla`} onClick={onSave} />}
        {status === 'created' && (
          <>
            <Button icon='go' label='Invia Richieste di Firma' style={{ marginLeft: 20 }} onClick={() => sendRef.current.show()} />
            <ModalGeneric ref={sendRef} header='Invia Richieste' content={<SelectMultiSignMode onConfirm={sendSignRequests} hide={() => sendRef.current.hide()} />} />
          </>
        )}
        {status === 'requested' &&
          <>
            <Button icon='go' label='Invia sollecito e rinnova richieste scadute' style={{ marginLeft: 20 }} onClick={() => resendRef.current.show()} />
            <ModalGeneric ref={resendRef} header='Invio Sollecito e Rinnovo Firme' content={<ModalContent onHide={() => resendRef.current.hide()} onConfirm={() => { resendSignatures(multisignId); resendRef.current.hide() }} contentText='Sei sicuro di volere inviare un sollecito ai clienti che non hanno ancora firmato il documento e di rinnovare la data di scadenza delle richieste scadute?' />} />
          </>}
      </Flex>
    </Flex>
  )
}
export default MultiSignMainData
