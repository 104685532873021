import React from 'react'
import { getUrlParams } from '../../Services/Utils'
import { useNavigate } from 'react-router-dom'
import { Tabs, TabHeader, Flex } from '../../Components'
import TabSignatures from './Components/TabSignatures'

const getTabs = () => [
  { id: 0, icon: 'doc-2', title: 'Documenti FIRMATI', content: <TabSignatures signed /> },
  { id: 1, icon: 'edit-2', title: 'Documenti In RICHIESTA', content: <TabSignatures requested /> },
  { id: 2, icon: 'expired', title: 'Documenti SCADUTI', content: <TabSignatures expired /> }
]

const Signatures = () => {
  const tab = getUrlParams('tab')
  const navigate = useNavigate()
  const currentTab = parseInt(tab) || 0

  return (
    <Flex fw>
      <Tabs
        style={{ width: '100%', height: '100%', padding: 20 }}
        onTabSelection={(e) => navigate('/?tab=' + e)}
        currentTab={currentTab}
      >
        {getTabs()?.map((tab, index) => (
          <TabHeader
            title={tab?.title}
            key={index}
            index={index}
            icon={tab?.icon}
          >
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </Flex>
  )
}

export default Signatures
