import React from 'react'
import { Flex, Input, Text } from '../../../../../../Components'
import { Checkbox } from 'primereact/checkbox'
import { useCQuery } from '../../../../../../Services'
import { Select } from '../../../../../../Components/Common/Select'

const mailTypes = [{ value: 'PEC', varName: 'configPEC', label: 'PEC' }, { value: 'mail', varName: 'configEmail', label: 'Standard Email' }]
const otpOptions = [{ value: true, label: 'Firma con OTP' }, { value: false, label: 'Firma senza OTP' }]

export const SelectMailType = ({ mail: [selectedMailType, setSelectedMailType], client: [{ email, phoneNumber }, setData], otp: [otp, setOtp] }) => {
  const { data: environment } = useCQuery('environment')

  return (
    <Flex style={{ padding: 40, paddingBottom: 0 }}>
      <Flex fw js fh>
        {environment?.configPEC?.host && (
          <>
            <Text value='Seleziona il tipo di email che vuoi utilizzare (PEC o mail)' bold title size={16} />
            {mailTypes.map(({ value, varName, label }) => (
              <Flex
                row fw onClick={e => setSelectedMailType(value)} key={value} jb
                style={{ border: '1px solid black', padding: 20, borderRadius: 10, margin: 5, cursor: 'pointer' }}
              >
                <Checkbox checked={selectedMailType === value} style={{ width: 20 }} />
                <Flex style={{ width: 'calc(100% - 20px)' }}>
                  <Text value={label} />
                  <Text value={environment?.[varName]?.from} />
                  <Text value={environment?.[varName]?.host} />
                </Flex>
              </Flex>
            ))}
          </>)}
        <Flex style={{ marginTop: 15 }}>
          <Text value="Scrivi l'email del cliente a cui vuoi inviare la richiesta di firma" bold title size={16} />
          <Input id='email' value={email} onChange={({ email }) => setData({ email })} label='Email Cliente' style={{ width: 400, marginTop: 5 }} />
        </Flex>
        <Flex style={{ marginTop: 15 }}>
          <Text value='Scrivi il numero del cliente a cui vuoi inviare la richiesta di firma' bold title size={16} />
          <Input id='phoneNumber' value={phoneNumber} onChange={({ phoneNumber }) => setData({ phoneNumber })} label='Numero Cliente' style={{ width: 400, marginTop: 5 }} />
        </Flex>
        {environment.alwaysUseOtp
          ? (setOtp(true))
          : (
            <>
              <Text value="Scegli se vuoi utilizzare l'OTP oppure no" style={{ marginTop: 20 }} bold title size={16} />
              <Select value={otp} options={otpOptions} style={{ fontSize: 12, marginTop: 5 }} onChange={({ value }) => setOtp(value)} />
            </>
            )}
      </Flex>
    </Flex>
  )
}
