import React, { useRef } from 'react'
import SignaturePad from 'react-signature-canvas'

import { Button, Text } from '../../../Components'

export default function Panel ({ canvasProps, buttonBoxStyle, buttonConfirmStyle, buttonCancelStyle, onPressCancel, onPressConfirm, designPage }) {
  const signatureRef = useRef(null)

  const onConfirm = () => {
    const b64 = signatureRef.current.isEmpty() ? null : signatureRef.current.toDataURL()
    onPressConfirm(b64)
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 2.5, marginBottom: 10 }}>
        <Text bold title value={'Per una migliore applicazione della firma, comincia a firmare a partire dall\'angolo in basso a sinistra.'} />
      </div>
      <SignaturePad
        canvasProps={{
          style: canvasProps || defaultStyle.canvasProps
        }}
        ref={signatureRef}
      />
      <div style={buttonBoxStyle || defaultStyle.buttonBoxStyle}>
        <Button
          onClick={onPressCancel}
          label='Cancella'
          style={buttonCancelStyle || defaultStyle.buttonCancelStyle}
          icon='close'
          inverted
        />
        <Button
          onClick={onConfirm}
          label='Conferma'
          style={{ ...buttonCancelStyle || { ...defaultStyle.buttonCancelStyle }, backgroundColor: designPage?.mainColor }}
          icon='check'
          inverted
        />
      </div>
    </div>
  )
}

const defaultStyle = {
  canvasProps: {
    width: '100%',
    border: '1px solid black',
    borderRadius: 25,
    marginBottom: 20,
    minHeight: 100
  },
  buttonBoxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10
  },
  buttonCancelStyle: { backgroundColor: '#32324e', color: '#fff' },
  buttonConfirmStyle: { color: '#fff' }
}
