import React from 'react'
import { Flex, Text, Button } from '../../../Components'

export default function SignatureDetailsModal ({ onHide, contentText = '', contentBody }) {
  return (
    <Flex column js an style={{ padding: '1em', maxWidth: '80%', margin: '0 auto' }}>
      <Text style={{ marginTop: -20 }} value='In questa sezione sono mostrate le informazioni aggiuntive contenute nella mail della richiesta di firma' />
      <Flex style={{ minWidth: '100%', backgroundColor: '#FFF', borderRadius: 20, padding: '1em', margin: '.5em' }}>
        <Text value={contentText} />
      </Flex>
      {contentBody && contentBody()}
      <Flex row style={{ marginTop: 10 }}>
        <Button label='Chiudi' icon='check' onClick={onHide} style={{ marginLeft: 10 }} />
      </Flex>
    </Flex>
  )
}
