import React from 'react'
import { useNavigate } from 'react-router-dom'

import { getUrlParams } from '../../Services/Utils'
import { Tabs, TabHeader, Flex } from '../../Components'
import TabClients from './TabClients'

const getTabs = () => [
  { id: 0, icon: 'account', title: 'Clienti', content: <TabClients /> }
]

export default function Clients () {
  const tab = getUrlParams('tab')
  const navigate = useNavigate()
  const currentTab = parseInt(tab) || 0

  return (
    <Flex fw>
      <Tabs
        style={{ width: '100%', height: '100%', padding: 20 }}
        onTabSelection={(e) => navigate('/?tab=' + e)}
        currentTab={currentTab}
      >
        {getTabs()?.map((tab, index) => (
          <TabHeader
            title={tab?.title}
            key={index}
            index={index}
            icon={tab?.icon}
          >
            {tab?.content}
          </TabHeader>
        ))}
      </Tabs>
    </Flex>
  )
}
