import React from 'react'
import { motion } from 'framer-motion'
import { useLocation, useNavigate } from 'react-router-dom'
import { useWindowSize } from 'react-use'
import PoweredBySIAP from './PoweredBySIAP.png'
import { Text, Flex } from '../../Components'
import { useCQuery, getMenuItems, usePageTitle } from '../../Services'
import { SlidingMenu, HamburgerMenu } from './SlidingMenu'
import { cap, doLogout } from '../../State'

export const TopMenu = () => {
  const { data: agency = {}, isSuccess: isSuccessAgency } = useCQuery(['agency', cap.auth.state.agency])

  const pageTitle = usePageTitle()
  const { width } = useWindowSize()

  const agencyName = cap?.auth?.state.isAdmin
    ? 'Admin'
    : cap?.auth?.state?.isEnvAdmin
      ? 'Env Admin: ' + cap?.auth?.state?.environment
      : agency?.name

  const navigate = useNavigate()
  const menuAction = (page) => page === 'logout' ? doLogout() : navigate(page)

  const { pathname } = useLocation()
  // Se all'interno della capsula non ho agency (non ho fatto il login) non mi fa vedere il Menu. (caso di firme remote)
  if (!isSuccessAgency) return null

  return (pathname.includes('list') || pathname.includes('pdf') || pathname.includes('multisign') || pathname.includes('env') || pathname.includes('devices') || pathname.includes('clients') || pathname.includes('instantsign') || pathname.includes('newrequest') || pathname === '/') && (
    <>
      <SlidingMenu menuAction={menuAction} menuItems={getMenuItems()} />
      <motion.div style={styles.containerStyle} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
        <Flex js row fw>
          <HamburgerMenu />
          <Text title bold size='20px' style={{ marginLeft: 20 }} value={pageTitle} />
        </Flex>
        <Flex row fw je>
          {width > 1240 && <Text bold title size='20px' style={{ marginRight: 20, whiteSpace: 'nowrap' }} value={agencyName || ''} />}
          <img src={PoweredBySIAP} style={{ maxHeight: 46, margin: 2, marginRight: 10 }} />
        </Flex>
      </motion.div>
    </>
  )
}
const styles = {
  containerStyle: {
    width: '100%',
    height: 50,
    zIndex: 50,
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0px 2px 2px rgba(100, 100, 100, 0.5)',
    backdropFilter: 'blur(4px) saturate(180%)',
    WebkitBackdropFilter: 'blur(4px) saturate(180%)',
    y: -50,
    opacity: 0
  }
}
