import React from 'react'
import { Button, Flex } from '../../../Components'
import { FC, invalidateQuery } from '../../../Services'

/**
 * Restore an email template by setting deleted flag to false
 *
 * @param {object} props.restore - The email template to restore
 * @param {function} props.onClose - Function to close the modal
 *
 * @returns {JSX.Element}
 */
const RestoreEmail = ({ restore, onClose }) => {
  /**
   * Restore an email template and invalidate the cache
   */
  const restoreEmailTemplate = async () => {
    const res = await FC.service('emailTemplates').patch(restore._id, {
      deleted: false,
      updatedAt: new Date()
    })
    res._id && window.growl.show({
      severity: 'success',
      summary: 'Email Restored',
      detail: 'Email successfully restored!'
    })
    invalidateQuery('emailTemplates')
    onClose()
  }

  return (
    <Flex style={{ paddingBottom: '20px' }}>
      <Button
        label='Restore' icon='copy' style={{ width: 'fit-content' }}
        onClick={restoreEmailTemplate}
      />
    </Flex>
  )
}

export default RestoreEmail
