import React, { useRef, useState } from 'react'
import { useMount } from 'react-use'
import { Button, Card, Flex, ModalGeneric, Spinner, Text } from '../../../../../Components'
import { generateNewCustomQuestion, generateNewSignDate, mountFn, saveAll } from './Functions'
import DraggableSignDate from '../DraggableSignDate'
import DraggableCustomQuestion from '../DraggableCustomQuestion'
import { DateSign } from './DateSign'
import { CustomQuestionElement } from './CustomQuestion'
import { CorrectButton } from './CorrectButton'
import ModalContent from '../../../../Signatures/Components/ModalContent'
import { useNavigate } from 'react-router-dom'
import { BadgeKeyWord } from './BadgeKeyWord'

const OPERATION_LIST = [
  { label: 'Firma cliente', type: 'sign' },
  { label: 'Data documento', type: 'date' },
  { label: 'Crocette personalizzate', type: 'customCheck' },
  { label: 'Testi fissi', type: 'fixedLabel' }
]

const OperationRelative = ({
  documentId: [documentId, setDocumentId], documentName: [documentName, setDocumentName],
  pageNumber, marginBlock, refContainer, documentLink, onMouseMove, awsLink
}) => {
  const [badgeKeyWordRender, setBadgeKeyWordRender] = useState([])
  const [infoText, setInfoText] = useState('')
  const [signDateArray, setSignDateArray] = useState([])
  const [listCustomQuestions, setListCustomQuestions] = useState([])
  const [parsedDocument, setParsedDocument] = useState([])
  const [isReady, setIsReady] = useState(false)
  const modalBack = useRef(false)
  const navigate = useNavigate()

  useMount(async () => {
    const { parsedDocument, documentId, documentName, signDateArray, listCustomQuestions } = await mountFn(documentLink, awsLink)
    setIsReady(true)
    parsedDocument && setParsedDocument(parsedDocument)
    documentId && setDocumentId(documentId)
    documentName && setDocumentName(documentName)
    signDateArray && setSignDateArray(signDateArray)
    listCustomQuestions && setListCustomQuestions(listCustomQuestions)
  })

  const generateCorrectButton = (type) =>
    <CorrectButton handleNewItem={handleNewItem} type={type} signDate={[signDateArray, setSignDateArray]} customQuestions={[listCustomQuestions, setListCustomQuestions]} parsedDocument={parsedDocument} setBadgeKeyWordRender={setBadgeKeyWordRender} />

  const handleNewItem = (item, type) => {
    setBadgeKeyWordRender([])
    if (type === 'sign' || type === 'date' || type === 'fixedLabel') return generateNewSignDate(item, type, [signDateArray, setSignDateArray])
    if (type === 'customCheck') return generateNewCustomQuestion(item, type, [listCustomQuestions, setListCustomQuestions])
  }

  const renderSelectedItem = (type) => (type === 'sign' || type === 'date' || type === 'fixedLabel')
    ? (
      <Flex row fw wrap>
        {signDateArray.filter(el => el.type === type && el.status === 2).map((item, index) =>
          <DateSign item={item} key={index} id={index} signDate={[signDateArray, setSignDateArray]} />)}
      </Flex>)
    : (
      <Flex row fw wrap>
        {listCustomQuestions.filter(el => el.type === type && el.status === 3).map((item, index) =>
          <CustomQuestionElement
            item={item} id={index} key={index}
            customQuestions={[listCustomQuestions, setListCustomQuestions]}
          />)}
      </Flex>
      )

  const marginTop = document.getElementsByClassName('marginDocumentPDF')[0]?.offsetTop

  const renderCardContent = (type) => (
    <Flex row={type !== 'customCheck'} fw js style={{ padding: '10px 0px' }}>
      {type === 'customCheck' ? (<Flex fw as>  {generateCorrectButton(type)}</Flex>) : generateCorrectButton(type)}
      {renderSelectedItem(type)}
    </Flex>)

  /* PER DATA E FIRMA status 1 => ricerca parola chiave 2 => parola chiave confermata e drag and drop 3 => conferma posizione
  PER DOMANDA status 1 => ricerca parola chiave 2 => scrivi domanda 3 => parola chiave confermata e drag and drop 4 => conferma posizione */
  if (!isReady) return <Spinner />
  return (
    <>
      <div onMouseMove={onMouseMove} ref={refContainer} style={{ width: 565, height: 799, position: 'absolute', zIndex: 20, border: '1px solid black', marginTop }}>
        <DraggableSignDate listState={[signDateArray, setSignDateArray]} pageNumber={pageNumber} parentRef={refContainer} />
        <BadgeKeyWord badgeKeyWordRender={badgeKeyWordRender} pageNumber={pageNumber} />
        <DraggableCustomQuestion pageNumber={pageNumber} listState={[listCustomQuestions, setListCustomQuestions]} parentRef={refContainer} />
      </div>
      <Flex fw style={{ paddingTop: 20 }}>
        <Flex fw as style={{ marginBottom: 10, paddingLeft: 20 }}>
          <Card style={{ padding: 10, height: 65, borderRadius: 30 }}>
            <Flex row jb style={{ height: 45 }}>
              <Button icon='back' label='Annulla' onClick={() => modalBack.current.show()} />
              <ModalGeneric
                ref={modalBack}
                header='Annulla richiesta di firma'
                content={<ModalContent onHide={() => modalBack.current.hide()} onConfirm={() => navigate('/')} contentText='Vuoi annullare la richiesta di firma?' />}
              />
            </Flex>
          </Card>
        </Flex>
        <Flex style={{ marginLeft: 20, width: 'calc(100% - 20px)' }}>
          {OPERATION_LIST.map(({ label, type }, index) => (
            <Card key={index} style={{ width: '100%', margin: '10px 0px' }} title={label} collapsable={type !== 'sign' && type !== 'date'} collapsed>
              {renderCardContent(type)}
            </Card>))}
        </Flex>
        <Flex row>
          <Button
            style={{ cursor: 'pointer', marginTop: 10, marginBottom: 10, marginRight: 10 }}
            rich label='Salva elementi inseriti'
            onClick={async () => {
              await saveAll({ documentLink, documentName }, signDateArray, listCustomQuestions)
              setInfoText('Salvataggio avvenuto con successo!')
              setTimeout(() => setInfoText(''), 3000)
            }}
          />
        </Flex>
        <Text value={infoText} bold style={{ marginBottom: 10 }} />
      </Flex>
    </>
  )
}

export default OperationRelative
