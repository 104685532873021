import React from 'react'
import { Toast } from 'primereact/toast'

import { useMount, useUnmount } from 'react-use'
import _ from 'lodash'
import { cap } from '../../State'

const debouncedSetSize = _.debounce(() => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth
  const screenHeight = window.innerHeight || document.documentElement.clientHeight
  cap.pageSize.setState({ screenWidth, screenHeight })
}, 400)

const debouncedSetOrientation = _.debounce(() => {
  try {
    if (window.matchMedia('(orientation: portrait)').matches) cap.orientation.setState('portrait')
    if (window.matchMedia('(orientation: landscape)').matches) cap.orientation.setState('landscape')
  } catch (e) {}
}, 400)

function PageUtils () {
  useMount(() => {
    window.addEventListener('resize', debouncedSetSize)
    window.addEventListener('orientationchange', debouncedSetOrientation)
    debouncedSetSize()
    debouncedSetOrientation()
  })

  useUnmount(() => {
    window.removeEventListener('resize', debouncedSetSize)
    window.removeEventListener('orientationchange', debouncedSetOrientation)
  })

  return (
    <Toast ref={(el) => { window.growl = el }} style={{ marginTop: 50 }} />
  )
}

export { PageUtils }
