import copy from 'clipboard-copy'
import moment from 'moment'

const structures = {
  agencies: [
    ['Nome', v => v.name],
    ['Firmate', v => v.states.signed || 0],
    ['In Richiesta', v => v.states.requested || 0],
    ['Annullate', v => v.states.cancelled || 0],
    ['Scadute', v => v.states.expired || 0],
    ['Totali su Tablet', v => v.destination.APP || 0],
    ['Totali Remote', v => v.destination.REMOTE || 0],
    ['Firme Totali', v => v.totalSigns]
  ],
  days: [
    ['Giorno', v => moment(v.day).format('DD/MM/YYYY')],
    ['Firmate', v => v.states.signed || 0],
    ['In Richiesta', v => v.states.requested || 0],
    ['Annullate', v => v.states.cancelled || 0],
    ['Scadute', v => v.states.expired || 0],
    ['Totali Remote', v => v.destination.REMOTE || 0],
    ['Totali su Tablet', v => v.destination.APP || 0],
    ['Firme Totali', v => v.totalSigns]
  ],
  details: [
    ['Codice Dispositivo', v => v.deviceCode],
    ['Giorno', v => moment(v.createdAt).format('DD/MM/YYYY')],
    ['Stato', v => v.status],
    ['Agenzia', v => v.agency],
    ['Destinazione', v => v.requestDest],
    ['Tipo Contatto', v => v.contactType],
    ['ID Contatto', v => v.contactID],
    ['OpUnit', v => v.opUnit],
    ['Tipo Pratica', v => v.tipoPrtVou],
    ['Anno Pratica', v => v.annoPrtVou],
    ['Numero Pratica', v => v.numeroPrtVou],
    ['Numero Biglietto', v => v.numeroBgt],
    ['Utente Atlante', v => v.userAtl],
    ['Email Utente', v => v.remoteUserEmail],
    ['Telefono Utente', v => v.remoteUserPhone],
    ['Codice Firma Remota', v => v.remoteUserCode],
    ['Tipo Documento', v => v.documentType],
    ['Risposta Privacy 1', v => v.privacyQ1],
    ['Risposta Privacy 2', v => v.privacyQ2],
    ['Risposta Privacy 3', v => v.privacyQ3],
    ['Risposta Privacy 4', v => v.privacyQ4]
  ]
}

export const exportTable = (table, data) => {
  const structure = structures[table]
  if (!data || !data.length) return null

  const header = structure.map(s => s[0]).join('\t')
  const parseLine = line => structure.map(e => e[1](line)).join('\t')

  copy(header + '\n' + data.map(parseLine).join('\n'))
    .then(() => window.growl.show({ severity: 'success', summary: 'Tabella Copiata negli appunti', detail: 'Puoi incollare in Excel!' }))
    .catch(() => window.growl.show({ severity: 'error', summary: 'Errore nella copia negli appunti' }))
}
