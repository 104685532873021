export const translateLabel = (status) => {
  switch (status) {
    case 'created':
      return 'In lavorazione'
    case 'requested':
      return 'Inviata'
    case 'sending':
      return 'Invio in corso'
    case 'signed':
      return 'Firmata'
    case 'error':
    default:
      return 'Errore'
  }
}
