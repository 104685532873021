import React, { useState } from 'react'
import { Button, Flex, Input, Text } from '../../../../Components'
import { useCQuery } from '../../../../Services/QueryCache'
import TypeSetterArea from './TypeSetterArea'
import { inputPropsDropdown, emailTypes, editEmailTemplate } from './functions'

/**
 * Edit an email template
 *
 * @param {object} props.edit - The email template to edit
 * @param {function} props.onClose - Function to close the modal
 *
 * @returns {JSX.Element}
 */
const EditEmail = ({ edit: { environment: sourceEnvironment, _id }, onClose }) => {
  const { data: dbTemplates = [] } = useCQuery('emailTemplates')
  const { data: dbEnvs = [] } = useCQuery('environments')

  // Target email is the one that will be created
  const [targetEnvironment, setTargetEnvironment] = useState(sourceEnvironment)
  const [targetEmailTypeByEnvironment, setTargetEmailTypesByEnvironment] = useState(emailTypes(sourceEnvironment))
  const [previewsEnvironments, setPreviewsEnvironments] = useState(emailTypes(sourceEnvironment))

  return (
    <Flex fw wrap style={{ padding: '0 20px' }}>
      <Text value='Change Environment' size='22px' center style={{ marginTop: '20px' }} />
      <Flex row style={{ width: '35%', marginTop: '20px', textTransform: 'capitalize' }}>
        <Text value='To:' center style={{ display: 'inline-block' }} />
        <Input
          {...inputPropsDropdown('environment', 'Environment', targetEnvironment, setTargetEnvironment)}
          dropdown options={['Common', ...dbEnvs.map(env => env._id)]}
        />
      </Flex>
      <Button
        label='Confirm' icon='edit' style={{ margin: '30px 10px' }}
        onClick={() => editEmailTemplate(
          targetEmailTypeByEnvironment, targetEnvironment, sourceEnvironment, onClose, _id
        )}
      />
      <Text value='Change Template' size='22px' center style={{ display: 'block' }} />
      {['signatureRequest', 'reminder', 'error', 'expired'].map((type, key) => (
        <TypeSetterArea
          key={key}
          targetEmailTypeByEnvironment={[targetEmailTypeByEnvironment, setTargetEmailTypesByEnvironment]}
          previewsEnvironments={[previewsEnvironments, setPreviewsEnvironments]}
          filteredEnvironments={dbTemplates?.filter(template => template?.[type])
            ?.map(template => template.environment) || []}
          type={type} id={_id} targetEnvironment={targetEnvironment}
        />
      ))}

      <Flex row>
        <Button
          label='Save' icon='import' style={{ margin: '30px 10px' }}
          onClick={() => editEmailTemplate(
            targetEmailTypeByEnvironment, targetEnvironment, sourceEnvironment, onClose, _id
          )}
        />
        <Button label='Close' icon='close' style={{ margin: '30px 10px' }} onClick={onClose} />
      </Flex>
    </Flex>
  )
}

export default EditEmail
