import React, { useRef } from 'react'
import { useWindowSize } from 'react-use'
import { Button, ModalGeneric } from '../../../Components'
import { FC, invalidateQuery, showGrowl } from '../../../Services'
import ModalContent from './ModalContent'

export function DeleteBtn ({ rowData: { requestData, requestSource, _id } }) {
  const { width: screenWidth } = useWindowSize()
  const deleteRef = useRef(null)

  const openDeleteModal = () => deleteRef?.current?.show ? deleteRef.current.show() : deleteRef.show()
  const hideDeleteModal = () => deleteRef?.current?.hide ? deleteRef.current.hide() : deleteRef.hide()

  const deleteRequest = async () => {
    const bodyDelete = {
      requestData,
      requestId: _id,
      requestSource,
      action: 'deleteSign'
    }
    const res = await FC.client.service('services').create(bodyDelete)
    hideDeleteModal()
    if (!res) return showGrowl('error', 'Errore cancellazione richiesta', 'Qualcosa è andato storto nella cancellazione della richiesta. Riprova.')
    showGrowl('success', 'Cancellazione andata a buon fine.', '')
    invalidateQuery('signaturesDoc')
  }

  return (
    <>
      <ModalGeneric
        ref={deleteRef}
        header='Cancellazione richiesta di firma'
        content={
          <ModalContent
            onHide={hideDeleteModal}
            onConfirm={deleteRequest}
            contentText='Sicuro di volere eliminare questa richiesta di firma ?'
          />
        }

      />
      <Button
        rich
        round
        tooltip='Elimina'
        icon='bin'
        onClick={openDeleteModal}
        style={{ cursor: 'pointer', marginLeft: screenWidth > 1200 ? 10 : 0 }}
      />
    </>

  )
}
