import React from 'react'
import { Button, Card, Flex, Text } from '../../../../Components'
import { useNavigate } from 'react-router-dom'
import success from '../../../../Resources/Images/success.webp'

const Confirmation = ({ navToFirstStep }) => {
  const navigate = useNavigate()
  const handleNavToSigns = () => navigate('/')

  return (
    <Card style={{ padding: '3em 5em', borderRadius: 20, textAlign: 'center', backgroundColor: '#e6f7ff' }}>
      <Flex>
        <Text style={{ textAlign: 'center' }} value='Fantastico!' size={22} upCase bold />
        <Text style={{ textAlign: 'center' }} value='Richiesta di firma inviata con successo' size={16} />
        <img style={{ width: '230px', margin: '1em auto' }} src={success} />
      </Flex>
      <Flex style={{ gap: '.5em' }}>
        <Button style={{ minWidth: '230px' }} icon='back' label='Vai alle Firme' onClick={handleNavToSigns} />
        <Button style={{ minWidth: '230px' }} icon='plus' label='Nuova Richiesta' onClick={navToFirstStep} />
      </Flex>
    </Card>
  )
}

export default Confirmation
