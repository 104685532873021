import { useState, useRef } from 'react'
import { Button, Flex, ModalGeneric, Text } from '../../../Components'
import { Checkbox } from 'primereact/checkbox'
import ModalContent from '../../Signatures/Components/ModalContent'
import { sendSignedDocs } from './Functions'
import { ModalImportNewClients } from './ModalImportNewClients'

export const ClientsDocTutorial = () => (
  <Flex as js fw>
    <Text value="Effettua l'upload di un file CSV contentente i clienti ai quali mandare la richiesta di firma." size={16} style={{ marginTop: 50 }} />
    <Text value='Il file CSV deve essere così strutturato: ' size={16} />
    <Text value='Nella prima riga di intestazione devono essere inseriti i campi nome, cognome, email, cellulare (obbligatori). Dopo di questi possono essere inseriti ulteriori campi facolativi a scelta (es. ragione sociale ecc)' size={16} />
    <Text value='Dalla seconda riga devono essere inseriti i dati dei vari clienti.' size={16} />
    <Flex row>
      <Text value='Ecco un file di esempio da editare e caricare.' size={16} />
      <Button style={{ marginLeft: 10 }} label='Scarica' icon='download' onClick={() => window.location.assign('https://cacerts.siap.cloud/EsempioUtenti.csv')} />
    </Flex>
  </Flex>
)

export const MultiSignStats = ({ multiSign: { signatures = [], clients = [] } = {} }) => {
  const signedReqs = signatures.filter(sign => sign && sign.status === 'signed')
  const signedReqsLength = signedReqs.length
  const requestedReqs = signatures.filter(sign => sign && sign.status === 'requested').length
  const expiredReqsLength = signatures.filter(sign => sign && sign.status === 'expired').length
  const excludedClients = clients.filter(client => client && client.isClientExcluded === true)

  return (
    <Flex style={{ padding: 5, textAlign: 'left' }}>
      <Flex fw wrap je style={{ gap: '0.5em', cursor: 'default' }} row>
        {signedReqs?.length === signatures?.length && <Text value='Firma Multipla firmata da tutti i clienti' upCase size={18} />}
        <Text style={badgeStyle} bold value={`Clienti: ${clients?.length}`} size={16} />
        {excludedClients?.length !== 0 && (<Text style={badgeStyle} bold value={`Clienti esclusi: ${excludedClients?.length}`} size={16} />)}
        <Text style={badgeStyle} bold value={`Richieste da firmare: ${requestedReqs}`} size={16} />
        <Text style={badgeStyle} bold value={`Richieste firmate: ${signedReqsLength}`} size={16} />
        <Text style={badgeStyle} bold value={`Richieste scadute: ${expiredReqsLength}`} size={16} />
      </Flex>
    </Flex>
  )
}

export const SelectMultiSignMode = ({ onConfirm, hide }) => {
  const [multiSignMode, setMultiSignMode] = useState([true, false])
  const sendMode = multiSignMode.map((mode, ind) => ind === 0 ? (mode && 'MAIL') : (mode && 'SMS')).filter(s => s).join('-')
  return (
    <Flex>
      <Text center value='Vuoi inviare le richieste di firma tramite SMS e/o tramite E-mail?' />
      <Flex row>
        <Text value='EMAIL' style={{ marginRight: 10 }} />
        <Checkbox checked={multiSignMode[0]} onChange={() => setMultiSignMode([!multiSignMode[0], multiSignMode[1]])} />
        <Text value='SMS' style={{ marginLeft: 20, marginRight: 10 }} />
        <Checkbox checked={multiSignMode[1]} onChange={() => setMultiSignMode([multiSignMode[0], !multiSignMode[1]])} />
      </Flex>
      <Flex row style={{ marginTop: 10 }}>
        <Button label='Annulla' icon='back' onClick={hide} style={{ marginLeft: 10 }} />
        <Button label='Conferma' icon='check' onClick={() => onConfirm(sendMode)} style={{ marginLeft: 10 }} />
      </Flex>
    </Flex>
  )
}

export const MultiSignActions = ({ multisignId, clients, exportCSV, isNew, status }) => {
  console.log(status, 'status')
  const refModalAddNewClients = useRef(null)
  const downloadDocsRef = useRef()

  if (!isNew && status !== 'created') {
    return (
      <Flex row style={{ width: 300, marginTop: 5 }}>
        <Button style={{ marginRight: 10 }} round tooltip='Scarica documenti firmati' icon='send' onClick={() => downloadDocsRef?.current?.show()} />
        <ModalGeneric ref={downloadDocsRef} header='Invio documenti firmati' content={<ModalContent onHide={() => downloadDocsRef.current.hide()} onConfirm={() => { sendSignedDocs(multisignId?.toString()); downloadDocsRef.current.hide() }} contentText="Sei sicuro di scaricare i documenti? La tua agenzia riceverà un'email contentente il link per il download dell'archivio." />} />
        <Button style={{ marginRight: 10 }} round tooltip='Importa altri clienti' icon='cloud-up' onClick={() => refModalAddNewClients?.current?.show()} disabled={status === 'sending' || status === 'created'} />
        <ModalGeneric content={<ModalImportNewClients hide={() => refModalAddNewClients?.current?.hide()} currentClients={clients} multiSign={multisignId} status={status} />} ref={refModalAddNewClients} style={{ width: '90%', height: '90%' }} />
        <Button round tooltip='Scarica CSV' icon='xls-2' onClick={exportCSV} />
      </Flex>
    )
  }
}

const badgeStyle = {
  boxShadow: 'rgb(80, 80, 80) 0px 0px 10px',
  borderRadius: 10,
  padding: '7px 15px 7px 15px'

}
