import React from 'react'
import { Button, Flex, Text } from '../../../Components'

export default function ItalicSign ({ sign = false, onPressConfirm, designPage }) {
  if (!sign) return null
  return (
    <Flex style={{ marginTop: 10 }}>
      <Button
        label='Firma con nome e cognome'
        onClick={() => onPressConfirm(sign)}
        style={{ backgroundColor: designPage?.mainColor, color: '#fff' }}
        inverted
      />
      <Text bold title style={{ marginTop: 10 }} value='Esempio di firma con Nome e Cognome' />
      <div style={{ height: 'auto', width: '100%', marginTop: 5 }}>
        <img style={{ width: '100%', height: 'auto', maxHeight: 60, border: '1px solid black' }} alt='ItalicSignature' src={sign} />
      </div>
    </Flex>

  )
}
