import React, { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Flex, Button, Text } from '../../../Components'
import { FilterMatchMode } from 'primereact/api'
import { exportExcel } from '../../../Services/Utils'

const SmsTable = ({ searchResult, from, to, environments, setIsVisible, setIsScrolled, getHistoryDetails }) => {
  const [filters] = useState({
    environment: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  })
  const actionBodyTemplate = (rowData) => {
    return (
      <Flex row style={{ gap: '.5em', width: '180px' }}>
        <Button round icon='see' tooltip='Visualizza storico' onClick={() => { getHistoryDetails(from, to, rowData); setIsVisible(true); setIsScrolled(true) }} />
      </Flex>
    )
  }

  const environmentBodyTemplate = (rowData) => {
    return (
      <Flex row fw style={{ justifyContent: 'space-between', padding: '.5em', width: '300px' }}>
        <Flex row style={{ justifyContent: 'flex-start', alignItems: 'center', padding: '.5em' }}>
          {rowData.logo && (
            <div style={{ width: 50, height: 50, marginRight: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img src={rowData.logo} alt='logo' style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', margin: '5px' }} />
            </div>
          )}
          <Text upCase size={22} bold value={rowData.environment || 'not found'} />
        </Flex>
      </Flex>
    )
  }

  const handleExportExcel = () => {
    const columns = [
      { label: 'AGENZIA', field: 'environment', width: 120 },
      { label: 'Data Inizio', field: 'from', width: 120 },
      { label: 'Data Fine', field: 'to', width: 120 },
      { label: 'Totale IT', field: 'totalIT', width: 70 },
      { label: 'Totale Estero', field: 'totalOthers', width: 70 },
      { label: 'Totale SMS', field: 'totalMessaggesValue', width: 70 },
      { label: 'Totale €', field: 'total', width: 70 }
    ]

    exportExcel(searchResult.sort((a, b) => a.environment.localeCompare(b.environment)),
      columns, `report SMS Esendex ${new Date(Date.now()).toLocaleString()}`)
  }
  const header = (
    <Flex fw je ae>
      <Button round icon='xls-2' tooltip='Scarica storico' severity='success' rounded onClick={handleExportExcel} data-pr-tooltip='XLS' />
    </Flex>
  )

  return (
    <DataTable
      style={{ width: '100%' }} sortField='environment' sortOrder={1} paginator rows={10} value={searchResult} className='TableSignatures'
      selectionMode='single' filter={filters} filterDisplay='row' header={header}
    >
      <Column field='environment' header='Agenzie (A-Z)' filter filterPlaceholder='Cerca per Nome' showFilterMenu={false} body={environmentBodyTemplate} />
      {/* <Column field='from' header='Data Inizio' />
      <Column field='to' header='Data Fine' /> */}
      <Column field='totalIT' header='Totale ITA' />
      <Column field='totalOthers' header='Totale Estero' />
      <Column field='totalMessaggesValue' header='N. Messaggi' />
      <Column field='total' header='Totale €' />
      <Column field='' header='' body={actionBodyTemplate} />
    </DataTable>
  )
}

export default SmsTable
