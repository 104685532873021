import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog } from 'primereact/dialog'

/* import { getHost } from '../../Services' */
import { Text } from './Text'
import { Button } from './Button'
import { Icon } from './Icon'
import { Modal } from './Modal'

/* JSX content are 'element', words are string */
const typeStringOrReact = PropTypes.oneOfType([PropTypes.string, PropTypes.element])

class ModalGeneric extends Component {
  constructor (props) {
    super(props)
    this.state = { visible: !!props.visible }
    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)
  }

  componentDidMount () {
    this.props.setShow && this.props.setShow(this.show)
    this.props.setHide && this.props.setHide(this.hide)
  }

  componentDidUpdate (prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.setState({ visible: this.props.visible })
    }
  }

  show () {
    this.setState({ visible: true })
  }

  hide () {
    this.setState({ visible: false })
  }

  polimorphicContent () {
    return (
      <>
        {typeof this.props.content === 'object'
          ? (
              this.props.content
            )
          : (
            <Text
              style={{
                ...this.props.style,
                font: 'bold',
                fontSize: '20px',
                marginBottom: '20',
                ...centerHorizontally
              }}
              value={this.props.content}
            />
            )}

        {this.props.action && (
          <>
            <div
              className='action-buttons'
              style={{ marginBottom: '30px', ...centerHorizontally }}
              onClick={(event) => event.stopPropagation()}
            >
              {!this.props.onlyYes && (
                <Button
                  rich
                  label={this.props.no || 'No'}
                  icon='close'
                  onClick={this.hide}
                  style={{ marginRight: 20 }} // width: 100
                />
              )}
              <Button
                rich
                label={this.props.yes || 'Si'}
                icon='check'
                style={{}} // width: 100
                onClick={() => {
                  this.hide()
                  this.props.action()
                }}
              />
            </div>
          </>
        )}
      </>
    )
  }

  render () {
    return (
      <Modal>
        <Dialog
          modal
          dismissableMask={!this.props?.noDismissable}
          draggable
          showHeader={false}
          visible={this.state.visible}
          closable={this.props.closable !== 'no'}
          contentStyle={{
            backgroundColor: 'rgb(255, 255, 255, 0.8)',
            backdropFilter: 'blur(16px) saturate(180%)',
            WebkitBackdropFilter: 'blur(16px) saturate(180%)',
            overflowX: 'hidden'
          }}
          style={{
            minWidth: 550,
            backgroundSize: 'cover',
            borderRadius: '20px',
            overflow: 'auto',
            overflowX: 'hidden',
            scrollbarWidth: 'none',
            ...this.props.style,
            overflowY: 'auto',
            color: '#32324e',
            boxShadow: '0px 0px 57px -25px rgba(0,0,0,0.75)',
            padding: 'inherit',
            ...this.props.styleContainer
          }}
          onHide={this.hide}
        >
          {/* <div
            onClick={(event) => event.stopPropagation()}
            className='contentContainer'
            style={{
              overflowY: 'auto',
              overflowX: 'hidden',
              // backgroundImage: `url(${Themes[getHost()[0].theme]})`,
              backgroundSize: 'cover',
              borderRadius: '20px',
              color: '#32324e', // getHost()[0].theme === 1 ? 'white' : '#32324e',
              boxShadow: '0px 0px 57px -25px rgba(0,0,0,0.75)',
              height: '100%',
              padding: 'inherit',
              backgroundColor: 'red',
              ...this.props.styleContainer
            }}
          > */}
          {this.props.closable !== 'no'
            ? (
              <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                <Icon
                  color='#32324e' // getHost()[0].theme === 1 ? 'white' : '#32324e' }
                  name='close'
                  size={20}
                  style={{ cursor: 'pointer', marginBottom: 10 }}
                  onClick={() => this.hide()}
                />
              </div>)
            : null}
          <Text
            style={{
              color: '#32324e', // getHost()[0].theme === 1 ? 'white' : '#32324e',
              ...centerHorizontally,
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 20
            }}
            size={20}
            value={this.props.header}
          />
          {this.polimorphicContent()}
          {/*  </div> */}
        </Dialog>
      </Modal>
    )
  }
}

const centerHorizontally = {
  display: 'flex',
  justifyContent: 'center'
}

ModalGeneric.propTypes = {
  content: typeStringOrReact,
  setShow: PropTypes.func,
  setHide: PropTypes.func,
  header: typeStringOrReact,
  action: PropTypes.func,
  style: PropTypes.object,
  styleContainer: PropTypes.object,
  styleText: PropTypes.object,
  no: PropTypes.string,
  yes: PropTypes.string,
  onlyYes: PropTypes.bool,
  question: PropTypes.string
}
export { ModalGeneric }
