import React, { useEffect, useState } from 'react'
import { sampleDataTemplateCompiler } from './utils'
import { FC } from '../../../Services'
import { Text } from '../../../Components'

/**
 * Create Email Preview
 *
 * @param {object} props.environment - Template environment
 * @param {object} props.type - Template type
 * @param {object} props.previewsEnvironments - The environments of the previews
 * @param {object} props.setPreviewsEnvironments - Set the environments of the previews
 * @param {object} props.setTypeByEnvironment - Set the types of the email by environment
 *
 * @returns {JSX.Element}
 */
const EmailPreview = ({
  environment, type, setAllPreviewsEnvironments, setTypeByEnvironment,
  previewsEnvironments, templateExists, setTemplateExists, toggleRender
}) => {
  const [previews, setPreviews] = useState([''])

  useEffect(() => {
    (async () => {
      if (environment && type) {
        const [template] = await FC.service('emailTemplates')
          .find({ query: { environment: environment } })

        if (template?.[type]) {
          const res = await sampleDataTemplateCompiler([
            { environment: environment, type, templateInput: null }
          ])
          setPreviews(res)
        }
        setTemplateExists({ ...templateExists, [type]: !!template[type] })
      }
    })()
  }, [environment, type, templateExists[type], toggleRender])

  if (!templateExists[type]) return null
  return (
    <div style={emailPreviewStyle}>
      {previews.map(({ content, contentLabel }, key) => (
        <React.Fragment key={key}>
          <Text center size='32px' value={contentLabel} style={{ margin: '20px 0' }} />
          <div
            className={type} key={key} style={{ backgroundColor: 'white' }}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </React.Fragment>
      ))}
    </div>
  )
}

const emailPreviewStyle = {
  backgroundColor: 'white',
  width: '100%',
  maxHeight: '500px',
  overflowX: 'clip',
  overflowY: 'scroll'
}

export default EmailPreview
