import React from 'react'
import { Button, Flex, Text } from '../../../../../../Components'

export default function Confirm ({ action }) {
  return (
    <Flex style={{ padding: 20 }}>
      <Text size={20} value={'Scegli se inviare ora la richiesta di firma o se metterla in "Attesa"'} />
      <Flex row style={{ marginTop: 10 }}>
        <Button label='Metti in attesa' icon='clock' onClick={() => action('wait')} style={{ marginRight: 5 }} />
        <Button label='Invia richiesta' icon='send' style={{ marginLeft: 5 }} onClick={() => action('send')} />
      </Flex>
    </Flex>
  )
}
