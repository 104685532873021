import { objectMap } from './Functions'
import { StoredCapsule, Capsule } from './CapsuleClasses'

const sessionState = {
  pageSize: {
    screenWidth: window.innerWidth || document.documentElement.clientWidth,
    screenHeight: window.innerHeight || document.documentElement.clientHeight
  },
  modals: { // da togliere
    modalDecision: false,
    modalSignature: false
  },
  mediumScreen: 1240,
  smallScreen: 640,
  orientation: ((window?.screen?.orientation || {}).type || window?.screen?.mozOrientation || window?.screen?.msOrientation) === 'landscape-primary'
    ? 'landscape'
    : 'portrait',
  selectedEnv: ''
}
const storedState = {
  auth: {
    jwt: '',
    userId: '',
    agency: '',
    isEnvAdmin: false,
    isSingleUO: false,
    isAdmin: false
  },
  logo: {
    url: ''
  },
  aws: 'https://s3.eu-central-1.amazonaws.com/sign.dev/'
}

export const state = {
  ...objectMap(sessionState, (key, value) => new Capsule(key, value)),
  ...objectMap(storedState, (key, value) => new StoredCapsule(key, value))
}
