import { useCQuery } from '../../../Services/QueryCache'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { addAndSendNewClients, headerLabel, importFromCRM, uploadClients } from './Functions'
import { showGrowl } from '../../../Services'
import { useRef, useState, useEffect } from 'react'
import { Button, Flex, Spinner, Text } from '../../../Components'

export const ModalImportNewClients = ({ currentClients, multiSign, hide }) => {
  const { data: environment = {} } = useCQuery('environment')
  const refUploadClients = useRef()
  const [clients, setClients] = useState([])
  const [displayClients, setDisplayClients] = useState()
  const [loadingCRM, setLoadingCRM] = useState(false)

  const removeExistingClients = (client) => !currentClients.find(({ userPhone = '' }) => (userPhone)?.split('.')?.pop() === (client?.userPhone)?.split('.')?.pop())

  const importClients = async (type, e) => {
    type === 'CRM' && setLoadingCRM(true)
    const newClients = type === 'CRM'
      ? (await importFromCRM()).filter(removeExistingClients)
      : (await uploadClients(e)).filter(removeExistingClients)
    type === 'CRM' && setLoadingCRM(false)
    const updatedClients = newClients.map((client) => ({ ...client, isClientExcluded: false }))
    setClients(updatedClients)
    !updatedClients?.length && showGrowl('error', 'Errore nuovi clienti', 'Non è stato trovato nessun nuovo cliente da importare')
  }

  const toggleExclusion = ({ index, ...client }) => {
    const clientCopy = [...clients]
    clientCopy[index] = { ...client, isClientExcluded: !client?.isClientExcluded }
    window.growl.show({
      severity: clients[index].isClientExcluded ? 'success' : 'error',
      summary: clients[index].isClientExcluded ? 'Cliente Incluso' : 'Cliente Escluso',
      detail: clients[index].isClientExcluded ? 'Cliente incluso con successo' : 'Cliente escluso con successo'
    })
    setClients(clientCopy)
  }

  const addStatus = (clients) => clients.map((item, i) => ({
    ...item,
    index: i
  }))

  useEffect(() => setDisplayClients(addStatus(clients)), [JSON.stringify(clients)])

  return (
    <Flex fw>
      <Text value='Aggiunta clienti' bold size={20} upCase />
      <Text title value='Importa nuovi clienti. Puoi aggiungere solo clienti con numeri di cellulare diversi da quelli già presenti.' />
      <Flex row>
        <input type='file' accept='.csv' ref={refUploadClients} style={{ display: 'none' }} onChange={(e) => importClients('CSV', e)} />
        <Button icon='cloud-up' label='Importa da CSV' style={{ marginTop: 10 }} onClick={() => refUploadClients.current.click()} />
        {environment?.hasCrm &&
          (!loadingCRM ? <Button icon='cloud-up' label='Importa da CRM' style={{ marginTop: 10, marginLeft: 20 }} onClick={() => importClients('CRM')} /> : <Spinner />)}
      </Flex>
      {!!clients?.length &&
        <>
          <DataTable
            style={{ width: '100%', margin: 10, boxShadow: '0 0 10px rgb(80, 80, 80)', borderRadius: 20, padding: 10 }} sortField='createdAt' sortOrder={-1} scrollHeight='80vh'
            value={displayClients} emptyMessage='Niente da visualizzare' responsiveLayout='scroll' paginator rows={10} className='TableSignatures' filterDisplay='row'
          >
            {Object.keys(clients[0] || {})?.map((key, ind) => (
              key !== 'isClientExcluded' && key !== 'index'
                ? (
                  <Column
                    key={ind} header={headerLabel(key)} alignHeader='center' showClearButton={false} filter
                    filterMatchMode='contains' style={{ textAlign: 'center' }} sortable
                    filterPlaceholder={'🔍   ' + headerLabel(key)} showFilterMenu={false} field={key}
                    body={({ [key]: value }) => <Text center value={value} style={{ width: 150 }} />}
                  />)
                : null
            ))}
            <Column
              body={(rowData) => (
                <Flex as style={{ width: 150 }}>
                  <Button
                    tooltip={rowData?.isClientExcluded ? 'Includi Cliente' : 'Escludi Cliente'}
                    round
                    icon={rowData.isClientExcluded ? 'add' : 'close'}
                    onClick={() => toggleExclusion(rowData)}
                  />
                </Flex>)}
            />
          </DataTable>
          <Button icon='send' label='Aggiungi clienti ed invia richiesta di firma' onClick={() => { hide(); addAndSendNewClients(multiSign, clients) }} />
        </>}
    </Flex>
  )
}
