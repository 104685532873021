import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useState } from 'react'
import { Button, Flex, Spinner, Text } from '../../../Components'
import { useCQuery } from '../../../Services'
import { useNavigate } from 'react-router-dom'
import { FilterMatchMode } from 'primereact/api'

const EnvList = () => {
  const { data: enviroments = [], isSuccess } = useCQuery('environments')
  const navigate = useNavigate()

  const [filters] = useState({
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mobyt: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  })

  if (!isSuccess) return <Spinner />
  return (
    <Flex fw fh js style={{ padding: 20 }}>
      <Flex fw>
        <Flex jb row fw>
          <Text size={22} bold value='Elenco Environment' style={{ marginBottom: 15 }} />
          <Button label='Crea nuovo environment' onClick={() => navigate('/environments/new')} />
        </Flex>
        <DataTable style={{ width: '100%' }} value={enviroments} filter={filters} filterDisplay='row' paginator rows={20} emptyMessage='Nessun risultato' className='TableSignatures'>
          <Column field='_id' header='Id' sortable filter filterPlaceholder='Cerca per Id' showFilterMenu={false} />
          <Column field='configEmail.from' header='Email' sortable filter filterPlaceholder='Cerca per Email' showFilterMenu={false} />
          <Column field='mobyt.senderName' header='Mobyt' sortable filter filterPlaceholder='Cerca per configurazione Mobyt' showFilterMenu={false} />
          <Column
            header='' body={(environment) => (
              <Flex row>
                <Button label='Modifica' icon='edit' style={{ marginRight: 10 }} onClick={() => navigate(`/environments/${environment._id}`)} />
                <Button icon='edit' label='Gestione PDF' onClick={() => navigate(`/pdf/${environment?._id}/list`)} />
              </Flex>
            )}
          />
        </DataTable>
      </Flex>
    </Flex>
  )
}

export default EnvList
