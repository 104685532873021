import React, { useEffect, useState } from 'react'
import { Flex, Button, Input } from '../../../../Components'
import { sampleDataTemplateCompiler, elementRegex, getDefaultTemplate } from '../utils'
import { FC } from '../../../../Services'
import { format } from '@projectwallace/format-css'
import {
  createPreview, saveTemplate, editButtonStyle, fontButtonStyle,
  fontEditorStyle, editorStyle
} from './functions'

/**
 * Edit the code of an email template
 *
 * @param {object} props.id - Template ID
 * @param {object} props.environment - Template environment
 * @param {object} props.type - Template type
 * @param {object} props.onClose - Function to close the modal
 *
 * @returns {JSX.Element}
 */
const EditEmailCode = ({
  onClose, id, environment, type, updateMenu
}) => {
  const [preview, setPreview] = useState('')
  const [codeDisplayStyle, setCodeDisplayStyle] = useState('block')
  const [fontEditIsOpen, setFontEditIsOpen] = useState(false)
  const [emailPreviewWidth, setEmailPreviewWidth] = useState('50%')
  const [editButtonLabel, setEditButtonLabel] = useState('Edit Style')
  const [html, setHtml] = useState('')
  const [style, setStyle] = useState('')
  const [fontImport, setFontImport] = useState('')
  const [editorText, setEditorText] = useState('')

  /**
   * Change the editor mode between HTML and CSS
   * and update the editor text
   */

  const handleEditModeChangeClick = () => {
    if (editButtonLabel === 'Edit Style') {
      setEditButtonLabel('Edit HTML')
      setHtml(editorText)
      setEditorText(style)
    } else {
      setEditButtonLabel('Edit Style')
      setEditorText(html)
      setStyle(format(editorText).replaceAll('\t', '  '))
    }
  }

  const toggleMenuVisibility = () => {
    if (codeDisplayStyle === 'block') {
      setCodeDisplayStyle('none')
      setEmailPreviewWidth('100%')
    } else {
      setCodeDisplayStyle('block')
      setEmailPreviewWidth('50%')
    }
  }

  const getUpdatedInput = () => (editButtonLabel === 'Edit Style'
    ? { html: editorText.trim(), style: format(style.trim()).replaceAll('\t', '  ') }
    : { html: html.trim(), style: format(editorText.trim()).replaceAll('\t', '  ') })

  /**
     * Get the template of the environment
     * and set the preview
     *
     * @returns {string} template
     */
  const firstRender = async () => {
    const rawResponse = await FC.service('emailTemplates').find({ query: { environment } })
    const [{ content: previewResponse } = {}] = await sampleDataTemplateCompiler([
      { environment, type, templateInput: null }
    ])
    const template = rawResponse[0][type] || getDefaultTemplate({ type: 'style' })
    const hasFontImport = template.match(elementRegex('font'))
    const hasStyle = template.match(elementRegex('style'))

    setStyle(hasStyle
      ? format(template.match(elementRegex('style'))[0].trim()).replaceAll('\t', '  ')
      : getDefaultTemplate({ type: 'style' })
    )
    setHtml(template.match(elementRegex('body'))[0].trim())
    setFontImport(hasFontImport ? template.match(elementRegex('font'))[0] : '')
    setEditorText(template.match(elementRegex('body'))[0])
    setPreview(previewResponse)
  }

  useEffect(() => {
    (async () => editorText === ''
      ? await firstRender()
      : await createPreview(getUpdatedInput, fontImport, environment, type, setPreview))()
  }, [editorText])

  return (
    <Flex>
      <Flex row as width='100%' style={{ maxHeight: '730px' }}>
        <Flex style={{ width: '50%', display: codeDisplayStyle }}>
          <Flex row style={{ width: '100%', backgroundColor: '#232330' }}>
            <Button
              inverted label={fontEditIsOpen ? 'Close Font Edit' : 'Edit Font'} iconStyle={{ color: '#fff' }}
              onClick={() => { setFontEditIsOpen(!fontEditIsOpen); fontButtonStyle(editButtonLabel) }}
              style={fontButtonStyle(editButtonLabel)} icon='edit' textColor='#e1e2e8'
            />
            <Button
              inverted label={editButtonLabel} onClick={() => handleEditModeChangeClick()}
              style={editButtonStyle} iconStyle={{ color: '#fff' }} textColor='#e1e2e8' icon='edit'
            />
          </Flex>
          <Input
            multiline autocomplete={false} id='fontEditor' value={fontImport} setValue={setFontImport}
            style={{ display: (editButtonLabel === 'Edit HTML' && fontEditIsOpen) ? 'block' : 'none', ...fontEditorStyle }}
          />
          <Flex js style={{ width: '100%', height: fontEditIsOpen ? '628px' : '700px', overflow: 'clip' }}>
            <Input
              multiline autocomplete={false} id='mainEditor' value={editorText} setValue={setEditorText}
              style={{
                maxHeight: fontEditIsOpen ? '628px' : '700px',
                minHeight: fontEditIsOpen ? '80%' : '100%',
                ...editorStyle
              }}
            />
          </Flex>
        </Flex>
        <div style={{ width: emailPreviewWidth, height: '730px', overflow: 'auto' }}>
          <div className={type} dangerouslySetInnerHTML={{ __html: preview }} />
        </div>
      </Flex>
      <Flex row jn style={{ marginTop: '25px', width: '30%', position: 'fixed', bottom: '13px' }}>
        <Button
          label='Save' onClick={() => saveTemplate(
            getUpdatedInput, fontImport, environment, type, id, updateMenu, onClose)} icon='import'
        />
        <Button
          label='Toggle Code' onClick={() => toggleMenuVisibility()}
          icon='controls' style={{ width: 'fit-content' }}
        />
        <Button label='Cancel' onClick={() => onClose()} icon='close' />
      </Flex>
    </Flex>
  )
}

export default EditEmailCode
