import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components'

import { version } from '../../package.json'
import { FC } from '../../Services'

export default function Status () {
  const [serverStatus, setServerStauts] = useState(null)

  useEffect(() => {
    FC.client.service('public').get('status')
      .then((res) => setServerStauts(res))
      .catch((err) => setServerStauts(err))
  }, [])

  return (
    <Flex>
      <div>Versione client: {version}</div>
      <div>Versione server: {JSON.stringify(serverStatus)} </div>
    </Flex>

  )
}
