import React from 'react'
import { Draggable } from './Draggable'

const DraggableCustomRadioQuestion = ({ parentRef, pageNumber, listState: [elements, setElements] }) => {
  const updateElementListRadio = (idCross, update) =>
    setElements(elements.map(item => item.id === idCross ? { ...item, ...update } : item))

  return elements.length !== 0 &&
    elements.filter(item => item.page === pageNumber).map((item) => {
      return (
        <Draggable
          isCustomQuestion
          marginDefault={10}
          key={`${item?.id}-${item?.ind}`}
          dimensionContainer={{ width: item?.width, height: item?.height }}
          refParentDiv={parentRef}
          startX={item?.coordinates.x}
          startY={item?.coordinates.y}
          callbackUpdatePosition={(x, y) => updateElementListRadio(item?.id, { coordinates: { x: x - 20, y: y - 20 } })}
        >
          <span style={{ position: 'absolute' }} className='p-badge'>{`Domanda_${item?.progressiveNumber + 1}`}</span>

        </Draggable>
      )
    })
}

export default DraggableCustomRadioQuestion
