import React, { useRef, useState } from 'react'
import { Button, Card, Flex, Input, Text } from '../../../../Components'
import { Select } from '../../../../Components/Common/Select'
import { FC } from '../../../../Services'
import { useLocation } from 'react-router-dom'

const documentTypes = [{ value: 'fixed', label: 'Posizionamento assoluto' }, { value: 'relative', label: 'Posizionamento relativo' }]

const NewDocumentForm = ({ handleSelectDoc }) => {
  const { pathname } = useLocation()
  const isInstant = pathname === '/instantsign'
  const [documentName, setDocumentName] = useState('')
  const [alertText, setAlertText] = useState('')
  const [selectedDocumentType, setSelectedDocumentType] = useState('fixed')
  const upload = useRef(null)

  const uploadFile = async (e) => {
    const readFile = (file) =>
      new Promise((resolve, reject) => {
        const reader = new window.FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    e.stopPropagation()
    e.preventDefault()
    e.persist()
    if (!e.target.files.length) return false
    const uri = await readFile(e.target.files[0])
    try {
      const res = await FC.service(`upload-${FC.uploadEnv}`).create({ uri })
      handleSelectDoc({ url: res?.documentUrl, id: res?.documentId, type: selectedDocumentType, name: documentName })
    } catch (e) {
      console.log('ERROR => ', e)
    }
  }

  return (
    <Flex>
      <Card style={{ padding: 50, borderRadius: 20, marginTop: 20 }} title='Crea un nuovo documento da firmare'>
        <Flex fw>
          <Text value='Inserisci il nome del documento documento da mappare e caricalo' style={{ margin: 5 }} />
          <Input
            label='Nome documento:' id='documentName' value={documentName}
            style={{ margin: 5, width: 400 }} onChange={(e) => setDocumentName(e.documentName)}
          />
          {!isInstant && (
            <Select
              value={selectedDocumentType} options={documentTypes}
              style={{ fontSize: 12, marginTop: 20 }}
              onChange={({ value }) => setSelectedDocumentType(value)}
            />)}
          <Flex>
            <Button
              label='Carica Documento' icon='send' style={{ width: 400, marginTop: 20 }} onClick={() => {
                if (!documentName) {
                  setAlertText('Inserisci il nome del documento')
                  setTimeout(() => setAlertText(''), 3000)
                } else if (!selectedDocumentType) {
                  setAlertText('Inserisci il tipo di modalità')
                  setTimeout(() => setAlertText(''), 3000)
                } else upload.current.click()
              }}
            />
          </Flex>
          <Text value={alertText} color='red' bold style={{ marginTop: 20 }} />
          <input
            type='file' accept='application/pdf' ref={upload}
            style={{ display: 'none' }} onChange={(e) => uploadFile(e)}
          />
        </Flex>
      </Card>
    </Flex>
  )
}

export default NewDocumentForm
