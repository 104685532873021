import { useRef } from 'react'
import ModalContent from '../../Signatures/Components/ModalContent'
import { handleDeleteDoc, uploadDocument } from './Functions'
import { useNavigate, useParams } from 'react-router-dom'
import { useCQuery } from '../../../Services/QueryCache'
import { Button, Flex, ModalGeneric, Text } from '../../../Components'

export const MultiSignDoc = () => {
  const { multisignId } = useParams()
  const { data: multiSign = {} } = useCQuery(['multiSign', multisignId])
  const { status = '', mappedDocument = '', name: multiSignName = '', clients = [] } = multiSign

  const navigate = useNavigate()
  const refUploadDocument = useRef()
  const deleteRef = useRef()

  const uploadSignDocument = async (e) => {
    const { documentUrl } = await uploadDocument(e)
    navigate('/multisign/mapDoc', { state: { multiSignData: { multisignId, url: documentUrl, type: 'fixed', name: multiSignName, clientField: Object.keys(clients[0]) } } })
  }

  return (
    <Flex as fw style={{ marginTop: 30 }}>
      <Text title upCase bold value='Documento' size />
      {mappedDocument
        ? (
          <>
            <Text value='Documento caricato e mappato correttamente.' />
            <Flex row>
              <Button label='Visualizza documento' onClick={() => status !== 'created' ? window.open(mappedDocument?.url) : navigate('/multisign/mapDoc', { state: { multiSignData: { multisignId, url: mappedDocument?.url, type: 'fixed', name: multiSignName, clientField: Object.keys(clients[0]) } } })} />
              {status === 'created' && (
                <>
                  <Button label='Elimina documento' onClick={() => deleteRef.current.show()} style={{ marginLeft: 10 }} />
                  <ModalGeneric ref={deleteRef} header='Cancellazione documento' content={<ModalContent onHide={() => deleteRef.current.hide()} onConfirm={() => { handleDeleteDoc(multisignId); deleteRef.current.hide() }} contentText='Sei sicuro di volere eliminare questo documento? Dovrai caricare un nuovo documento per poter continuare.' />} />
                </>)}
            </Flex>
          </>)
        : (
          <>
            <Text value="Documento ancora non caricato. Effettua l'upload del documento da inviare in firma." />
            <input type='file' accept='application/pdf' ref={refUploadDocument} style={{ display: 'none' }} onChange={uploadSignDocument} />
            <Button label='Carica e mappa documento' onClick={() => refUploadDocument.current.click()} />
          </>)}
    </Flex>
  )
}
