import React from 'react'

import { Flex, Text, Input, Card } from '../../Components'
import moment from 'moment'
import { cap } from '../../State'
import { useWindowSize } from 'react-use'

export const DateRangePicker = ({ state: [range = {}, setRange] }) => {
  const { width: screenWidth } = useWindowSize()
  const width = screenWidth < cap.smallScreen.state ? screenWidth - 20 : ((screenWidth * 40) / 100)

  const handleSetDate = (field) => (data) => {
    let newFrom = field === 'from' ? data.from : range.from
    let newTo = field === 'to' ? data.to : range.to
    field === 'to' && moment(newTo).isBefore(moment(newFrom)) && (newFrom = newTo)
    field === 'from' && moment(newFrom).isAfter(moment(newTo)) && (newTo = newFrom)
    setRange({ from: moment(newFrom).format('YYYY-MM-DD'), to: moment(newTo).format('YYYY-MM-DD') })
  }

  return (
    <Card style={{ width: '100%' }}>
      <Flex row fw jb>
        <Flex row jb fw width={650}>
          <Text value='Statistiche Agenzie' size={30} />
          <Flex row>
            <Text value='Dal: ' size={20} />
            <Input date id='from' value={moment(range.from).toDate()} onChange={handleSetDate('from')} style={{ width: 150, marginLeft: 10 }} />
          </Flex>
          <Flex row>
            <Text value='Al: ' size={20} />
            <Input date id='to' value={moment(range.to).toDate()} onChange={handleSetDate('to')} style={{ width: 150, marginLeft: 10 }} />
          </Flex>
        </Flex>
        <img src={cap.logo.state.url} style={{ height: 'auto', width: 'auto', maxHeight: 60, maxWidth: width }} />
      </Flex>
    </Card>
  )
}
