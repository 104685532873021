import PropTypes from 'prop-types'
import React from 'react'

import { Text } from './Text'

const renderBadge = (badge, badgeSize) => (
  <div
    style={ {
      borderRadius: '50%',
      width: badgeSize || 16,
      height: badgeSize || 16,
      backgroundColor: 'rgb(145, 0, 0)',
      position: 'absolute',
      right: -8,
      bottom: -4
    } }
  >
    <div
      style={ {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      } }
    >
      <Text
        size={ badgeSize - 4 || 12 }
        color='white'
        style={ { fontFamily: 'Lato', padding: 4, paddingTop: 5 } }
        center
        value={ badge }
      />
    </div>
  </div>
)

const renderWithImage = ({ image, size, badge, style, onClick, imageStyle, text }) => {
  const badgeNumber = badge === 0 ? null : badge
  return (
    <div style={ { display: 'flex', flexDirection: 'column' } } onClick={ onClick }>
      <div
        style={ {
          padding: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 100,
          height: 100,
          backgroundColor: 'white',
          borderRadius: '50px',
          flexDirection: 'column',
          ...style
        } }
      >
        <img
          src={ image }
          alt=''
          style={ {
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 'auto',
            padding: 10,
            ...imageStyle
          } }
        />
        { badgeNumber && renderBadge(badgeNumber) }
      </div>
      { text && <Text style={ { textAlign: 'center', marginTop: 10 } } bold value={ text } /> }
    </div>
  )
}
renderWithImage.propTypes = {
  size: PropTypes.number,
  image: PropTypes.object,
  imageStyle: PropTypes.object,
  badge: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  text: PropTypes.string
}

const renderIcon = ({
  name,
  size,
  badge,
  style,
  onClick,
  color,
  iconStyle,
  text,
  textStyle,
  badgeSize,
  posed,
  onMouseOver,
  onMouseIn,
  onMouseOut
}) => {
  const iconSize = size || 36

  const iconOverrides = {
    'primeicon pi-power-off': 'pi pi-fw pi-power-off'
  }

  const className = iconOverrides[name] || `icon app-icon-${name || 'agencies'}`
  const badgeNumber = badge === 0 ? null : badge
  return (
    <React.Fragment>
      <div
        style={ {
          position: 'relative',
          width: iconSize,
          height: iconSize,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          ...style
        } }
        onClick={ onClick }
        onMouseOver={ onMouseOver }
        onMouseEnter={ onMouseIn }
        onMouseLeave={ onMouseOut }
      >
        <div
          className={ className }
          style={ {
            fontSize: iconSize,
            color: !posed ? color || '#32324e' : '',
            ...iconStyle
          } }
        >
          { badgeNumber && renderBadge(badgeNumber, badgeSize) }
        </div>
      </div>
      { text && (
        <Text
          style={ {
            ...textStyle,
            // marginLeft: -10,
            marginTop: 10,
            textAlign: 'center'
          } }
          bold
          value={ text }
        />
      ) }
    </React.Fragment>
  )
}
renderIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  badge: PropTypes.number,
  style: PropTypes.object,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseIn: PropTypes.func,
  onMouseOut: PropTypes.func,
  color: PropTypes.string,
  iconStyle: PropTypes.object,
  text: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  textStyle: PropTypes.object,
  badgeSize: PropTypes.number,
  posed: PropTypes.bool
}
export const Icon = (props) => {
  if (props.image) return renderWithImage(props)
  return renderIcon(props)
}
