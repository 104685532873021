import React, { useState } from 'react'

import { Button } from '../../Components/Common/Button'
import { Flex } from '../../Components/Common/Flex'
import { Input } from '../../Components/Common/Input'
import { Card } from '../../Components/Common/Card'
import { Spinner } from '../../Components/Common/Spinner'
import { FC, getHost, getLogoByAgency } from '../../Services'
import { cap } from '../../State'
import { useNavigate } from 'react-router-dom'

const Login = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [waiting, setWaiting] = useState(false)
  const host = getHost()
  const navigate = useNavigate()

  const handleSubmit = async () => {
    setWaiting(true)
    if (!email || !password) {
      window.growl.show({
        severity: 'error',
        summary: 'Errore login',
        detail: 'Campi non completati correttamente'
      })
      setWaiting(false)
      return false
    }
    const { user, accessToken } = await FC.login({ strategy: 'local', username: email, password })
    if (!user || !user.username || !accessToken) {
      /*  this.showError('Utente o Password non riconosciuti') */
      window.growl.show({
        severity: 'error',
        summary: 'Errore login',
        detail: 'Credenziali non corrette'
      })
      setWaiting(false)
      return false
    }

    const logo = await getLogoByAgency(user.agency)
    const [agency] = await FC.service('agencies').find({ query: { _id: user.agency } })
    const environment = await FC.service('environments').get(agency?.environment)

    cap.logo.patchState({ url: logo })

    cap.auth.patchState({
      hasEstemporaneo: environment?.hasEstemporaneo,
      hasMultiSign: environment?.hasMultiSign,
      environment: environment?._id,
      jwt: accessToken,
      userId: user._id,
      username: user.username,
      agency: user.agency,
      noTablet: user.noTablet,
      isEnvAdmin: user.isEnvAdmin,
      isSingleUO: user.isSingleUO,
      isAdmin: user.isAdmin,
      hasAtl: agency?.hasAtl || false,
      hasFtoSign: environment?.hasFtoSign || false
    })
    navigate(user.isAdmin ? '/list' : user.isEnvAdmin ? '/env' : '/')
  }
  return (
    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
      <Flex fw fh>
        <Card style={{ width: '600px', height: '100vh', padding: 50, borderRadius: 0 }} fadeIn>
          <img src={host.logo} style={{ width: '100%', height: 'auto', marginTop: '30%' }} alt='logo' />
          <div style={styles.loginElements}>
            <Input
              id='email'
              placeholder='email'
              value={email}
              onChange={({ email }) => setEmail(email)}
              style={{ width: 400, margin: '10px' }}
            />
            <Input
              password
              placeholder='password'
              id='password'
              value={password}
              onChange={({ password }) => {
                setPassword(password)
              }}
              style={{
                borderTopLeftRadius: 20,
                borderBottomLeftRadius: 20,
                width: 400,
                margin: '10px'
              }}
              enterAction={handleSubmit}
            />
            {waiting
              ? <Spinner />
              : (
                <Button
                  label='Accedi'
                  icon='symbol-1'
                  disabled={waiting}
                  onClick={handleSubmit}
                  rich
                  style={{ marginBottom: 10, marginTop: 10 }}
                />)}
          </div>
        </Card>
      </Flex>
    </div>
  )
}
const styles = {
  loginElements: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '20px',
    alignItems: 'center'
  },
  gridPadding: {
    padding: '.7em 0'
  }
}

export default Login
