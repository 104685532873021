import React from 'react'
import { AnswerRadio } from './AnswerRadio'

export const CustomRadio = ({
  radiosState: [listCustomRadios, setListCustomRadios],
  id: key, item, page
}) => {
  const updateCustomRadio = (newData, id) => {
    setListCustomRadios(listCustomRadios.map((el = {}) => el.id === id ? { ...el, ...newData } : el))
  }

  return (
    <AnswerRadio
      item={item} index={key} updateCustomRadio={updateCustomRadio}
      customRadio={[listCustomRadios, setListCustomRadios]}
    />
  )
}
