import React from 'react'
import { Card, Flex, Text, Spinner, Button } from '../../Components'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { useCQuery } from '../../Services'
import moment from 'moment'
import { exportTable } from './Utils'

const statusMap = {
  cancelled: 'Annullata',
  error: 'Errore',
  expired: 'Scaduta',
  requested: 'In richiesta',
  signed: 'Firmata'
}

export const StatsDettagliate = ({ range }) => {
  const { isSuccess, data = {} } = useCQuery(['statAgenzie', range])
  const { data: environment = {} } = useCQuery('environment')

  const title = `Dettaglio firme dal ${moment(range.from).format('DD/MM/YYYY')} al ${moment(range.to).format('DD/MM/YYYY')} - ` +
    `Firme Totali: ${data.details ? data.details.length : 0}`

  const formatDate = ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY')
  const bodyPrivacy = ({ privacyQ1 = '', privacyQ2 = '', privacyQ3 = '', privacyQ4 = '' }) =>
    privacyQ1 + ' ' + privacyQ2 + ' ' + privacyQ3 + ' ' + privacyQ4

  const bodyPratica = ({ tipoPrtVou = '', annoPrtVou = '', numeroPrtVou = '', numeroBgt = '' }) =>
    tipoPrtVou + ' ' + annoPrtVou + ' ' + numeroPrtVou + ' ' + numeroBgt

  const tableHeader = (
    <Flex row fw jb>
      <Text bold value={title} />
      <Button icon='file-excel' label='Esporta Tabella' onClick={() => exportTable('details', data.details)} />
    </Flex>
  )

  return (
    <Card style={{ marginTop: 20 }}>
      <DataTable
        style={{ fontSize: 12, marginBottom: 40 }}
        value={data.details} paginator rows={20}
        emptyMessage='Nessuna richiesta di firma trovata per il range di date selezionato.'
        header={isSuccess ? tableHeader : <Spinner />}
      >
        <Column field='createdAt' header='Data' sortable body={formatDate} style={{ width: 100 }} />
        {!environment.hasFtoSIgn && (
          <Column field='deviceCode' header='Codice Dispositivo' sortable style={{ width: 100 }} />)}
        <Column body={({ status }) => statusMap[status] || ''} header='Stato' sortable style={{ width: 100 }} />
        {!environment.hasFtoSign && (
          <>
            <Column field='documentType' header='Tipo' sortable style={{ width: 100 }} />
            <Column field='opUnit' header='Unità Op.' sortable style={{ width: 100 }} />
            <Column field='contactType' header='Tipo Contatto' sortable style={{ width: 100 }} />
            <Column field='contactID' header='ID Contatto' sortable style={{ width: 100 }} />
            <Column header='Pratica' sortable body={bodyPratica} />
            <Column field='userAtl' header='Utente Atlante' sortable />
          </>
        )}
        <Column field='remoteUserEmail' header='Email Cli' sortable style={{ width: 260 }} />
        <Column field='remoteUserPhone' header='Telefono Cli' sortable />
        <Column field='remoteUserCode' header='Codice' sortable />
        {!environment.hasFtoSign && (
          <Column header='Privacy' body={bodyPrivacy} />)}
      </DataTable>
    </Card>
  )
}
