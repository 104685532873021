import moment from 'moment'
import jwtDecode from 'jwt-decode'
import 'moment/locale/it'

import { DOMAINS } from './DomainsConfig'
import { FC } from './FeathersClient'
import { cap } from '../State'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
moment.locale('it')

export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const search = Array.isArray(params) ? params : [params]
  const found = search.map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const getHost = () => {
  const { environment } = cap.auth.state
  const env = getUrlParams('env')
  if (environment === 'isolaazzurra') return DOMAINS[9]
  if (env === 'viaggigiovani' || environment === 'viaggigiovani') return DOMAINS[10]
  const url = window.location.hostname
  return DOMAINS.find(({ host }) => host.find((hostName) => hostName === url)) || DOMAINS[0]
}

export const CommonColors = {
  translucent: 'rgba(231, 231, 231, 0.8)',
  purple: 'rgb(50, 50, 78)',
  white: 'rgb(255, 255, 255)'
}

export const getSignPath = (pathname) => {
  if (!pathname) return undefined

  const splitted = pathname.split('/')
  if (splitted.length === 2) return splitted[1]
}

export const openUrlFromBuffer = (buffer, type, name) => {
  const a = document.createElement('a')
  a.style = 'display: none'
  const blob = new Blob([buffer], { type: type })
  const url = URL.createObjectURL(blob)
  document.body.appendChild(a)

  a.href = url
  a.download = name ? `${name}.zip` : 'DocumentiSelezionati.zip'
  a.click()
  a.remove()
  window.URL.revokeObjectURL(url)
}

export const isJWTValid = (jwt) => {
  try {
    return moment.unix(jwtDecode(jwt).exp) - moment() > 0
  } catch (e) { }
  return false
}

export const getLogoByAgency = async (agencyId) => {
  if (!agencyId) return getHost().logo

  const agency = await FC.service('agencies').find({ query: { _id: agencyId } })

  return agency[0]?.logo || getHost().logo
}

export const showGrowl = (severity, summary, detail) => {
  window.growl.show({
    severity,
    summary,
    detail
  })
}

export const exportExcel = (data, columns, baseFileName) => {
  const formattedData = data.map((row) => {
    const formattedRow = {}
    columns.forEach((col) => {
      if (col.field === 'createdAt') {
        const date = new Date(row[col.field])
        formattedRow[col.label] = date.toLocaleDateString('it-IT', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        })
      } else {
        formattedRow[col.label] = row[col.field]
      }
    })
    return formattedRow
  })

  const worksheet = XLSX.utils.json_to_sheet(formattedData)
  worksheet['!cols'] = columns.map(col => ({ wpx: col.width }))

  const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] }
  const excelBuffer = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array'
  })

  saveAsExcelFile(excelBuffer, baseFileName)
}

const saveAsExcelFile = (buffer, baseFileName) => {
  const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const EXCEL_EXTENSION = '.xlsx'
  const date = new Date()
  const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`
  const fileName = `${baseFileName}-${formattedDate}${EXCEL_EXTENSION}`
  const data = new Blob([buffer], { type: EXCEL_TYPE })
  saveAs(data, fileName)
}

export const setValueInLocation = (obj, location, value) => {
  const keys = location.split('.')
  let ref = obj
  keys.forEach((key, i) => {
    if (i === keys.length - 1) ref[key] = value
    else ref = ref[key] || (ref[key] = {})
  })
  return obj
}
