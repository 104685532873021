import React, { useState } from 'react'
import { Flex, Text } from '../../../Components'
import { Dropdown } from 'primereact/dropdown'

export function RadioQuestions ({ radioFullList, onChangeCustomRadio }) {
  const [customAnswers, setCustomAnswers] = useState([])
  const setCustomAnswer = (index, answer) => {
    const newAnswers = [...customAnswers]
    newAnswers[index] = answer
    setCustomAnswers(newAnswers)
    onChangeCustomRadio(newAnswers)
  }

  const customOptions = (index, element, length) => (
    <Option
      key={index}
      item={element}
      optionId={index}
      switchAction={setCustomAnswer}
      switchValue={customAnswers[index]}
    />
  )
  return (
    <Flex fw as style={{ marginTop: 15 }}>
      <Flex fw as>
        {radioFullList?.length > 0 && (
          <Flex fw as>
            <Text title bold value='PER PROCEDERE RISPONDI ALLA SEGUENTE DOMANDA' />
          </Flex>
        )}
        {radioFullList?.map((pr, index) => customOptions(index, pr, radioFullList.length))}
      </Flex>
    </Flex>
  )
}

const Option = ({ item, optionId, switchAction, switchValue, isLast }) => {
  const { title, answerQuestion = [], mandatory } = item

  const titleLabel = title
  const handleDropdownChange = (e) => {
    const selectedAnswer = e.value
    switchAction(optionId, selectedAnswer)
  }
  return (
    <Flex width='100%'>
      <Flex width='100%' row jb style={{ backgroundColor: '#f8f8ff ', opacity: '0.9', padding: 12, borderRadius: 12, margin: '0.5em 0em' }}>
        <Flex as style={{ marginRight: 5 }}>
          <Flex row>
            <Text value={`${titleLabel}`} size={18} />
            {mandatory && <Text size={20} color='red' value='*' />}
          </Flex>
        </Flex>
        <Flex style={{ marginLeft: 5 }}>
          <Dropdown
            value={switchValue}
            onChange={handleDropdownChange}
            options={answerQuestion.map(({ answer }) => answer)}
            placeholder='Seleziona una risposta'
            className='w-full md:w-14rem'
            style={{ minWidth: '220px', border: '3px solid #4959e6', borderRadius: 12 }}
          />
        </Flex>
      </Flex>
      <hr />
    </Flex>
  )
}
